import { TelematicsState } from '../../reducers/telematics.reducer.types';

export const getTelematicsMachinesPage = (state: TelematicsState) => state.ui.telematicsAggregationsMachinesUI.page;
export const getTelematicsMachinesOrder = (state: TelematicsState) => state.ui.telematicsAggregationsMachinesUI.order;
export const getTelematicsMachinesOrderBy = (state: TelematicsState) =>
  state.ui.telematicsAggregationsMachinesUI.orderBy;
export const getTelematicsMachinesRowsPerPage = (state: TelematicsState) =>
  state.ui.telematicsAggregationsMachinesUI.rowsPerPage;

export const getTelematicsMachinesTextFilter = (state: TelematicsState) =>
  state.ui.telematicsAggregationsMachinesUI.textFilter;

export const getTelematicsMachinesOpenedRows = (state: TelematicsState) =>
  state.ui.telematicsAggregationsMachinesUI.openedMachineRows;

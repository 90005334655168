import { AnyAction } from 'redux';
import { RSAAAction } from 'redux-api-middleware';

import { getSectionListSearch, getSectionListPage, getSectionListRowsPerPage, getSectionListOrder, getSectionListOrderBy, getSectionListAdvancedFilter } from '../selectors/sectionList.selectors';

import { getSelectedNamespace } from '../reducers/namespace.reducer';

import { getFertilizerApi, getFertilizersApi, resetFertilizerApi, resetFertilizersApi, updateFertilizerApi, createFertilizerApi, patchFertilizerApi } from '../../shared/api/agroevidence/catalogues/fertilizers/fertilizers.api';

import { CataloguesState } from '../../reducers/catalogues.reducer.types';
import { CatalogueTo, EagriFertilizerNitrogenCategoryTo, FertilizerCreateTo, FertilizerPatchTo } from '../../shared/api/agroevidence/agroevidence.types';
import { getFertilizersParams } from '../../shared/api/agroevidence/catalogues/fertilizers/fertilizers.types';

export const fetchFertilizers = (validOn?: string) =>
  (dispatch: (action: RSAAAction) => void, getState: () => CataloguesState) => {
    const state = getState();
    const selectedNamespace = getSelectedNamespace(state);
    const advancedFilter = getSectionListAdvancedFilter(state);

    if (!selectedNamespace) return;

    const params: getFertilizersParams = {
      search: getSectionListSearch(state),
      page: getSectionListPage(state) + 1,
      'per-page': getSectionListRowsPerPage(state),
      'sort-col': getSectionListOrderBy(state),
      'sort-dir': getSectionListOrder(state),
    };

    if (advancedFilter) {
      params.organic = advancedFilter.isOrganic?.state;
      params['eagri-nitrogen-category'] = advancedFilter.nitrogenCategory?.map((nc: EagriFertilizerNitrogenCategoryTo) => nc.code)?.join(',');
      params.catalogue = advancedFilter.source?.map((s: CatalogueTo) => s.type)?.join(',');
    }

    if (validOn) {
      params['valid-on'] = validOn;
    }

    dispatch(getFertilizersApi(params));
  };

export const resetFertilizers = () => (dispatch: (action: AnyAction) => void) => dispatch(resetFertilizersApi());

export const fetchFertilizer = (fertilizerId: string) => (
  dispatch: (action: RSAAAction) => void,
) =>
  dispatch(getFertilizerApi(fertilizerId));

export const resetFertilizer = () => (dispatch: (action: AnyAction) => void) => dispatch(resetFertilizerApi());

export const updateFertilizer = (fertilizerId: string, params: FertilizerCreateTo) => (
  dispatch: (action: RSAAAction) => Promise<void>,
) =>
  dispatch(updateFertilizerApi(fertilizerId, params));

export const patchFertilizer = (fertilizerId: string, params: FertilizerPatchTo) => (
  dispatch: (action: RSAAAction) => Promise<void>,
) =>
  dispatch(patchFertilizerApi(fertilizerId, params));

export const createFertilizer = (params: FertilizerCreateTo) => (
  dispatch: (action: RSAAAction) => Promise<void>,
) =>
  dispatch(createFertilizerApi(params));

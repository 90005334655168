import { ReactNode, CSSProperties } from 'react';

import moment, { Duration, Moment } from 'moment';
import { IntlShape } from 'react-intl';

import { DUPLICATED_TAB_KEY } from '../../core/map/constants/localStorage.constants';

import { COLORS } from '../../shared/components/misc/CfTextBadge/CfTextBadge';
import LocalStorage from '../../shared/services/LocalStorage.service';

import { DriveDetailPartTo, OperationTo, TelematicsOperation, Type } from '../../shared/api/telematics/telematics.types';

export const getOperationName = (operations: OperationTo[], operationCode: TelematicsOperation) =>
  operations.find((operation) => operation.code === operationCode)?.name;

export const printTimeString = (hours: number, minutes: number) => {
  if (minutes > 9) {
    return `${hours}:${minutes}`;
  } else {
    return `${hours}:0${minutes}`;
  }
};

export const getOperationDuration = (timeFrom: Moment | null, timeTo: Moment | null) => {
  const hasValidTimes = timeFrom?.isValid() && timeTo?.isValid();
  if (hasValidTimes) {
    const start = moment(timeFrom);
    start.set('seconds', 0);
    const stop = moment(timeTo);
    stop.set('seconds', 59);
    const minutesDuration = stop.diff(start, 'minutes');
    return printTimeString((Math.floor(minutesDuration / 60)), minutesDuration % 60);
  } else {
    return '';
  }
};

export const getDuration = (timeseconds: number) => {
  const hours = Math.floor(timeseconds / 3600);
  const minutes = Math.round((timeseconds % 3600) / 60);
  const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  return `${formattedHours}:${formattedMinutes}`;
};

export const getColDesc = (sortable: boolean, label: ReactNode, style?: CSSProperties) => ({
  align: 'inherit',
  sortable,
  label,
  style,
});

export const getDriveBadgeProps = (type: Type, intl: IntlShape) => {
  switch (type) {
    case Type.UPDATED:
      return {
        backgroundColor: COLORS.PURPLE,
        color: COLORS.WHITE,
        text: 'E',
        tooltip: intl.formatMessage({ id: 'Telematics.badge.updated' }),
      };
    case Type.MANUAL:
      return {
        backgroundColor: COLORS.BLUE,
        color: COLORS.WHITE,
        text: 'M',
        tooltip: intl.formatMessage({ id: 'Telematics.badge.manual' }),
      };
    case Type.SEPARATED:
      return {
        backgroundColor: COLORS.VIOLET,
        color: COLORS.WHITE,
        text: 'S',
        tooltip: intl.formatMessage({ id: 'Telematics.badge.separated' }),
      };
    case Type.HANDWORK:
      return {
        backgroundColor: COLORS.TRANSPARENT,
        color: COLORS.VIOLET,
        fontSize: 10,
        text: 'R',
        tooltip: intl.formatMessage({ id: 'Telematics.badge.handwork' }),
      };
    case Type.AUTOMATIC:
    default:
      return {
        backgroundColor: COLORS.GREY,
        color: COLORS.WHITE,
        text: 'A',
        tooltip: intl.formatMessage({ id: 'Telematics.badge.automatic' }),
      };
  }
};

export const alignTimesWithDate = (date: Moment, timeFrom: Moment, timeTo: Moment): Moment[] => {
  const timeFromDateAgnostic = [timeFrom.hour(), timeFrom.minute(), timeFrom.second()];
  const timeToDateAgnostic = [timeTo.hour(), timeTo.minute(), timeTo.second()];
  const timeFromAligned = moment(date).set('hour', timeFromDateAgnostic[0]).set('minute', timeFromDateAgnostic[1]).set('second', timeFromDateAgnostic[2]);
  const timeToAligned = moment(date).set('hour', timeToDateAgnostic[0]).set('minute', timeToDateAgnostic[1]).set('second', timeToDateAgnostic[2]);
  return [timeFromAligned, timeToAligned];
};

export const getSplitTime = (
  intervals: DriveDetailPartTo[],
  splitterDate?: string | Moment,
) => {
  const selectedSplitterDuration = intervals.reduce((acc, interval) => {
    const intervalStart = moment(interval.dateFrom);
    const intervalEnd = moment(interval.dateTo);
    const splitterMoment = moment(splitterDate);
    if (intervalStart.isBefore(splitterMoment)) {
      if (intervalEnd.isBefore(splitterMoment)) {
        // The whole interval is before the splitter
        return acc.add(moment.duration(intervalEnd.diff(intervalStart, 'milliseconds')));
      } else {
        // Part of the interval is before the splitter
        return acc.add(moment.duration(splitterMoment.diff(intervalStart, 'milliseconds')));
      }
    }
    return acc;
  }, moment.duration(0));

  // Calculation of afterSplitterDuration based on the remaining parts of the intervals
  const remainingSplitterDuration = intervals.reduce((acc, interval) => {
    const intervalStart = moment(interval.dateFrom);
    const intervalEnd = moment(interval.dateTo);
    const splitterMoment = moment(splitterDate);
    if (intervalStart.isAfter(splitterMoment)) {
      // Part of the interval is after the splitter
      return acc.add(moment.duration(intervalEnd.diff(intervalStart, 'milliseconds')));
    } else if (intervalEnd.isAfter(splitterMoment)) {
      // Part of the interval starts before the splitter and ends after the splitter
      return acc.add(moment.duration(intervalEnd.diff(splitterMoment, 'milliseconds')));
    }
    return acc;
  }, moment.duration(0));

  return {
    selectedPart: selectedSplitterDuration.asSeconds(),
    remainingPart: remainingSplitterDuration.asSeconds(),
  };
};

interface TimeInterval {
  dateFrom: string;
  dateTo: string;
}

export const getNotOverlapedTime = (
  busyArray: TimeInterval[],
  workingTime: TimeInterval,
): TimeInterval[] => {
  const workingInterval: TimeInterval = {
    dateFrom: moment(workingTime.dateFrom).toISOString(),
    dateTo: moment(workingTime.dateTo).toISOString(),
  };

  return busyArray.reduce<TimeInterval[]>((notOverlappedIntervals, busy) => {
    const busyStart: Moment = moment(busy.dateFrom);
    const busyEnd: Moment = moment(busy.dateTo);

    const updatedIntervals: TimeInterval[] = [];

    notOverlappedIntervals.forEach((interval) => {
      const intervalStart: Moment = moment(interval.dateFrom);
      const intervalEnd: Moment = moment(interval.dateTo);

      if (busyEnd.isBefore(intervalStart) || busyStart.isAfter(intervalEnd)) {
        updatedIntervals.push(interval);
      } else if (
        busyStart.isSameOrBefore(intervalStart) &&
        busyEnd.isSameOrAfter(intervalEnd)
      ) {
        // Overlay interval, does nothing here.
      } else if (
        busyStart.isSameOrBefore(intervalStart) &&
        busyEnd.isBefore(intervalEnd)
      ) {
        updatedIntervals.push({
          dateFrom: busyEnd.toISOString(),
          dateTo: interval.dateTo,
        });
      } else if (
        busyStart.isAfter(intervalStart) &&
        busyEnd.isAfter(intervalEnd)
      ) {
        updatedIntervals.push({
          dateFrom: interval.dateFrom,
          dateTo: busyStart.toISOString(),
        });
      } else {
        updatedIntervals.push(
          {
            dateFrom: interval.dateFrom,
            dateTo: busyStart.toISOString(),
          },
          {
            dateFrom: busyEnd.toISOString(),
            dateTo: interval.dateTo,
          },
        );
      }
    });

    return updatedIntervals;
  }, [workingInterval]);
};

export function sumTimeIntervals(intervals: TimeInterval[]) {
  const totalDuration: Duration = intervals.reduce((acc, interval) => {
    const startDate: moment.Moment = moment(interval.dateFrom);
    const endDate: moment.Moment = moment(interval.dateTo);

    const duration: Duration = moment.duration(endDate.diff(startDate));
    acc.add(duration);

    return acc;
  }, moment.duration(0));

  if (totalDuration.seconds()) {
    // round up to the nearest minute
    totalDuration.add(1, 'minutes');
    totalDuration.subtract(totalDuration.seconds(), 'seconds');
  }

  const totalHours: number = Math.max(totalDuration.hours(), 0);
  const totalMinutes: number = Math.max(totalDuration.minutes(), 0);

  return { totalHours, totalMinutes };
}

const duplicateTabHandler = (filters: unknown) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  const openInNewPage = e.button === 1 || e.ctrlKey;

  if (openInNewPage) {
    LocalStorage.saveToLocalStorage({
      duplicatedTab: {
        filters,
      },
    }, DUPLICATED_TAB_KEY);
  }

  return openInNewPage;
};

export { duplicateTabHandler };

import queryString from 'query-string';
import { RSAA, RSAAAction } from 'redux-api-middleware';

import { API_MODULES as modules, API_METHODS as methods } from '../../api.constants';
import * as types from './parcels.constants';

import { ParcelsState } from '../../../../reducers/parcels.reducer.types';
import { ExportPayload, RsaaMethods, RsaaTypes } from '../../api.types';
import { ManualParcelCreateRequest, ParcelRestrictionRequest, ParcelTo } from '../agroevidence.types';

export interface ParcelsApiParams {
    centers?: string,
    context?: string;
    crops?: string,
    ids?: string;
    'land-use'?: string;
    'nitrate-vulnerable'?: boolean,
    page?: number;
    'per-page'?: number;
    search?: string;
    'sort-col'?: string;
    'sort-dir'?: string;
    sown?: boolean;
    status?: string[];
    'valid-from'?: string,
    'valid-to'?: string;
    zones?: string;
}

export const getParcelsApi = (params: ParcelsApiParams) => ({
  [RSAA]: {
    endpoint: `parcels?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    context: params.context ?? null,
    types: [
      types.GET_PARCELS_REQUEST,
      types.GET_PARCELS_SUCCESS,
      types.GET_PARCELS_ERROR,
    ] as RsaaTypes,
  },
});

export const resetParcelsApi = (context?: string) => ({
  type: types.RESET_PARCELS,
  meta: {
    context,
  },
});

export const getParcelsSuggestionsApi = (params: ParcelsApiParams) => ({
  [RSAA]: {
    endpoint: `parcels/suggestions?${queryString.stringify(params)}&`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_PARCELS_SUGGESTIONS_REQUEST,
      types.GET_PARCELS_SUGGESTIONS_SUCCESS,
      types.GET_PARCELS_SUGGESTIONS_ERROR,
    ] as RsaaTypes,
  },
});

export const resetParcelsSuggestionsApi = (context?: string) => ({
  type: types.RESET_PARCELS_SUGGESTIONS,
  meta: {
    context,
  },
});

export const addParcels = (context: string, parcels: ParcelTo) => ({
  type: types.ADD_PARCELS,
  meta: {
    context,
  },
  payload: parcels,
});

export const getParcelsStatisticsApi = () => ({
  [RSAA]: {
    endpoint: 'parcels/statistics?',
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_PARCELS_STATISTICS_REQUEST,
      types.GET_PARCELS_STATISTICS_SUCCESS,
      types.GET_PARCELS_STATISTICS_ERROR,
    ] as RsaaTypes,
  },
});

export const resetParcelsStatisticsApi = () => ({
  type: types.RESET_PARCELS_STATISTICS,
});

export const getParcelsLandUseApi = () => ({
  [RSAA]: {
    endpoint: 'parcels/land-use?',
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_PARCELS_LAND_USE_REQUEST,
      types.GET_PARCELS_LAND_USE_SUCCESS,
      types.GET_PARCELS_LAND_USE_ERROR,
    ] as RsaaTypes,
  },
});

export const resetParcelsLandUseApi = () => ({
  type: types.RESET_PARCELS_LAND_USE,
});

export const getCentersApi = (search?: string) => ({
  [RSAA]: {
    endpoint: `parcels/centers?${search ? `search=${search}&` : ''}`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_CENTERS_REQUEST,
      types.GET_CENTERS_SUCCESS,
      types.GET_CENTERS_ERROR,
    ] as RsaaTypes,
  },
});

export const resetCentersApi = () => ({
  type: types.RESET_CENTERS,
});

export const getParcelApi = (parcelId: string) => ({
  [RSAA]: {
    endpoint: `parcels/${parcelId}?`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_PARCEL_REQUEST,
      types.GET_PARCEL_SUCCESS,
      types.GET_PARCEL_ERROR,
    ] as RsaaTypes,
  },
});

export const resetParcelApi = (context?: string) => ({
  type: types.RESET_PARCEL,
  meta: {
    context,
  },
});

export interface ParcelUpdateApiParams{
  center?: {
    centerId: string,
  },
  name?: string,
  notes?: string,
  userYieldGrade?: 'I' | 'II' | 'III',
  zone?: {
    zoneId: string,
  }
}

export const updateParcelApi = (parcelId: string, params: ParcelUpdateApiParams) => ({
  [RSAA]: {
    endpoint: `parcels/${parcelId}?`,
    method: methods.PATCH as RsaaMethods,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(params),
    types: [
      types.PATCH_PARCEL_REQUEST,
      types.PATCH_PARCEL_SUCCESS,
      types.PATCH_PARCEL_ERROR,
    ] as RsaaTypes,
  },
});

export const getParcelSeedApplicationApi = (parcelId: string, excludeNoCrop?: boolean) => ({
  [RSAA]: {
    endpoint: `parcels/${parcelId}/seed-applications?${excludeNoCrop ? `exclude-no-crop=${excludeNoCrop}&` : ''}`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_PARCEL_SEED_APPLICATION_REQUEST,
      types.GET_PARCEL_SEED_APPLICATION_SUCCESS,
      types.GET_PARCEL_SEED_APPLICATION_ERROR,
    ] as RsaaTypes,
  },
});

export const getParcelPredecessorsApi = (parcelId: string) => ({
  [RSAA]: {
    endpoint: `parcels/${parcelId}/predecessors?`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_PARCEL_PREDECESSORS_REQUEST,
      types.GET_PARCEL_PREDECESSORS_SUCCESS,
      types.GET_PARCEL_PREDECESSORS_ERROR,
    ] as RsaaTypes,
  },
});

export const getParcelEagriRestrictionsApi = (parcelId: string) => ({
  [RSAA]: {
    endpoint: `parcels/${parcelId}/eagri?`,
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.GET_PARCEL_EAGRI_RESTRICTIONS_REQUEST,
      types.GET_PARCEL_EAGRI_RESTRICTIONS_SUCCESS,
      types.GET_PARCEL_EAGRI_RESTRICTIONS_ERROR,
    ] as RsaaTypes,
  },
});

export const getParcelSubtractionsGeometryApi = (params: ParcelRestrictionRequest) => ({
  [RSAA]: {
    endpoint: 'parcels/subtractions/geometry?',
    method: methods.POST as RsaaMethods,
    module: modules.AGROEVIDENCE,
    body: JSON.stringify(params),
    types: [
      types.POST_PARCEL_SUBTRACTIONS_GEOMETRY_REQUEST,
      types.POST_PARCEL_SUBTRACTIONS_GEOMETRY_SUCCESS,
      types.POST_PARCEL_SUBTRACTIONS_GEOMETRY_ERROR,
    ] as RsaaTypes,
  },
});

export const resetParcelSubtractionsGeometryApi = () => ({
  type: types.RESET_PARCEL_SUBTRACTIONS_GEOMETRY,
});

export const exportParcelsApi = () => ({
  [RSAA]: {
    endpoint: 'reports/parcels?',
    method: methods.GET as RsaaMethods,
    module: modules.AGROEVIDENCE,
    types: [
      types.EXPORT_PARCELS_REQUEST,
      {
        type: types.EXPORT_PARCELS_SUCCESS,
        payload: (
          _action: RSAAAction,
          _state: ParcelsState,
          res: Response,
        ) => ({
          body: res.blob(),
          type: res.headers.get('Content-Type'),
          disposition: res.headers.get('Content-Disposition'),
        } as ExportPayload),
      },
      types.EXPORT_PARCELS_ERROR,
    ] as RsaaTypes,
  },
});

export const createParcelApi = (params: ManualParcelCreateRequest, isDryMode: boolean) => ({
  [RSAA]: {
    endpoint: `manual/parcel?dry-mode=${isDryMode}&`,
    method: methods.POST as RsaaMethods,
    body: JSON.stringify(params),
    module: modules.AGROEVIDENCE,
    types: [
      types.CREATE_PARCEL_REQUEST,
      types.CREATE_PARCEL_SUCCESS,
      types.CREATE_PARCEL_ERROR,
    ] as RsaaTypes,
  },
});

import { Tab } from '../../containers/TelematicsTabs/TelematicsTabs';

import { DriveDetailTo, DriveRecalculateTo, OperationTo, ProductionOperationTo, Type, HandworkCreateTo, DriveOverlapRequestTo, WinfasTo, DrivePartDetailTo, DriveValidationType } from '../../../shared/api/telematics/telematics.types';
import { SharedFormValues } from '../TelematicsAggregationDetailContent/DetailContentForm.types';

export interface Props {
  approvalValidationErrors: Record<string, DriveValidationType | undefined>;
  driveDetail?: Partial<DriveDetailTo & DrivePartDetailTo>;
  handleReset: () => void;
  handleSave?: (values: HandworkCreateTo) => void;
  }

export interface ConnectedProps extends Props {
    calculateArea: (driveId: number, equipment: DriveRecalculateTo, changeHandler: (area: number) => void) => void;
    dateFilter?: {
      dateFrom: string;
      dateTo: string;
    },
    driveKeys?: WinfasTo | null;
    formPath?: Tab;
    getAggregatedOverlapsApi: (data: DriveOverlapRequestTo) => void;
    operations: OperationTo[];
    productionOperations: ProductionOperationTo[];
  }

export enum FORM_TYPES {
    CREATE = 'CREATE',
    EDIT = 'EDIT'
  }
export interface TelematicsHandworkFormValues extends SharedFormValues {
    duration: number;
    formType: FORM_TYPES;
    id: number;
    timeFrom: moment.Moment;
    timeIntervals?: string[];
    timeTo: moment.Moment;
    type: Type;
  }

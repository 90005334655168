import React, { FC } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import Scrollbars from 'react-custom-scrollbars-2';
import { FormattedMessage } from 'react-intl';

import CfStaticMap from '../../../shared/components/specific/CfStaticMap/CfStaticMap';
import { COLOR_ERROR } from '../../../theme';
import MapService from '../../map/services/Map.service';

import { FarmUpdateFlatResponse, GeoJson } from '../../../shared/api/agroevidence/agroevidence.types';

const transformData = (geometry: GeoJson) => ([
  {
    geometry: { ...geometry,
      coordinates: MapService.traverseAndTransformCoordinates(geometry.coordinates),
    },
    color: COLOR_ERROR.main.substring(1),
  },
]);

const useStyles = makeStyles((theme: Theme) => ({
  dialogContainer: {
    minWidth: '400px',
  },
  itemContainer: {
    padding: '5px 0px',
  },
  message: {
    fontSize: 18,
    fontWeight: 600,
    marginTop: 10,
    marginBottom: 5,
  },
  blockNrc: {
    color: theme.palette.grey[500],
  },
  map: {
    borderRadius: 0,
    '& .ol-viewport': {
      borderRadius: 0,
    },
  },
  conflictWrapper: {
    height: 300,
  },
}
));

interface UpdateLpisErrorDialogProps {
  farmId: string,
  onClose: () => void,
  showErrorDialog?: boolean,
  updateLpisResult: FarmUpdateFlatResponse
}

const UpdateLpisErrorDialog: FC<UpdateLpisErrorDialogProps> = ({
  farmId,
  onClose,
  showErrorDialog = false,
  updateLpisResult,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.dialogContainer }}
      data-test="update-lpis-error-dialog"
      open={showErrorDialog}
    >
      <DialogTitle>
        <FormattedMessage id="UpdateLPIS.errorDialogTitle" />
      </DialogTitle>
      <Scrollbars autoHeight autoHeightMax={'80vh'}>
        <DialogContent>
          <Grid className={classes.itemContainer}>
            <Stack direction={'column'}>
              <div className={classes.message}>
                <FormattedMessage id={`LpisError.header.code.${updateLpisResult?.shortCode}`} />
              </div>
              <div>
                <FormattedMessage id={`LpisError.message.code.${updateLpisResult?.shortCode}`} />
              </div>
            </Stack>
            {updateLpisResult?.farmUpdateErrorFlatResponse?.map((errorFlatResponse, index) => (
              <Grid key={index}>
                <div className={classNames(classes.message, classes.blockNrc)}>
                  <a
                    color="primary"
                    data-test="parcel-detail-link"
                    href={`/farm/${farmId}/parcels/${errorFlatResponse?.parcelId}/overview`}
                    target="_blank no-referrer noopener"
              >
                    {errorFlatResponse.block}
                  </a>
                </div>
                <Stack direction={'column'} spacing={1}>
                  {errorFlatResponse.conflictingGeometry &&
                  <div>
                    <div><FormattedMessage id={'lpis.update.error.conflictingGeometry'} /></div>
                    <div className={classes.conflictWrapper}>
                      <CfStaticMap
                        classes={{ map: classes.map }}
                        geometries={transformData(errorFlatResponse?.conflictingGeometry)}
                        mapId={`conflicting-geometry-map_${index}`}
                  />
                    </div>
                  </div>
                  }
                  {errorFlatResponse.geometryToRepair &&
                  <div>
                    <div><FormattedMessage id={'lpis.update.error.geometryToRepair'} /></div>
                    <div className={classes.conflictWrapper}>
                      <CfStaticMap
                        classes={{ map: classes.map }}
                        geometries={transformData(errorFlatResponse?.geometryToRepair)}
                        mapId={`geometry-to-repair-map_${index}`}
                  />
                    </div>
                  </div>
                  }
                </Stack>
              </Grid>

            ))}

          </Grid>
        </DialogContent>
      </Scrollbars>
      <DialogActions>
        <Grid alignItems="center" container justifyContent="flex-end" spacing={1}>
          <Button
            color="primary"
            id="close"
            onClick={onClose}
            variant="text"
          >
            <FormattedMessage id="common.close" />
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateLpisErrorDialog;

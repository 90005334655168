import React, { FC, ReactNode } from 'react';

import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { PRECISION_URLS } from '../precision.constants';

import { ImportFilesApiType, ValidateFileApiType, importFilesApi, validateFileApi } from '../../../shared/api/sentinel/asAppliedTaskdata/asAppliedTaskdata.api';
import CfPrimaryTab from '../../../shared/components/common/CfPrimaryTab/CfPrimaryTab';
import CfPrimaryTabs from '../../../shared/components/common/CfPrimaryTabs/CfPrimaryTabs';
import DroplistAdd from '../../../shared/components/common/DroplistAdd/DroplistAdd';
import PageHeader from '../../../shared/components/common/PageHeader/PageHeader';
import PageHeading from '../../../shared/components/common/PageHeading/PageHeading';
import { useDialog } from '../../../shared/hooks/useDialog';
import useToggles from '../../../shared/toggles/toggles';
import TOGGLES from '../../../shared/toggles/toggles.const';
import { Thunk } from '../../../types';

import ImportMachinesDialog from './ImportMachinesDialog';
import { usePrecisionFarmingStyles } from './styles';

import { PrecisionState } from '../../../reducers/precision.reducer.types';

export enum PRECISION_LISTING_TABS {
  AS_APPLIED='as_applied',
  SERVICES='services'
}

const getSelectedTabIndex = (tab: PRECISION_LISTING_TABS): number => {
  if (tab === PRECISION_LISTING_TABS.SERVICES) {
    return 0;
  } else if (tab === PRECISION_LISTING_TABS.AS_APPLIED) {
    return 1;
  }
  return 0;
};
interface Props extends RouteComponentProps<{farmId: string}> {
  children: ReactNode,
  importFilesApi: (params: ImportFilesApiType) => void;
  ngRedirectToVrf: (parcelId: string[]) => void,
  ngRedirectToVrs: (parcelId: string[]) => void,
  tab: PRECISION_LISTING_TABS,
  validateFileApi: (params: ValidateFileApiType) => void;
}

const PrecisionTabs: FC<Props> = ({
  children, history, importFilesApi, match, ngRedirectToVrf, ngRedirectToVrs,
  tab, validateFileApi,
}) => {
  const classes = usePrecisionFarmingStyles();
  const [, , isToggleActive] = useToggles();

  const selectedTabIndex = getSelectedTabIndex(tab);

  const { farmId } = match.params;

  const {
    handleClose: handleMachinesImportClose, handleOpen: handleMachinesImportOpen, isOpen: machinesImportIsOpen,
  } = useDialog();

  const handleServicesTabClick = () => history.push(`/farm/${farmId}/${PRECISION_URLS.services()}`);
  const handleAsAppliedTabClick = () => history.push(`/farm/${farmId}/${PRECISION_URLS.asApplied()}`);

  return (
    <div>
      <div className={classes.container}>
        <PageHeader
          actionButtons={isToggleActive(TOGGLES.PRECISION.name) &&
            <div className={classes.fabContainer}>
              <DroplistAdd
                options={[
                  {
                    dataTest: 'add-var-vert-link',
                    intlId: 'common.addVrfActivity',
                    onClick: () => ngRedirectToVrf([]),
                  },
                  {
                    dataTest: 'add-var-seed-link',
                    intlId: 'common.addVrsActivity',
                    onClick: () => ngRedirectToVrs([]),
                    divider: true,
                  },
                  {
                    intlId: 'PrecisionFarming.add.importRides',
                    dataTest: 'precision-import-rides-xlsx',
                    onClick: handleMachinesImportOpen,
                  },
                ]}
                />
            </div>
          }
          classes={{
            header: classes.header,
          }}
          heading={
            <PageHeading
              dataTest="precision-heading"
              value={
                <FormattedMessage id="common.precision" />
              } />
          }
        />
        <div className={classes.tabsContainer}>
          <CfPrimaryTabs
            centered
            tabValue={selectedTabIndex}
            >
            <CfPrimaryTab
              data-test={'precision-services'}
              onClick={handleServicesTabClick}
              label={<div>
                <FormattedMessage id="PrecisionFarming.tabs.services" />
              </div>}
            />
            {isToggleActive(TOGGLES.PRECISION.name) && <CfPrimaryTab
              data-test={'precision-as-applied'}
              onClick={handleAsAppliedTabClick}
              label={<div>
                <FormattedMessage id="PrecisionFarming.tabs.machineData" />
              </div>}
            />}
          </CfPrimaryTabs>
        </div>
      </div>
      {machinesImportIsOpen &&
      <ImportMachinesDialog
        handleClose={handleMachinesImportClose}
        importFilesApi={importFilesApi}
        open={machinesImportIsOpen}
        validateFileApi={validateFileApi}
      />}
      {children}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Thunk<PrecisionState>) => bindActionCreators({
  importFilesApi,
  validateFileApi,
}, dispatch);

export default connect(undefined, mapDispatchToProps)(PrecisionTabs);

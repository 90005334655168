import React from 'react';

import { Theme } from '@mui/material';
import Fab from '@mui/material/Fab';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.common.white,
    flexShrink: 0,
    marginRight: theme.spacing(2),
  },
}));

type FabProps = Pick<React.ComponentProps<typeof Fab>, 'onClick'> ;
type Props = {
  children: React.ReactNode
}

const FAButton: React.FC<FabProps & Props> = ({
  children, onClick, ...props
}) => {
  const classes = useStyles();

  return (
    <Fab
      aria-label="add button"
      className={classes.button}
      color="secondary"
      onClick={onClick}
      size="medium"
      {...props}
        >
      {children}
    </Fab>
  );
};

export default FAButton;

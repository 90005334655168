import { getCrops } from '../../selectors/crops.selectors';
import {
  getHoveredId,
  getSelectedParcelIds,
  getFilteredReachableIds,
  getAvailableIds,
} from '../../selectors/editor.selectors';
import { getStyle } from '../../selectors/map.selectors';
import { getSowingPlan } from '../../selectors/sowingPlan.selectors';

export const refreshDefaultStyles = () => (_, getState) => {
  const state = getState();
  const service = getStyle(state);
  const hoveredId = getHoveredId(state);
  const selectedIds = getSelectedParcelIds(state);
  const crops = getCrops(state);
  service.refreshDefaultStyles(hoveredId, selectedIds[0], crops);
};

export const refreshSowingPlanStyles = () => (_, getState) => {
  const state = getState();
  const service = getStyle(state);
  const hoveredId = getHoveredId(state);
  const selectedIds = getSelectedParcelIds(state);
  const sowingPlan = getSowingPlan(state);
  if (service !== null) {
    service.refreshSowingPlanStyles(hoveredId, selectedIds[0], sowingPlan);
  }
};

export const refreshSplitStyles = () => (_, getState) => {
  const state = getState();
  const service = getStyle(state);
  const hoveredId = getHoveredId(state);
  const selectedIds = getSelectedParcelIds(state);
  service.refreshSplitStyles(hoveredId, selectedIds[0]);
};

export const refreshMergeStyles = () => (_, getState) => {
  const state = getState();
  const service = getStyle(state);
  const hoveredId = getHoveredId(state);
  const selectedIds = getSelectedParcelIds(state);
  const availableIds = getAvailableIds(state);
  const reachableIds = getFilteredReachableIds(state);
  service.refreshMergeStyles(hoveredId, selectedIds, availableIds, reachableIds);
};

export const refreshBufferStyles = () => (_, getState) => {
  const state = getState();
  const service = getStyle(state);
  const hoveredId = getHoveredId(state);
  const selectedIds = getSelectedParcelIds(state);
  service.refreshSplitStyles(hoveredId, selectedIds[0]);
};

export const refreshDrawStyles = () => (_, getState) => {
  const state = getState();
  const service = getStyle(state);
  service.refreshDrawStyles();
};

export const refreshParcelDetailStyles = cropColor => (_, getState) => {
  const state = getState();
  const service = getStyle(state);
  const hoveredId = getHoveredId(state);
  const selectedIds = getSelectedParcelIds(state);
  service.refreshParcelDetailStyles(hoveredId, selectedIds[0], cropColor);
};

export const refreshTelematicsStyles = () => (_, getState) => {
  const state = getState();
  const service = getStyle(state);
  const hoveredId = getHoveredId(state);
  const selectedIds = getSelectedParcelIds(state);
  service.refreshTelematicsStyles(hoveredId, selectedIds[0]);
};

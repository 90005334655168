import { intersection } from 'lodash';
import { createSelector } from 'reselect';

import { getDriverAggregations } from '../../shared/api/telematics/aggregations/aggregations.selectors';

import { TelematicsState } from '../../reducers/telematics.reducer.types';

export const getTelematicsAggregationsPage = (state: TelematicsState) => state.ui.telematicsAggregations.page;
export const getTelematicsAggregationsOrder = (state: TelematicsState) => state.ui.telematicsAggregations.order;
export const getTelematicsAggregationsOrderBy = (state: TelematicsState) => state.ui.telematicsAggregations.orderBy;
export const getTelematicsAggregationsRowsPerPage = (state: TelematicsState) =>
  state.ui.telematicsAggregations.rowsPerPage;

export const getTelematicsAggregationsTextFilter = (state: TelematicsState) =>
  state.ui.telematicsAggregations.textFilter;

export const getTelematicsAggregationsUnapprovedFilter = (state: TelematicsState) =>
  state.ui.telematicsAggregations.unapprovedRidesOnly;

export const getTelematicsAggregationsBulkEditMode = (state: TelematicsState) =>
  state.ui.telematicsAggregations.bulkEditMode;

export const getTelematicsAggregationsOpenedDriverRows = (state: TelematicsState) =>
  state.ui.telematicsAggregations.openedDriverRows;

export const getTelematicsAggregationsRidesStatusUpdate = (state: TelematicsState) =>
  state.ui.telematicsAggregations.ridesStatusUpdate;

export const getTelematicsAggregationsSelected = (state: TelematicsState) => state.ui.telematicsAggregations.selected;
export const getTelematicsAggregationsSelectedRides = (state: TelematicsState) =>
  state.ui.telematicsAggregations.selectedRides;

export const getTelematicsAggregationsSelectedOnPage =
  createSelector(getDriverAggregations, getTelematicsAggregationsSelected,
    (data, selected) => (data ?? []).reduce((acc, row) => {
      if (selected.includes(row.drives[0].id)) {
        acc.push(row.drives[0].id);
      }
      return acc;
    }, [] as Array<string>));

export const getTelematicsAggregationsSelectedRidesOnPage =
  createSelector(getDriverAggregations, getTelematicsAggregationsSelectedRides,
    (data, selectedRides) => (data ?? []).reduce((acc, row) => {
      const rowRes = intersection(row.drives.map(d => d.id), selectedRides);
      acc.push(...rowRes);
      return acc;
    }, [] as Array<string>));

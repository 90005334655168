import React, { FC, ReactNode, useEffect, useMemo } from 'react';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getIsZonesFetching, getZones } from '../../../../../../shared/api/agroevidence/zones/zones.selectors';

import { getZonesApi } from '../../../../../../shared/api/agroevidence/zones/zones.api';
import CfAutocomplete from '../../../../../../shared/components/common/CfAutocomplete/CfAutocomplete';
import { Thunk } from '../../../../../../types';

import { ParcelsState } from '../../../../../../reducers/parcels.reducer.types';
import { ZoneTo } from '../../../../../../shared/api/agroevidence/agroevidence.types';

export interface ZoneSelectorProps {
  defaultZones?: ZoneTo[],
  getZonesApi: (search?: string) => void,
  isZoneFetching: boolean,
  label: ReactNode,
  onChange: (items: ZoneTo[]) => void,
  suggestions: ZoneTo[]
}

const ZoneSelector: FC<ZoneSelectorProps> = ({
  defaultZones = [],
  getZonesApi,
  isZoneFetching,
  label,
  onChange,
  suggestions,
}) => {
  const intl = useIntl();

  useEffect(() => {
    getZonesApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const extendedeSuggestions = useMemo(() => ([
    {
      id: 'NONE',
      name: intl.formatMessage({ id: 'ParcelDetailOverview.noZoneOption' }),
    },
    ...suggestions,
  ]), [suggestions, intl]);

  return (
    <CfAutocomplete
      defaultValues={defaultZones}
      id="zone-selector"
      isFetching={isZoneFetching}
      isMultiple={true}
      label={label}
      loadOptions={getZonesApi}
      onChange={onChange}
      suggestions={extendedeSuggestions}
      testId="zone-filter"
    />
  );
};

const mapStateToProps = (state: ParcelsState) => ({
  suggestions: getZones(state),
  isZoneFetching: getIsZonesFetching(state),
});

const mapDispatchToProps = (dispatch: Thunk<ParcelsState>) =>
  bindActionCreators(
    {
      getZonesApi,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ZoneSelector);

import React from 'react';

import { Stack, Tooltip, styled } from '@mui/material';
import { useIntl } from 'react-intl';

import catchCropSvg from '../../../../assets/img/icons/catch-crop.svg';

import { SeedApplicationType } from '../../../api/agroevidence/agroevidence.types';

type Props = React.PropsWithChildren<{ cropType?: SeedApplicationType } & Pick<React.ComponentProps<typeof Icon>, 'sx' | 'className' >>;

const CropName = ({ className, children, cropType, sx = [] }: Props) => {
  const intl = useIntl();
  const tooltip = intl.formatMessage({ id: 'common.intercrop' });

  return (
    <Stack alignItems={'center'} className={className} direction={'row'}>
      {cropType === SeedApplicationType.CATCH_CROP
        ? <Tooltip title={tooltip}>
          <Icon src={catchCropSvg} sx={[{ height: 15 }, ...(Array.isArray(sx) ? sx : [sx])]} />
        </Tooltip>
        : undefined}
      {children}
    </Stack>
  );
};

const Icon = styled('img')({
  marginRight: 5,
});

export { CropName };

import React, { useContext, useEffect, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { isFetchingDriverAggregations, getAggregatedDrive } from '../../../shared/api/telematics/aggregations/aggregations.selectors';

import { TELEMATICS_URLS } from '../../telematics.constants';

import { getAggregatedDriveKeysApi, getDriverAggregationDetailApi, resetDriverAggregationDetail } from '../../../shared/api/telematics/aggregations/aggregations.api';
import { Thunk } from '../../../types';
import { TelematicsContext } from '../Telematics/Telematics';
import { getPreviousTelematicsPage } from '../TelematicsTabs/useTelematicsLogger';

import HandworkAggregationDetailLayout from './HandworkAggregationDetailLayout';
import TelematicsAggregationDetailLayout from './StandardAggregationDetailLayout';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';
import { DriveDetailTo, Type } from '../../../shared/api/telematics/telematics.types';

export const BE_SEPARATE_INTERVAL_ERROR_MIN_LENGTH = 'SEPARATION_INTERVAL_LENGTH';

const useStyles = makeStyles(() => ({
  spinnerWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

interface Props {
  catalogueType: string;
  driveDetail?: DriveDetailTo;
  fetchDrive: (driveId: string) => void;
  fetchDriveKeys: (id: string) => void;
  isFetching: boolean;
  ngGoToAction: (actionId?: string) => void;
  resetDrive: () => void;
}

const TelematicsAggregationDetail: React.FC<Props> = ({
  catalogueType, driveDetail, fetchDrive, fetchDriveKeys, isFetching, ngGoToAction, resetDrive,
}) => {
  const location = useLocation();
  const history = useHistory();
  const { farmId } = useContext(TelematicsContext);
  const { driveId } = useParams<{ driveId: string }>();

  const classes = useStyles();

  const [previousPage, setPreviousPage] = useState<keyof typeof TELEMATICS_URLS|undefined>(undefined);

  useEffect(() => () => resetDrive(), [resetDrive]);

  useEffect(() => {
    setPreviousPage(getPreviousTelematicsPage(location, TELEMATICS_URLS));
  }, [location, location.pathname]);

  useEffect(() => {
    if (driveId) {
      fetchDrive(driveId);
      fetchDriveKeys(driveId);
    }
  }, [driveId, fetchDrive, fetchDriveKeys]);

  const handleGoBack = () => {
    if (previousPage && previousPage in TELEMATICS_URLS) {
      history.push(`/farm/${farmId}/${TELEMATICS_URLS[previousPage]}`);
    }
  };

  const isHandwork = !isFetching && driveDetail?.type === Type.HANDWORK;

  if (!driveDetail && isFetching) {
    return (
      <div className={classes.spinnerWrapper}>
        <CircularProgress color="primary" />
      </div>
    );
  }

  return isHandwork ? (
    <HandworkAggregationDetailLayout
      driveDetail={driveDetail}
      driveId={driveId}
      farmId={farmId}
      handleGoBack={handleGoBack}
      isFetching={isFetching}
      ngGoToAction={ngGoToAction}
      />
  ) : (
    <TelematicsAggregationDetailLayout
      catalogueType={catalogueType}
      driveDetail={driveDetail}
      driveId={driveId}
      farmId={farmId}
      handleGoBack={handleGoBack}
      isFetching={isFetching}
      ngGoToAction={ngGoToAction}
      previousPage={previousPage}
    />
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  isFetching: isFetchingDriverAggregations(state),
  driveDetail: getAggregatedDrive(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) => bindActionCreators({
  fetchDrive: getDriverAggregationDetailApi,
  fetchDriveKeys: getAggregatedDriveKeysApi,
  resetDrive: resetDriverAggregationDetail,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TelematicsAggregationDetail);

import React, { FC } from 'react';

import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { ConnectedProps, connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getPrecisionAsAppliedSelected } from '../selectors/precisionAsApplied.selectors';

import { fetchPrecisionAsApplied, resetSelectedAsApplied } from '../actions/precision.actions';

import { deleteTaskdataApi } from '../../../shared/api/sentinel/asAppliedTaskdata/asAppliedTaskdata.api';
import CfDialog from '../../../shared/components/common/CfDialog/CfDialog';
import { AsyncFn, Thunk } from '../../../types';

import { PrecisionState } from '../../../reducers/precision.reducer.types';

const useStyles = makeStyles(() => ({
  dialogContainer: {
    padding: '16px 24px 24px 24px',
  },
  dialogContent: {
    padding: 0,
  },
  dialogActions: {
    padding: 0,
    paddingTop: 48,
  },
  title: {
    padding: 0,
    marginBottom: 24,
  },
  closeIcon: {
    top: 8,
    right: 8,
  },
  text: {
    fontSize: 14,
  },
  cancelButton: {
    color: '#999999',
  },
}));

interface Props {
  handleClose: () => void;
  open: boolean;
}

const DeleteMachinesDialog: FC<Props & ReduxProps> = ({
  deleteTaskdataApi,
  fetchPrecisionAsApplied,
  handleClose,
  open,
  resetSelectedAsApplied,
  selected,
}) => {
  const classes = useStyles();

  const handleOnAccept = () => {
    (deleteTaskdataApi as AsyncFn<number[]>)(selected)
      .then(() => {
        resetSelectedAsApplied();
        fetchPrecisionAsApplied();
        handleClose();
      });
  };

  return (<CfDialog
    acceptButtonVariant="text"
    acceptText={<FormattedMessage id="common.delete" />}
    externalStyle={classes}
    maxWidth="xs"
    onAccept={handleOnAccept}
    onCancel={handleClose}
    opened={open}
    processing={false}
    title={<FormattedMessage id="PrecisionFarming.asApplied.tasks.delete.title" />}
    >
    <div className={classes.text}>
      <FormattedMessage id="PrecisionFarming.asApplied.tasks.delete.desc" />
    </div>
  </CfDialog>);
};

const mapStateToProps = (state: PrecisionState) => ({
  selected: getPrecisionAsAppliedSelected(state),
});

const mapDispatchToProps = (dispatch: Thunk<PrecisionState>) =>
  bindActionCreators(
    {
      deleteTaskdataApi: deleteTaskdataApi as (val: number[]) => void,
      fetchPrecisionAsApplied,
      resetSelectedAsApplied,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;
export default connector(DeleteMachinesDialog);

import React, { FC, ReactNode, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getIsFetchingProductionOperations, getProductionOperations } from '../../../../shared/api/telematics/drives/drives.selectors';
import { selectDateRange } from '../../../selectors/telematicsTabs.selectors';

import { fetchProductionOperations } from '../../../actions/telematicsList.actions';

import { resetProductionOperations } from '../../../../shared/api/telematics/drives/drives.api';
import CfAutocomplete from '../../../../shared/components/common/CfAutocomplete/CfAutocomplete';
import { Thunk } from '../../../../types';

import { TelematicsState } from '../../../../reducers/telematics.reducer.types';
import { ProductionOperationTo } from '../../../../shared/api/telematics/telematics.types';

interface Props {
  dateFrom: string;
  dateTo: string;
  defaultValues?: ProductionOperationTo[];
  externalClasses?: Record<string, string>;
  fetchProductionOperations: (dFrom: string, dTo: string) => void;
  isFetching: boolean;
  label: ReactNode;
  onChange(items: ProductionOperationTo[]): void;
  options: ProductionOperationTo[];
  resetProductionOperations: () => void;
}

const ProductionOperationSelector: FC<Props> = (
  { defaultValues = [],
    options,
    externalClasses,
    isFetching,
    label,
    onChange,
    resetProductionOperations,
    fetchProductionOperations,
    dateFrom,
    dateTo,
  }) => {
  useEffect(() => {
    fetchProductionOperations(dateFrom, dateTo);
    return () => {
      resetProductionOperations();
    };
  },
  [fetchProductionOperations, resetProductionOperations, dateFrom, dateTo]);
  return (
    <CfAutocomplete
      classes={externalClasses}
      defaultValues={defaultValues}
      id="production-operation-selector"
      isFetching={isFetching}
      isMultiple={true}
      label={label}
      onChange={onChange}
      suggestions={options}
      testId="production-operation-filter"
    />
  );
};

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators({
    fetchProductionOperations,
    resetProductionOperations,
  }, dispatch);

const mapStateToProps = (state: TelematicsState) => ({
  options: getProductionOperations(state),
  isFetching: getIsFetchingProductionOperations(state),
  dateFrom: selectDateRange(state).dateFrom,
  dateTo: selectDateRange(state).dateTo,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductionOperationSelector);

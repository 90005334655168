import React, { FC, ReactNode, useEffect, useCallback } from 'react';

import { Chip } from '@mui/material';
import { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material/useAutocomplete';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCrops, getIsFetchingCrops } from '../../../../shared/api/telematics/drives/drives.selectors';
import { selectDateRange } from '../../../selectors/telematicsTabs.selectors';

import { fetchCrops } from '../../../actions/telematicsList.actions';

import * as sortOrder from '../../../../shared/constants/sortOrder.constants';

import { resetCrops } from '../../../../shared/api/telematics/drives/drives.api';
import CfAutocomplete from '../../../../shared/components/common/CfAutocomplete/CfAutocomplete';
import { Thunk } from '../../../../types';
import useAdvancedFilterStyles from '../TelematicsAdvancedFilter.styles';

import { TelematicsState } from '../../../../reducers/telematics.reducer.types';
import { CropSortColumn, CropTo } from '../../../../shared/api/telematics/telematics.types';

interface CropSelectorProps {
  dateFrom: string;
  dateTo: string;
  defaultValues?: CropTo[];
  externalClasses?: Record<string, string>;
  fetchCrops: (dateFrom: string, dateTo: string, sortCol: string, sortDir: string) => void;
  isFetching: boolean;
  label: ReactNode;
  onChange: (items: CropTo[]) => void;
  options: CropTo[];
  resetCrops: () => void;
}

const defaultDefaultValues: CropTo[] = [];

const handleGetSelected = (option: CropTo, value?: CropTo | null) =>
  option.code === value?.code;

const handleFilterOptions = createFilterOptions({
  stringify: ({ code, name }) => `${name} ${code}`,
});

const CropSelector: FC<CropSelectorProps> = (
  { defaultValues = defaultDefaultValues,
    externalClasses = {},
    options,
    isFetching,
    label,
    fetchCrops,
    resetCrops,
    onChange,
    dateFrom,
    dateTo,
  }) => {
  const classes = useAdvancedFilterStyles();

  useEffect(() => {
    fetchCrops(dateFrom, dateTo, CropSortColumn.NAME, sortOrder.ASC);
    return () => {
      resetCrops();
    };
  }, [fetchCrops, resetCrops, dateFrom, dateTo]);

  const handleRenderOption = useCallback((option: CropTo) => (
    <div>
      <div>{option.name ?? option.code}</div>
      <div className={classes.subtext}>{option.code}</div>
    </div>
  ), [classes]);

  const handleRenderTags = useCallback((values: CropTo[], getTagProps: AutocompleteRenderGetTagProps) =>
    <>
      {values.map((value, index) => (
        <Chip
          {...getTagProps({ index })}
          key={value.id ?? value.code}
          label={
            <>
              {value.name ?? value.code}
              <span className={classes.subtext}>{value.code}</span>
            </>
          }
        />
      ))}
    </>, [classes]);

  return (
    <CfAutocomplete
      classes={externalClasses}
      defaultValues={defaultValues}
      filterOptions={handleFilterOptions}
      getSelected={handleGetSelected}
      id="crop-selector"
      isFetching={isFetching}
      isMultiple={true}
      label={label}
      onChange={onChange}
      renderOption={handleRenderOption}
      renderTags={handleRenderTags}
      suggestions={options}
      testId="crop-filter"
    />
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  options: getCrops(state),
  isFetching: getIsFetchingCrops(state),
  dateFrom: selectDateRange(state).dateFrom,
  dateTo: selectDateRange(state).dateTo,
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      fetchCrops,
      resetCrops,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CropSelector);

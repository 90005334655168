import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { IconButton, alpha } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { COLOR_ERROR, COLOR_FONT } from '../../../theme';

import { FileLoadItem } from './FileUploader';

const useStyles = makeStyles((theme: Theme) => ({
  loadedFile: {
    minHeight: 36,
    borderRadius: 4,
    color: theme.palette.common.white,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    padding: 8,
    marginTop: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeIcon: {
    color: theme.palette.common.white,
    padding: 0,
  },
  errorMessage: {
    color: alpha(COLOR_ERROR.main, 0.8),
    fontSize: 12,
    marginTop: 4,
    fontWeight: 400,
  },
  error: {
    background: alpha(COLOR_ERROR.main, 0.8),
  },
  validated: {
    background: alpha(COLOR_FONT.main, 0.8),
  },
  new: {
    background: alpha(COLOR_FONT.main, 0.8),
  },
}));

interface FileItemProps {
  item: FileLoadItem;
  onRemove: (id: string | undefined) => void;
}

const FileItem: React.FC<FileItemProps> = ({ item, onRemove }) => {
  const classes = useStyles();

  return (
    <li key={item.uniqueId}>
      <div className={classnames(classes.loadedFile, classes[item.status])}>
        <span>
          {item.file.name}
        </span>
        <IconButton
          className={classes.closeIcon}
          onClick={() => onRemove(item.uniqueId)}
          size="small"
      >
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
      {item.errorCode && <div className={classes.errorMessage}>
        <FormattedMessage id={`FileUploader.modal.validation.error.${item.errorCode}`} />
        </div>}
    </li>
  );
};

export default FileItem;

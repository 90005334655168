import { AnyAction } from 'redux';

import * as types from './fertilizers.constants';

import { FertilizersState } from './fertilizers.types';

const initialState: FertilizersState = {
  items: [],
  item: undefined,
  totalCount: 0,
  isFetching: false,
  error: undefined,
  dose: undefined,
  isFetchingDose: false,
};

export default () => (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case types.GET_FERTILIZERS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_FERTILIZERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: action.payload,
        totalCount: Number(action.meta.headers.get('X-Total-Count')),
      };
    case types.GET_FERTILIZERS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
        totalCount: 0,
      };
    case types.RESET_FERTILIZERS:
      return {
        ...state,
        items: [],
        error: undefined,
        totalCount: 0,
      };

    case types.GET_FERTILIZER_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_FERTILIZER_SUCCESS:
    case types.UPDATE_FERTILIZER_SUCCESS:
    case types.CREATE_FERTILIZER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        item: action.payload,
      };
    case types.GET_FERTILIZER_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.RESET_FERTILIZER:
      return {
        ...state,
        item: undefined,
      };

    case types.GET_FERTILIZERS_DOSE_REQUEST:
      return {
        ...state,
        isFetchingDose: true,
      };
    case types.GET_FERTILIZERS_DOSE_SUCCESS:
      return {
        ...state,
        isFetchingDose: false,
        dose: action.payload,
      };
    case types.GET_FERTILIZERS_DOSE_ERROR:
      return {
        ...state,
        isFetchingDose: false,
      };
    case types.RESET_FERTILIZERS_DOSE:
      return {
        ...state,
        dose: undefined,
      };

    default:
      return state;
  }
};

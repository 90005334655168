import React, { FC, useContext, useEffect, useState } from 'react';

import { Button, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage, useIntl, FormattedDate } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getFarm, getUpdateLPISResults } from '../../../shared/api/agroevidence/farms/farms.selectors';

import { putFarmUpdateLpisApi, getFarmApi, resetFarmApi, getFarmUpdateLpisResultsApi, resetFarmUpdateLpisResultsApi } from '../../../shared/api/agroevidence/farms/farms.api';
import CfLoader from '../../../shared/components/common/CfLoader/CfLoader';
import PageHeader from '../../../shared/components/common/PageHeader/PageHeader';
import PageHeading from '../../../shared/components/common/PageHeading/PageHeading';
import { SnackbarContext } from '../../../shared/containers/SnackbarProvider/SnackbarProvider';
import UpdateLpisIcon from '../../../shared/icons/navbar/UpdateLpisIcon';
import { AsyncFn, Thunk } from '../../../types';

import UpdateLpisDialog from './UpdateLpisDialog';
import UpdateLpisErrorDialog from './UpdateLpisErrorDialog';

import { UserState } from '../../../reducers/user.reducer.types';
import { FarmUpdateFlatResponse, FarmUpdateState } from '../../../shared/api/agroevidence/agroevidence.types';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    paddingBottom: theme.spacing(2),
  },
  wrapper: {
    padding: theme.spacing(2),
    height: '100%',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 36,
  },
  info: {
    marginTop: 48,
    textAlign: 'center',
  },
  icon: {
    marginRight: 10,
    color: '#FFFFF',
  },
  errorButton: {
    color: theme.palette.error.main,
    background: 'none',
    border: 'none',
    padding: 0,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  lastSuccessUpdateDate: {
    marginBottom: 10,
  },
  loading: {
    margin: '10px 0px',
  },
}));

interface UpdateLpisProps {
  farmId: string,
  getFarmApi: (farmId: string) => void,
  getFarmUpdateLpisResultsApi: () => void,
  putFarmUpdateLpisApi: (farmId: string, date: string, updateType: string) => void,
  resetFarmApi: () => void,
  resetFarmUpdateLpisResultsApi: () => void,
  updateLpisResults: FarmUpdateFlatResponse[]
}

const UpdateLpis: FC<UpdateLpisProps> = ({
  farmId,
  getFarmApi,
  getFarmUpdateLpisResultsApi,
  putFarmUpdateLpisApi,
  resetFarmApi,
  resetFarmUpdateLpisResultsApi,
  updateLpisResults = [],
}) => {
  const classes = useStyles();
  const showSnackbar = useContext(SnackbarContext);
  const intl = useIntl();

  const [showDialog, setShowDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  useEffect(() => {
    getFarmApi(farmId);
    getFarmUpdateLpisResultsApi();

    const interval = setInterval(() => {
      getFarmUpdateLpisResultsApi();
    }, 100000);

    return () => {
      resetFarmApi();
      resetFarmUpdateLpisResultsApi();
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDialogOpen = () => {
    setShowDialog(true);
  };
  const handleDialogClose = () => {
    setShowDialog(false);
  };

  const handleErrorDialogOpen = () => {
    setShowErrorDialog(true);
  };
  const handleErrorDialogClose = () => {
    setShowErrorDialog(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDialogAccept = (wsKey: string, date: string, updateType: string) => {
    // TODO add date as parametrs into putFarmUpdateLpisApi.
    // wsKey will send to BE with new endpoint that will be create
    (putFarmUpdateLpisApi as AsyncFn<string, string, string>)(farmId, date, updateType).then((res) => {
      if (res.error) {
        showSnackbar({ message: <FormattedMessage id={'UpdateLPIS.dialog.error'} />, isError: true });
      }
      getFarmUpdateLpisResultsApi();
      handleDialogClose();
    });
  };

  const renderStatus = (data: FarmUpdateFlatResponse[]) => {
    if (data.length === 0 || data[0].state === FarmUpdateState.SUCCESS) {
      return '';
    }
    if (data[0].state === FarmUpdateState.IN_PROGRESS) {
      return (
        <>
          <div className={classes.loading}><CfLoader /></div>
          <div>{intl.formatMessage({ id: 'UpdateLPIS.lastUpdateState.inprogress' })}</div>
        </>
      );
    }
    const date = <FormattedDate value={data[0].startedAt} />;

    return (
      <div>
        {intl.formatMessage({ id: 'UpdateLPIS.lastUpdateState.failure' }, { date })}{' '}
        <button className={classes.errorButton} onClick={handleErrorDialogOpen}>
          <FormattedMessage id={'UpdateLPIS.lastUpdateState.failure_button'} />
        </button>
      </div>
    );
  };

  const renderSuccessUpdateTime = (data: FarmUpdateFlatResponse[]) =>
    (data[0]?.lastSuccessUpdateDate ? (
      <span>
        <FormattedDate
          value={updateLpisResults[0]?.lastSuccessUpdateDate} />
      </span>
    ) : '-');

  const isUpdateInProgress = updateLpisResults[0]?.state === FarmUpdateState.IN_PROGRESS;

  return (
    <div className={classes.wrapper}>
      {showDialog &&
        <UpdateLpisDialog
          lastUpdateDate={updateLpisResults[0]?.lastSuccessUpdateDate}
          onAccept={handleDialogAccept}
          onClose={handleDialogClose}
          showDialog={showDialog}
        />
      }
      {showErrorDialog &&
        <UpdateLpisErrorDialog
          farmId={farmId}
          onClose={handleErrorDialogClose}
          showErrorDialog={showErrorDialog}
          updateLpisResult={updateLpisResults[0]}
        />
      }
      <PageHeader
        classes={{ header: classes.header }}
        heading={<PageHeading value={<FormattedMessage id="UpdateLPIS.title" />} />}
      />
      <div className={classes.info}>
        <div className={classes.lastSuccessUpdateDate}>
          <FormattedMessage id="UpdateLPIS.lastSuccessUpdateDate" />
          {renderSuccessUpdateTime(updateLpisResults)}
        </div>
        {renderStatus(updateLpisResults)}
      </div>
      <div className={classes.button}>
        <Button
          color="primary"
          data-test={'update-lpis'}
          disabled={isUpdateInProgress}
          onClick={handleDialogOpen}
          variant="contained"
        >
          <UpdateLpisIcon className={classes.icon} />
          <FormattedMessage id="UpdateLPIS.button" />
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: UserState) => ({
  farm: getFarm(state),
  updateLpisResults: getUpdateLPISResults(state),
});

const mapDispatchToProps = (dispatch: Thunk<UserState>) =>
  bindActionCreators(
    {
      putFarmUpdateLpisApi,
      getFarmApi,
      resetFarmApi,
      getFarmUpdateLpisResultsApi,
      resetFarmUpdateLpisResultsApi,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(UpdateLpis);

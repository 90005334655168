import React from 'react';

import { Breakpoint } from '@mui/material';
import Grid, { GridProps } from '@mui/material/Grid';
import { withStyles } from '@mui/styles';

type Props = {
  actionButtons?: React.ReactNode;
  backButton?: React.ReactNode;
  classes: Record<'actionButtons' | 'header', string>;
  heading?: React.ReactNode;
};

type GridSize = Partial<Record<Breakpoint, number>>;

/**
  * @deprecated Please use PageHeaderV2 instead
  */
function PageHeader({ actionButtons = null, backButton = null, classes, heading = null }: Props) {
  return (
    <Grid className={classes.header} container spacing={2}>
      <Grid item sm={2} xs={12}>
        {backButton}
      </Grid>
      <Grid item sm={8} xs={12}>
        {heading}
      </Grid>
      <Grid className={classes.actionButtons} item sm={2} xs={12}>
        {actionButtons}
      </Grid>
    </Grid>
  );
}

const styles = {
  header: {
    alignItems: 'center',
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
};

const PageHeaderV2 = (props: React.PropsWithChildren<GridProps>) =>
  <Grid container spacing={2} sx={{ alignItems: 'center' }} {...props} />;

const Item = (props: React.PropsWithChildren<GridProps>) => <Grid item sm={2} xs={12} {...props} />;
const Heading = (props: React.PropsWithChildren<GridProps>) => <Grid item sm={8} xs={12} {...props} />;
const FlexItem = (props: React.PropsWithChildren<GridProps>) => <Item sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} {...props} />;

PageHeaderV2.Item = Item;
PageHeaderV2.Heading = Heading;
PageHeaderV2.FlexItem = FlexItem;

const mobileItemGridSize: GridSize = {
  md: 6,
  sm: 6,
  xs: 6,
};

const desktopItemGridSize: GridSize = {
  md: 4,
  sm: 12,
  xs: 12,
};

export default withStyles(styles)(PageHeader);
export { PageHeaderV2, mobileItemGridSize, desktopItemGridSize };

import React from 'react';

import { connect } from 'react-redux';

import { LANGUAGE_ID, LANG_KEYS } from '../../../../../shared/lang/lang.constants';

import BaseContainer from '../../../../../shared/containers/BaseContainer/BaseContainer';
import ParcelCropList from '../../../cropList/ParcelCropsList';
import { getParcelSeasonState } from '../../../shared/selectors/parcelSeasonState.selector';
import translationsCZ from '../../lang/locale-cs-CZ.json';
import translationsEL from '../../lang/locale-el-GR.json';
import translationsEN from '../../lang/locale-en-US.json';
import translationsES from '../../lang/locale-es-ES.json';
import translationsRO from '../../lang/locale-ro-RO.json';
import translationsRU from '../../lang/locale-ru-UA.json';
import translationsSR from '../../lang/locale-sr-LATN-RS.json';
import ParcelList from '../ParcelList/ParcelList';

import { ParcelsState } from '../../../../../reducers/parcels.reducer.types';
import { FarmTo } from '../../../../../shared/api/agroevidence/agroevidence.types';
import { SEASON_STATE } from '../../../../../shared/api/agroevidence/parcels/parcels.types';

const translations = {
  [LANG_KEYS.CZ]: translationsCZ,
  [LANG_KEYS.EN]: translationsEN,
  [LANG_KEYS.SR]: translationsSR,
  [LANG_KEYS.ES]: translationsES,
  [LANG_KEYS.RU]: translationsRU,
  [LANG_KEYS.EL]: translationsEL,
  [LANG_KEYS.RO]: translationsRO,
};

type Props = {
  farm: FarmTo;
  langId: LANGUAGE_ID;
  seasonState: SEASON_STATE;
}

const ParcelListWrapper = (props: Props) => {
  const { farm, langId } = props;
  return (
    <BaseContainer langId={langId} translations={translations}>
      {props.seasonState === SEASON_STATE.CURRENT ? (
        <ParcelList
          {...props}
          farmId={farm.id}
        />
      ) : (
        <ParcelCropList
          {...props}
          farmId={farm.id}
        />
      )}
    </BaseContainer>
  );
};

const mapStateToProps = (state: ParcelsState) => ({
  seasonState: getParcelSeasonState(state),
});

export default connect(mapStateToProps)(ParcelListWrapper);

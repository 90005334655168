import React, { CSSProperties, FC, ReactNode } from 'react';

import TableBody from '@mui/material/TableBody';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { getPrecisionAsAppliedIsFetching, getPrecisionAsAppliedSelectedOnPage, getPrecisionTaskdata,
  getPrecisionTaskdataCount } from '../../../shared/api/sentinel/precision/precisionAsApplied.selectors';
import { getPrecisionAsAppliedOrder, getPrecisionAsAppliedOrderBy, getPrecisionAsAppliedPage,
  getPrecisionAsAppliedRowsPerPage, getPrecisionAsAppliedSelected } from '../selectors/precisionAsApplied.selectors';

import { NAMESPACE as namespace } from '../reducer/precisionAsApplied.reducer';

import CfTableBodyEmpty from '../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty';
import CfTableBodyLoader from '../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader';
import CfTableWrapper from '../../../shared/components/tables/CfTableWrapper/CfTableWrapper';
import CfTableFooter from '../../../shared/containers/CfTableFooter/CfTableFooter';
import CfTableHead from '../../../shared/containers/CfTableHead/CfTableHead';

import AsAppliedRow from './AsAppliedRow';

import { PrecisionState } from '../../../reducers/precision.reducer.types';
import { TaskDataListTo } from '../../../shared/api/satellite/satellite.types';

const getColDesc = (sortable: boolean, label: ReactNode, style?: CSSProperties, rightPadding?: boolean) => ({
  align: 'inherit',
  sortable,
  label,
  style,
  rightPadding,
});

const columns = {
  created: getColDesc(
    false,
    <FormattedMessage id="PrecisionFarming.asApplied.column.importDate" />,
    { paddingLeft: 21, width: '110px' },
  ),
  filename: getColDesc(
    false,
    <FormattedMessage id="PrecisionFarming.asApplied.column.fileName" />,
    { width: '240px' },
  ),
  tasks: getColDesc(
    false,
    <span>
      <FormattedMessage id="PrecisionFarming.asApplied.column.taskCount" />
    </span>,
    { width: '90px' },
  ),
  dateFrom: getColDesc(
    false,
    <span>
      <FormattedMessage id="PrecisionFarming.asApplied.column.taskDateFrom" />
    </span>,
    { width: '120px' },
  ),
  dateTo: getColDesc(
    false,
    <span>
      <FormattedMessage id="PrecisionFarming.asApplied.column.taskDateTo" />
    </span>,
    { width: '120px' },
  ),
  duration: getColDesc(
    false,
    <span>
      <FormattedMessage id="PrecisionFarming.asApplied.column.taskDuration" />
    </span>,
    { width: '100px', textAlign: 'right' },
  ),
  actions: getColDesc(
    false,
    <span />,
    { width: '140px' },
  ),
};

export interface PrecisionAsAppliedTableProps {
  count: number;
  farmId: string;
  isFetching: boolean;
  langId: string,
  order: string;
  orderBy: string;
  page: number;
  rowsPerPage: number;
  selected: number[];
  selectedOnPage: number[];
  taskdata: TaskDataListTo[];
}

export const PrecisionAsAppliedTable: FC<PrecisionAsAppliedTableProps> = ({
  count,
  farmId,
  isFetching,
  langId,
  order,
  orderBy,
  page,
  rowsPerPage,
  selected,
  selectedOnPage,
  taskdata,
}) =>
  (
    <CfTableWrapper testId="precision-as-applied-list">
      <CfTableHead
        columns={columns}
        items={taskdata}
        langId={langId}
        namespace={namespace}
        order={order}
        orderBy={orderBy}
        selected={selected}
        selectedOnPage={selectedOnPage}
      />
      {isFetching && <CfTableBodyLoader columns={columns} />}

      {taskdata.length ? (
        <TableBody>
          {taskdata.map((row) => (
            <AsAppliedRow
              columnsLength={Object.keys(columns).length + 1}
              data={row}
              farmId={farmId}
              key={row.id}
            />
          ))}
        </TableBody>
      ) : (
        <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
      )}
      <CfTableFooter
        count={count}
        namespace={namespace}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </CfTableWrapper>
  );
const mapStateToProps = (state: PrecisionState) => ({
  taskdata: getPrecisionTaskdata(state),
  isFetching: getPrecisionAsAppliedIsFetching(state),
  page: getPrecisionAsAppliedPage(state),
  order: getPrecisionAsAppliedOrder(state),
  orderBy: getPrecisionAsAppliedOrderBy(state),
  rowsPerPage: getPrecisionAsAppliedRowsPerPage(state),
  count: getPrecisionTaskdataCount(state),
  selected: getPrecisionAsAppliedSelected(state),
  selectedOnPage: getPrecisionAsAppliedSelectedOnPage(state),
});

export default connect(mapStateToProps)(PrecisionAsAppliedTable);

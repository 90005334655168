import React from 'react';

import { FormControlLabel, Switch, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import { NAMESPACE } from '../../reducer/telematicsAggregations.reducer';

import CfTextFilter from '../../../shared/containers/CfTextFilter/CfTextFilter';

const useStyles = makeStyles((theme: Theme) => ({
  filters: {
    display: 'flex',
    height: 40,
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      display: 'grid',
      gridTemplateColumns: 'auto auto auto',
      gridTemplateRows: '40px',
      gridTemplateAreas: `
        'text-filter text-filter text-filter'
        'date . toggle'
      `,
      height: 'auto',
    },
  },
  date: {
    gridArea: 'date',
    justifySelf: 'flex-start',
    alignSelf: 'end',
  },
  textFilter: {
    gridArea: 'text-filter',
    justifySelf: 'center',
    maxWidth: 400,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
      maxWidth: 'unset',
      marginLeft: 0,
    },
  },
  toggle: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto',
    },
    gridArea: 'toggle',
    justifySelf: 'flex-end',
  },
  switch: {
    marginLeft: 'auto',
  },
  switchLabel: {
    fontSize: 14,
  },
}));

interface Props {
    setUnapprovedRidesFilter: (payload: boolean) => void;
    textFilter: string;
    unapprovedRidesOnly: boolean;
  }

const TableFilters: React.FC<Props> = ({
  setUnapprovedRidesFilter,
  textFilter,
  unapprovedRidesOnly,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.filters}>
      <div className={classes.textFilter}>
        <CfTextFilter
          initialValue={textFilter}
          namespace={NAMESPACE}
          translId="TelematicsAggregations.textFilterPlaceholder"
    />
      </div>
      <div className={classes.toggle}>
        <FormControlLabel
          classes={{ label: classes.switchLabel }}
          className={classes.switch}
          label={<FormattedMessage id="TelematicsAggregations.list.filterUnapproved" />}
          control={
            <Switch
              checked={unapprovedRidesOnly}
              color="primary"
              onChange={() => setUnapprovedRidesFilter(!unapprovedRidesOnly)}
        />}
    />
      </div>
    </div>
  );
};

export default TableFilters;

import { AnyAction } from 'redux';

import * as types from '../asAppliedTaskdata/asAppliedTaskdata.constants';

import { PrecisionAsApplied } from './precision.types';

export const NAMESPACE = 'precisionAsAppliedList';

const initialState: PrecisionAsApplied = {
  error: undefined,
  isFetching: false,
  taskdata: [],
  fileImportError: undefined,
  count: 0,
};

export default () => (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case types.GET_AS_APPLIED_TASKDATA_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: {},
      };
    case types.GET_AS_APPLIED_TASKDATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        taskdata: action.payload,
        count: Number(action.meta.headers.get('X-Total-Count')),
      };
    case types.GET_AS_APPLIED_TASKDATA_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.AS_APPLIED_IMPORT_FILES_REQUEST:
      return {
        ...state,
        fileImportError: undefined,
      };
    case types.AS_APPLIED_IMPORT_FILES_ERROR:
      return {
        ...state,
        fileImportError: true,
      };
    case types.AS_APPLIED_SET_FILE_ERROR:
      return {
        ...state,
        fileImportError: action.payload,
      };

    default:
      return state;
  }
};

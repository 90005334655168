import React from 'react';

import { Point } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import VectorSource from 'ol/source/Vector';
import { Icon, Style } from 'ol/style';

import { selectHighlightPoint } from '../../../selectors/asAppliedDetail.selectors';

import higlightIcon from '../../../../../assets/img/as-applied-pin.svg';

const HighlightLayer = ({ highlight, map }: { highlight: ReturnType<typeof selectHighlightPoint>, map?: Map; }) => {
  const highlightLayer = React.useRef(new VectorLayer({
    source: new VectorSource<Point>(),
    zIndex: 100,
  }));

  if (!map) {
    return null;
  }

  if (!highlight) {
    highlightLayer.current.getSource()?.refresh();
  } else {
    highlightLayer.current.getSource()?.refresh();
    highlightLayer.current.getSource()?.addFeature(highlight);

    highlightLayer.current.setStyle(iconStyle);

    highlightLayer.current.setMap(map);
  }

  return null;
};

const iconStyle = new Style({
  image: new Icon({
    anchor: [0.5, 26],
    anchorXUnits: 'fraction',
    anchorYUnits: 'pixels',
    src: higlightIcon,
  }),
});

export { HighlightLayer };

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const usePrecisionFarmingStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.common.white,
    flexShrink: 0,
    marginRight: theme.spacing(2),
  },
  actionButton: {
    display: 'flex',
    marginRight: theme.spacing(2),
  },
  header: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  tabsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  fabContainer: {
    marginLeft: 16,
  },
}));

export const usePrecisionListStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(2),
    height: '100%',
  },
  header: {
    paddingBottom: theme.spacing(2),
  },
  textFilter: {
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    flexGrow: 1,
    order: 2,
    alignItems: 'center',
    display: 'flex',
    gap: 12,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
      width: '100%',
      order: 1,
    },
  },
  noPrecision: {
    padding: theme.spacing(2),
  },
  bulkDeleteButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 3,
    minWidth: 'unset',
  },
}));

import { RsaaApiError } from '../../../types';
import { Tab } from '../../containers/TelematicsTabs/TelematicsTabs';

type Props = {
  driversAggregatedExportError: RsaaApiError;
  exportAggregatedDrives: () => void;
  exportAggregatedMachinesDrives: () => void;
  exportDrives: () => void;
  isExportingDriversAggregated: boolean;
  isExportingMachinesAggregated: boolean;
  machinesAggregatedExportError: RsaaApiError;
  selectedTelematicsTab: Tab;
}

export function useTelematicsExportState({
  driversAggregatedExportError, exportAggregatedDrives, exportAggregatedMachinesDrives,
  exportDrives, isExportingDriversAggregated, isExportingMachinesAggregated,
  machinesAggregatedExportError, selectedTelematicsTab,
}: Props) {
  return {
    tabExportError: selectedTelematicsTab === 'drivers'
      ? driversAggregatedExportError : machinesAggregatedExportError,
    tabIsExporting: selectedTelematicsTab === 'drivers' ?
      isExportingDriversAggregated : isExportingMachinesAggregated,
    // eslint-disable-next-line no-nested-ternary
    exportTabDrives: selectedTelematicsTab === 'drivers' ?
      exportAggregatedDrives : selectedTelematicsTab === 'machines' ?
        exportAggregatedMachinesDrives : exportDrives,
  };
}

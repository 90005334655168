/**
 * Classifier controller
 */

import Toggles from "../../../../shared/toggles/toggles.const"
import LocalStorage from "../../../../shared/services/LocalStorage.service";

/*@ngInject*/
export default function ClassifiersController(NgTableParams, Modal, Tabs, $scope, $state, Validation, Logger, UserStorage, FarmsResource, $stateParams) {
    var vm = this;

    // private
    var resource = {};
    vm.forms = [];

    // public
    vm.delAll = true;
    vm.items = [];

    vm.isLoaded = false;
    vm.loadingPromise = undefined;
    vm.isLoaded = false;
    vm.originalItems = [];
    vm.tabs = Tabs.getTabsTpl("classifiers");
    vm.tableParams = {};
    vm.checkboxes = {};

    vm.toBeCreated = [];
    vm.isAdmin = isAdmin();

    /**
     * Classifiers controller
     * @namespace Classifiers
     */
    vm.activate = activate;
    vm.setResource = setResource;
    vm.isFarmWithTelematics = false;
    
    vm.createItems = createItems;
    vm.cancelItemsCreate = cancelItemsCreate;
    vm.editItems = editItems;
    vm.cancelEdit = cancelEdit;
    vm.startItemEdit = startItemEdit;
    vm.deleteItems = deleteItems;
    vm.registerBlankItem = registerBlankItem;
    vm.getCheckedItems = getCheckedItems;

    getTelematicsStatus($stateParams.farmId)

    ////////////////////////////////////////////

    function activate(farmId, queryParams) {

        Validation.configure({
            showErrorMessage: false
        });
        vm.isLoaded = false;
        vm.farmId = farmId;

        vm.checkboxes = { checked: false, items: {} };
        const retrievedParams = queryParams || {};
        vm.tableParams = new NgTableParams(retrievedParams, {
            getData: function (p) {
                vm.loadingPromise = resource.getItems(p).then(function (result) {
                    vm.originalItems = angular.copy(result);
                    vm.isLoaded = true;
                    return result;
                });
                return vm.loadingPromise;
            }
        });
    }

    /**
     * Creates a provided item.
     * @memberOf Classifiers
     * @param {Object} item - An item to be created.
     * @param {Array} [attrs=[]] - An array of attributes.
     * @returns {Object} promise
     */
    function createItems(items) {
        items = items ? items : vm.toBeCreated;

        if (!_.isObject(items) || _.isEmpty(items)) {
            return;
        }

        var pass = true;
        _.each(items, function (item) {
            if (!Validation.checkForm(item.form)) {
                Validation.validate(item.form);
                pass = false;
            }
        });

        if (!pass) {
            return false;
        }

        _.each(items, function (item) {
            delete item.isNew;
            delete item.isEdited;
        });

        return resource.createItems(items)
            .then(function () {
                vm.tableParams.reload();
                _.each(vm.toBeCreated, function (item) {
                    //TODO here we need at least IDs of created items;
                    var obj = _.find(vm.tableParams.data, function (obj) { return obj.id === item.id; });
                    obj.isCreated = true;
                });
            })
            .finally(function () {
                vm.toBeCreated = [];
            });
    }

    function cancelItemsCreate(items) {
        items = items ? items : _.cloneDeep(vm.toBeCreated);
        _.each(items, function (item) {
            var index1 = vm.tableParams.data.indexOf(_.find(vm.tableParams.data, function (obj) { return obj.$$hashKey === item.$$hashKey; }));
            var index2 = vm.toBeCreated.indexOf(_.find(vm.toBeCreated, function (obj) { return obj.$$hashKey === item.$$hashKey; }));
            if (index1 >= 0) {
                vm.tableParams.data.splice(index1, 1);
            }
            if (index2 >= 0) {
                vm.toBeCreated.splice(index2, 1);
            }
        });

    }

    function startItemEdit(item) {
        item.isEdited = true;
    }

    /**
     * Edits an array of provided items.
     * @memberOf Classifiers
     * @param {Array} items - Array of the items to be edited.
     * @returns {Object} promise
     */
    function editItems(items) {
        items = items ? items : getCheckedItems();

        if (!_.isObject(items) || _.isEmpty(items)) {
            Logger.error("Invalid object.");
            return;
        }

        var pass = true;
        _.each(items, function (item) {
            if (!Validation.checkForm(item.form)) {
                Validation.validate(item.form);
                pass = false;
                Logger.error("Invalid form.");
            }
        });

        if (!pass) {
            return false;
        }

        _.each(items, function (item) {

            //hotfix for missing @class in seeds
            if (item.route === "seeds") {
                item["@class"] = "MaterialSeed";
            }
            // end of hotfix

            item.isEdited = false;
            delete item.isEdited;
        });

        return resource.editItems(items)
            .then(function () {
                vm.tableParams.reload();
            });
    }

    /**
     * Cancels editing of the provided item.
     * @memberOf Classifiers
     * @param {Object} item - An item which editing to be canceled.
     * @return {undefined}
     */
    function cancelEdit(item) {
        item.isEdited = false;
        var originalItem = _.find(vm.originalItems, function (i) {
            return i.id === item.id;
        });
        angular.extend(item, originalItem);
    }

    /**
     * Deletes an array of provided items.
     * @memberOf Classifiers
     * @param {Array} items - An array of items to be deleted. Min length = 1.
     * @returns {Object} promise
     */
    function deleteItems(items) {
        items = items ? items : getCheckedItems();
        if (!_.isObject(items) || _.isEmpty(items)) {
            return;
        }

        var config = {
            data: items,
            size: 'md',
            templateUrl: 'app/assets/templates/modals/deleteModalClassifiers.html'
        };

        return Modal.activateModal(config).then(function (items) {
            return resource.deleteItems(items).then(function () {
                vm.checkboxes.checked = false;
                vm.tableParams.page(1);
                vm.tableParams.reload();
            });
        });
    }

    function getCheckedItems() {
        var itemsToBeProcessed = [];
        _.each(vm.tableParams.data, function (item) {
            if (vm.checkboxes.items[item.id]) {
                itemsToBeProcessed.push(item);
            }
        });
        return itemsToBeProcessed;
    }

    function registerBlankItem(obj) {
        var pages = vm.tableParams.generatePagesArray();
        var last = _.find(pages, function (obj) { return obj.type === "last"; });
        if (last && vm.tableParams.page() < last.number) {
            vm.tableParams.page(last.number);
            vm.tableParams.reload().then(function () {
                vm.toBeCreated.push(obj);
                vm.tableParams.data.push(obj);
            });
        } else {
            vm.toBeCreated.push(obj);
            vm.tableParams.data.push(obj);
        }
    }

    function isAdmin() {
        const user = UserStorage.getUser();
        const authorities = user.authorities;
        return _.indexOf(authorities, 'ROLE_ADMIN') !== -1;
    }

    function getTelematicsStatus(farmId) {
        FarmsResource.getTelematicsStatus(farmId)
        .then(data => {
            vm.isFarmWithTelematics = data?.telematics || false;
        })
    }

    $scope.$watch(function () { return vm.checkboxes.items; }, function (newVal, oldVal) {
        if (newVal !== oldVal) {
            var counter = 0;
            _.each(newVal, function (item) {
                if (item) {
                    counter++;
                }
            });
            // vm.delAll = counter > 1 ? false : true;
            vm.delAll = counter;
        }
    }, true);

    function setResource(initResource) {
        resource = initResource;
    }

}

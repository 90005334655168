import queryString from 'query-string';
import { RSAA } from 'redux-api-middleware';

import { API_MODULES as modules } from '../../api.constants';
import * as types from './parcels.constants';

export const getParcelsMapActionable = params => {
  const p = {
    page: 1,
    'per-page': 50,
    ids: params.ids,
    'neighbors-more-than': params.neighborsMoreThan,
    localNameBlockNr: params.localNameBlockNr,
  };

  return {
    [RSAA]: {
      endpoint: `parcels/map/actionable?${queryString.stringify(p)}&`,
      method: 'GET',
      context: null,
      module: modules.CORE,
      types: [
        types.GET_PARCELS_MAP_ACTIONABLE,
        types.GET_PARCELS_MAP_ACTIONABLE_SUCCESS,
        types.GET_PARCELS_MAP_ACTIONABLE_FAILURE,
      ],
    },
  };
};

export const resetParcelsMapActionable = () => ({
  type: types.RESET_PARCELS_MAP_ACTIONABLE,
  meta: {
    context: null,
  },
});

const PREFIX = 'TELEMATICS_DRIVES';

export const GET_DRIVES_REQUEST = `${PREFIX}/GET_DRIVES_REQUEST`;
export const GET_DRIVES_SUCCESS = `${PREFIX}/GET_DRIVES_SUCCESS`;
export const GET_DRIVES_ERROR = `${PREFIX}/GET_DRIVES_ERROR`;

export const CHANGE_DRIVE_STATE_REQUEST = `${PREFIX}/CHANGE_DRIVE_STATE_REQUEST`;
export const CHANGE_DRIVE_STATE_SUCCESS = `${PREFIX}/CHANGE_DRIVE_STATE_SUCCESS`;
export const CHANGE_DRIVE_STATE_SUCCESS_ERROR = `${PREFIX}/CHANGE_DRIVE_STATE_ERROR`;

export const GET_DRIVE_DETAIL_REQUEST = `${PREFIX}/GET_DRIVE_DETAIL_REQUEST`;
export const GET_DRIVE_DETAIL_SUCCESS = `${PREFIX}/GET_DRIVE_DETAIL_SUCCESS`;
export const GET_DRIVE_DETAIL_ERROR = `${PREFIX}/GET_DRIVE_DETAIL_ERROR`;
export const RESET_DRIVE_DETAIL = `${PREFIX}/RESET_DRIVE_DETAIL`;

export const CREATE_DRIVE_REQUEST = `${PREFIX}/CREATE_DRIVE_REQUEST`;
export const CREATE_DRIVE_SUCCESS = `${PREFIX}/CREATE_DRIVE_SUCCESS`;
export const CREATE_DRIVE_ERROR = `${PREFIX}/CREATE_DRIVE_ERROR`;

export const EDIT_DRIVE_REQUEST = `${PREFIX}/EDIT_DRIVE_REQUEST`;
export const EDIT_DRIVE_SUCCESS = `${PREFIX}/EDIT_DRIVE_SUCCESS`;
export const EDIT_DRIVE_ERROR = `${PREFIX}/EDIT_DRIVE_ERROR`;

export const GET_ACTION_TYPES_REQUEST = `${PREFIX}/GET_ACTION_TYPES_REQUEST`;
export const GET_ACTION_TYPES_SUCCESS = `${PREFIX}/GET_ACTION_TYPES_SUCCESS`;
export const GET_ACTION_TYPES_ERROR = `${PREFIX}/GET_ACTION_TYPES_ERROR`;
export const RESET_ACTION_TYPES = `${PREFIX}/RESET_ACTION_TYPES`;

export const GET_OPERATIONS_REQUEST = `${PREFIX}/GET_OPERATIONS_REQUEST`;
export const GET_OPERATIONS_SUCCESS = `${PREFIX}/GET_OPERATIONS_SUCCESS`;
export const GET_OPERATIONS_ERROR = `${PREFIX}/GET_OPERATIONS_ERROR`;
export const RESET_OPERATIONS = `${PREFIX}/RESET_OPERATIONS`;

export const GET_PRODUCTION_OPERATIONS_REQUEST = `${PREFIX}/GET_PRODUCTION_OPERATIONS_REQUEST`;
export const GET_PRODUCTION_OPERATIONS_SUCCESS = `${PREFIX}/GET_PRODUCTION_OPERATIONS_SUCCESS`;
export const GET_PRODUCTION_OPERATIONS_ERROR = `${PREFIX}/GET_PRODUCTION_OPERATIONS_ERROR`;
export const RESET_PRODUCTION_OPERATIONS = `${PREFIX}/RESET_PRODUCTION_OPERATIONS`;

export const GET_DRIVERS_REQUEST = `${PREFIX}/GET_DRIVERS_REQUEST`;
export const GET_DRIVERS_SUCCESS = `${PREFIX}/GET_DRIVERS_SUCCESS`;
export const GET_DRIVERS_ERROR = `${PREFIX}/GET_DRIVERS_ERROR`;
export const RESET_DRIVERS = `${PREFIX}/RESET_DRIVERS`;

export const GET_MACHINES_REQUEST = `${PREFIX}/GET_MACHINES_REQUEST`;
export const GET_MACHINES_SUCCESS = `${PREFIX}/GET_MACHINES_SUCCESS`;
export const GET_MACHINES_ERROR = `${PREFIX}/GET_MACHINES_ERROR`;
export const RESET_MACHINES = `${PREFIX}/RESET_MACHINES`;

export const GET_EQUIPMENTS_REQUEST = `${PREFIX}/GET_EQUIPMENTS_REQUEST`;
export const GET_EQUIPMENTS_SUCCESS = `${PREFIX}/GET_EQUIPMENTS_SUCCESS`;
export const GET_EQUIPMENTS_ERROR = `${PREFIX}/GET_EQUIPMENTS_ERROR`;
export const RESET_EQUIPMENTS = `${PREFIX}/RESET_EQUIPMENTS`;

export const GET_CROPS_REQUEST = `${PREFIX}/GET_CROPS_REQUEST`;
export const GET_CROPS_SUCCESS = `${PREFIX}/GET_CROPS_SUCCESS`;
export const GET_CROPS_ERROR = `${PREFIX}/GET_CROPS_ERROR`;
export const RESET_CROPS = `${PREFIX}/RESET_CROPS`;

export const GET_DRIVE_SUBJECT_REQUEST = `${PREFIX}/GET_DRIVE_SUBJECT_REQUEST`;
export const GET_DRIVE_SUBJECT_SUCCESS = `${PREFIX}/GET_DRIVE_SUBJECT_SUCCESS`;
export const GET_DRIVE_SUBJECT_ERROR = `${PREFIX}/GET_DRIVE_SUBJECT_ERROR`;
export const RESET_DRIVE_SUBJECT = `${PREFIX}/RESET_DRIVE_SUBJECT`;

export const CALCULATE_AREA_REQUEST = `${PREFIX}/CALCULATE_AREA_REQUEST`;
export const CALCULATE_AREA_SUCCESS = `${PREFIX}/CALCULATE_AREA_SUCCESS`;
export const CALCULATE_AREA_ERROR = `${PREFIX}/CALCULATE_AREA_ERROR`;

export const EXPORT_DRIVES_REQUEST = `${PREFIX}/EXPORT_DRIVES_REQUEST`;
export const EXPORT_DRIVES_SUCCESS = `${PREFIX}/EXPORT_DRIVES_SUCCESS`;
export const EXPORT_DRIVES_ERROR = `${PREFIX}/EXPORT_DRIVES_ERROR`;

export const RESET_EXPORT_ERROR = `${PREFIX}/RESET_EXPORT_ERROR`;

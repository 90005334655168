import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import classNames from 'classnames';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';

import FileItem from './FileItem';
import { useFileUploaderStyles } from './style';

export type FileLoadItemStatus = 'new' | 'validated' | 'error';

export type FileLoadItem = {
  errorCode?: string,
  file: File,
  status: FileLoadItemStatus,
  uniqueId: string
};

type Props = {
  allFiles: FileLoadItem[];
  handleFilesValidationApi: (newFiles: FileLoadItem[]) => void;
  loading: boolean;
  setAllFiles: React.Dispatch<React.SetStateAction<FileLoadItem[]>>;
}

const FileUploader: React.FC<Props> = ({
  allFiles, handleFilesValidationApi, loading, setAllFiles }) => {
  const classes = useFileUploaderStyles();

  const { getInputProps, getRootProps } = useDropzone({
    accept: {
      'application/zip': ['.zip'],
      // 'application/xml': ['.xml'],
      // 'text/csv': ['.csv'],
    },
    onDrop: (accepted) => {
      const newFiles = [...accepted] as File[];
      const newFilesToSave: FileLoadItem[] = newFiles.map(file => ({
        file,
        uniqueId: _.uniqueId(),
        status: 'new',
      } as FileLoadItem));
      setAllFiles((prevState) => [...prevState, ...newFilesToSave]);
      handleFilesValidationApi(newFilesToSave);
    },
    disabled: loading,
  });

  const handleRemoveFile = (idToRemove?: string) => {
    setAllFiles((prevFiles) => prevFiles.filter((file) => file.uniqueId !== idToRemove));
  };

  return (
    <section className={classes.container}>
      <div className={classNames(classes.body, { [classes.bodyIsLoading]: loading })}>
        <div {...getRootProps({ className: classes.dropzone })}>
          <input {...getInputProps()} />
          <div className={classes['dropzone-title']}>
            <FormattedMessage id="FileUploader.title" />
          </div>
          {!allFiles.length && (
          <div className={classes['dropzone-formats']}>
            <FormattedMessage id="FileUploader.formats" />
          </div>
          )}
        </div>
        {allFiles.length > 0 && (
        <aside className={classes.bottomContainer}>
          <ul className={classes.loadedFiles}>
            {allFiles.map((item) => (
              <FileItem item={item} key={item.uniqueId} onRemove={handleRemoveFile} />
            ))}
          </ul>
        </aside>
        )}
      </div>
      {loading && <div className={classes.loader}>
        <CircularProgress />
        </div>}
    </section>
  );
};

export default FileUploader;

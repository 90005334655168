const PREFIX = 'PRECISION';

export const GET_AS_APPLIED_TASKDATA_REQUEST = `${PREFIX}/GET_AS_APPLIED_TASKDATA_REQUEST`;
export const GET_AS_APPLIED_TASKDATA_SUCCESS = `${PREFIX}/GET_AS_APPLIED_TASKDATA_SUCCESS`;
export const GET_AS_APPLIED_TASKDATA_ERROR = `${PREFIX}/GET_AS_APPLIED_TASKDATA_ERROR`;

export const LOAD_AS_APPLIED_TASKDATA_REQUEST = `${PREFIX}/LOAD_AS_APPLIED_TASKDATA_REQUEST`;
export const LOAD_AS_APPLIED_TASKDATA_SUCCESS = `${PREFIX}/LOAD_AS_APPLIED_TASKDATA_SUCCESS`;
export const LOAD_AS_APPLIED_TASKDATA_ERROR = `${PREFIX}/LOAD_AS_APPLIED_TASKDATA_ERROR`;

export const DELETE_AS_APPLIED_TASKDATA_REQUEST = `${PREFIX}/DELETE_AS_APPLIED_TASKDATA_REQUEST`;
export const DELETE_AS_APPLIED_TASKDATA_SUCCESS = `${PREFIX}/DELETE_AS_APPLIED_TASKDATA_SUCCESS`;
export const DELETE_AS_APPLIED_TASKDATA_ERROR = `${PREFIX}/DELETE_AS_APPLIED_TASKDATA_ERROR`;

export const AS_APPLIED_IMPORT_FILES_REQUEST = `${PREFIX}/GET_IMPORT_FILES_REQUEST`;
export const AS_APPLIED_IMPORT_FILES_SUCCESS = `${PREFIX}/GET_IMPORT_FILES_SUCCESS`;
export const AS_APPLIED_IMPORT_FILES_ERROR = `${PREFIX}/GET_IMPORT_FILES_ERROR`;

export const AS_APPLIED_VALIDATE_FILES_REQUEST = `${PREFIX}/GET_VALIDATE_FILES_REQUEST`;
export const AS_APPLIED_VALIDATE_FILES_SUCCESS = `${PREFIX}/GET_VALIDATE_FILES_SUCCESS`;
export const AS_APPLIED_VALIDATE_FILES_ERROR = `${PREFIX}/GET_VALIDATE_FILES_ERROR`;

export const AS_APPLIED_DOWNLOAD_FILE_REQUEST = `${PREFIX}/AS_APPLIED_DOWNLOAD_FILE_REQUEST`;
export const AS_APPLIED_DOWNLOAD_FILE_SUCCESS = `${PREFIX}/AS_APPLIED_DOWNLOAD_FILE_SUCCESS`;
export const AS_APPLIED_DOWNLOAD_FILE_ERROR = `${PREFIX}/AS_APPLIED_DOWNLOAD_FILE_ERROR`;
export const AS_APPLIED_SET_FILE_ERROR = `${PREFIX}/AS_APPLIED_SET_FILE_ERROR`;

import { RSAAAction } from 'redux-api-middleware';

import { getParcelsCropDetails } from '../../../../shared/api/agroevidence/parcelsCropDetails/parcelsCropDetails.selectors';

import { exportParcelsCropApi, getParcelsCropApi } from '../../../../shared/api/agroevidence/parcelsCropDetails/parcelsCropDetails.api';
import { getSeasonsSowingPlanApi } from '../../../../shared/api/agroevidence/sowingPlan/sowingPlan.api';
import FileService from '../../../../shared/services/File.service';
import { SHOWN_NUMBER_SOWING_SEASONS, START_YEAR_FIRST_SOWING_SEASONS } from '../../shared/services/Parcels.service';
import { getParcelCropDetailsListPage, getParcelCropDetailsListOrder, getParcelCropDetailsListOrderBy, getParcelCropDetailsListRowsPerPage, getParcelCropDetailsListTextFilter, getParcelCropDetailsListAdvancedFilter } from '../selectors/parcelCropDetailsList.selector';

import { ParcelsState } from '../../../../reducers/parcels.reducer.types';
import { CropTo, ZoneTo, ParcelCropDetailTo } from '../../../../shared/api/agroevidence/agroevidence.types';
import { ExportPayload } from '../../../../shared/api/api.types';

export const fetchSeasonSowingPlan = () => (
  dispatch: (action: RSAAAction) => void,
) => dispatch(getSeasonsSowingPlanApi(START_YEAR_FIRST_SOWING_SEASONS, SHOWN_NUMBER_SOWING_SEASONS));

export const fetchParcelsWithCropDetails = () => (
  dispatch: (action: RSAAAction) => void,
  getState: () => ParcelsState,
) => {
  const state = getState();
  const advancedFilter = getParcelCropDetailsListAdvancedFilter(state);
  const params = {
    size: SHOWN_NUMBER_SOWING_SEASONS,
    startYear: START_YEAR_FIRST_SOWING_SEASONS,
    page: getParcelCropDetailsListPage(state) + 1,
    'sort-dir': getParcelCropDetailsListOrder(state),
    'sort-col': getParcelCropDetailsListOrderBy(state),
    'per-page': getParcelCropDetailsListRowsPerPage(state),
    search: getParcelCropDetailsListTextFilter(state),
    crops: advancedFilter.crops
      ?.map((crop: CropTo) => crop.legislativeCode)
      ?.join(','),
    zones: advancedFilter.zones?.map((zone: ZoneTo) => zone.id)?.join(','),
  };
  return dispatch(getParcelsCropApi(params));
};

export const exportParcelsCrop = () => (
  dispatch: (action: RSAAAction) => Promise<ExportPayload>,
  getState: () => ParcelsState,
) => {
  const state = getState();
  const parcelIds = getParcelsCropDetails()(state)
    .map((parcel: ParcelCropDetailTo) => parcel.id)
    .join(',');
  dispatch(
    exportParcelsCropApi(
      START_YEAR_FIRST_SOWING_SEASONS,
      SHOWN_NUMBER_SOWING_SEASONS,
      parcelIds,
    ),
  ).then((res) => {
    FileService.processFileResponse(res);
  });
};

import moment from 'moment';

import { Tab } from '../containers/TelematicsTabs/TelematicsTabs';

import { TelematicsTabsUI } from '../telematics.types';

export const NAMESPACE = 'telematicsTabs';

type Action = |
{ payload: Tab, type: 'TELEMATICS_TABS/SET_VISITED_TAB' } |
{ dateFrom: string; dateTo: string; type: 'TELEMATICS_TABS/SET_DATE_RANGE' };

const initialDateFilter = {
  dateFrom: moment().subtract('1', 'day').startOf('day').toISOString(),
  dateTo: moment().subtract('1', 'day').endOf('day').toISOString(),
};

const initialState: TelematicsTabsUI = {
  dateFilter: initialDateFilter,
  lastVisitedTab: undefined,
};

export default () => (
  state = initialState,
  action: Action,
) => {
  switch (action.type) {
    case 'TELEMATICS_TABS/SET_VISITED_TAB': {
      return {
        ...state,
        lastVisitedTab: action.payload,
      };
    }
    case 'TELEMATICS_TABS/SET_DATE_RANGE':
      return {
        ...state,
        dateFilter: {
          dateFrom: action.dateFrom,
          dateTo: action.dateTo,
          lastUpdate: moment().toISOString(),
        },
      };
    default:
      return state;
  }
};

export { initialDateFilter };
export type { Action };

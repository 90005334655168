import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import { Card, CardContent, CardActions, Button, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import { ActionsPalette } from '../../palette/ActionsPalette';

import { ViolationTo } from '../../../../../shared/api/agroevidence/agroevidence.types';

interface IActionRestrictionCardProps {
    actionInfoType: 'SUCCESS' | 'WARNING' | 'ERROR'
    handleDialogOpen?: (violations: ViolationTo[]) => void,
    isAction?: boolean,
    violations?: ViolationTo[]
}

export const ActionRestrictionCard = ({
  actionInfoType,
  isAction,
  handleDialogOpen,
  violations = [],
}: IActionRestrictionCardProps) => {
  const classes = useStyles();

  const getCardBackgroundClass = () => {
    switch (actionInfoType) {
      case 'ERROR':
        return classes.cardError;
      case 'WARNING':
        return classes.cardWarning;
      case 'SUCCESS':
        return classes.cardSuccess;
      default:
    }
  };

  const getCardAccentClass = () => {
    switch (actionInfoType) {
      case 'ERROR':
        return classes.cardErrorAccent;
      case 'WARNING':
        return classes.cardWarningAccent;
      case 'SUCCESS':
        return classes.cardSuccesAccent;
      default:
    }
  };

  const cardBackgroundClass = getCardBackgroundClass();
  const cardAccentClass = getCardAccentClass();

  return (
    <>
      {violations.map((violation) => (
        <Card
          className={`${classes.card} ${cardBackgroundClass}`}
          key={violation.code}
        >
          <CardContent className={classes.content}>
            <div className={classes.header}>
              {actionInfoType !== 'SUCCESS' ? (
                <WarningIcon className={cardAccentClass} />
              ) : (
                <CheckCircleIcon className={cardAccentClass} />
              )}
            </div>
            <Box display="inline" fontWeight="400">
              <FormattedMessage id="ActionRestrictionsInfo.title" />
            </Box>
          </CardContent>
          {isAction && handleDialogOpen && (
            <CardActions className={classes.actions}>
              <Button
                className={cardAccentClass}
                onClick={() => handleDialogOpen(violations)}
                size="small"
              >
                <FormattedMessage id="common.show" />
              </Button>
            </CardActions>
          )}
        </Card>
      ))}
    </>
  );
};

const useStyles = makeStyles(() => ({
  cardError: {
    backgroundColor: ActionsPalette.error.light,
  },
  cardErrorAccent: {
    color: ActionsPalette.error.main,
  },
  cardWarning: {
    backgroundColor: ActionsPalette.warning.light,
  },
  cardWarningAccent: {
    color: ActionsPalette.warning.main,
  },
  cardSuccess: {
    backgroundColor: ActionsPalette.success.light,
  },
  cardSuccesAccent: {
    color: ActionsPalette.success.main,
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
  },
  content: {
    flex: '1',
    display: 'flex',
    gap: 8,
  },
  actions: {
    padding: '16px',
  },
  header: {
    display: 'inline-flex',
    color: ActionsPalette.neutral.main,
  },
}));

/* eslint-disable react/no-danger */
import React, { FC } from 'react';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import CfDialog from '../../../../../shared/components/common/CfDialog/CfDialog';

import { ViolationSeverity, ViolationTo } from '../../../../../shared/api/agroevidence/agroevidence.types';

const useStyles = makeStyles((theme: Theme) => ({
  errorWrapper: {
    marginBottom: 20,
    lineHeight: 1.2,
  },
  errorMessage: {
    color: theme.palette.error.main,
    marginBottom: 10,
  },
  warningMessage: {
    color: theme.palette.warning.light,
    marginBottom: 10,
  },
  errorText: {
    fontSize: 14,
  },
}));

interface Props {
  onBack: () => void,
  opened?: boolean,
  violationContent?: ViolationTo[]
}

export const ActionRestrictionsDetailsDialog: FC<Props> = ({
  opened = false,
  violationContent = [],
  onBack,
}) => {
  const classes = useStyles();

  return (
    <CfDialog
      acceptText={<FormattedMessage id="common.close" />}
      onAccept={onBack}
      opened={opened}
      title={<FormattedMessage id="ActionRestrictionDetailsDialog.problems" />}
    >
      <div>
        {violationContent.map((violation, index) => (

          <div
            className={classes.errorWrapper}
            data-test="restriction-item"
            key={violation.code}
          >
            <div
              className={
                violation.severity === ViolationSeverity.ERROR
                  ? classes.errorMessage
                  : classes.warningMessage
              }
            >
              {index + 1}) {violation.message}
            </div>
            <div
              className={classes.errorText}
              dangerouslySetInnerHTML={{ __html: violation.text }}
            />
          </div>
        ))}
      </div>
    </CfDialog>
  );
};

export default ActionRestrictionsDetailsDialog;

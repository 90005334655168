import React, { FC, useEffect, createContext } from 'react';

import { LinearProgress, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { getTelematicsStatus } from '../../../shared/api/telematics/sectionStatus/sectionStatus.actions';
import { fetchOperations } from '../../actions/telematicsList.actions';

import * as services from '../../../shared/constants/services.constants';
import { LANGUAGE_ID } from '../../../shared/lang/lang.constants';
import { TELEMATICS_URLS } from '../../telematics.constants';

import { getCatalogueType, getEconomicSystem, getEconomicSystemDate, isTelematicSectionAllowed, isTelematicSectionVerified } from '../../../shared/api/telematics/sectionStatus/sectionStatus.selector';
import CfStatusPanel from '../../../shared/components/common/CfStatusPanel/CfStatusPanel';
import PageHeader from '../../../shared/components/common/PageHeader/PageHeader';
import PageHeading from '../../../shared/components/common/PageHeading/PageHeading';
import ServiceNotPurchased from '../../../shared/components/common/ServiceNotPurchased/ServiceNotPurchased';
import { links } from '../../../shared/constants/links';
import TelematicsIcon from '../../../shared/icons/navbar/TelematicsIcon';
import { Thunk } from '../../../types';
import TelematicsAggregationDetail from '../TelematicsAggregationDetail/TelematicsAggregationDetail';
import TelematicsAggregationNew from '../TelematicsAggregationDetail/TelematicsAggregationNew';
import TelematicsAggregationsList from '../TelematicsAggregationsList/TelematicsAggregationsList';
import TelematicsDetail from '../TelematicsDetail/TelematicsDetail';
import TelematicsHandworkNew from '../TelematicsHandwork/TelematicsHandworkNew';
import TelematicsList from '../TelematicsList/TelematicsList';
import TelematicsMachines from '../TelematicsMachines/TelematicsMachines';
import TelematicsTabs from '../TelematicsTabs/TelematicsTabs';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';
import { Catalogue, DriverIdentityTo, EconomicSystem, MachineTo } from '../../../shared/api/telematics/telematics.types';

interface Context {
  catalogueType: Catalogue | undefined;
  economicSystem: EconomicSystem | undefined;
  economicSystemDate: string
  farmId: string;
  langId: string;
}

export const TelematicsContext = createContext<Context>({
  farmId: '',
  langId: '',
  economicSystemDate: '',
  economicSystem: undefined,
  catalogueType: undefined,
});

const useStyles = makeStyles((theme: Theme) => ({
  noTelematics: {
    padding: theme.spacing(2),
  },
  header: {
    paddingBottom: theme.spacing(2),
  },
}));

export interface TelematicsNgProps {
  ngRedirectToMainMapWithFilters: (
    dateFrom?: string,
    dateTo?: string,
    machine?: MachineTo[],
    driver?: Pick<DriverIdentityTo, 'name'| 'code'>[],
    openInNewPage?: boolean
  ) => void
}

interface Props extends TelematicsNgProps{
  catalogueType: Catalogue;
  economicSystem: EconomicSystem;
  economicSystemDate: string;
  farmId: string;
  fetchOperations(): void;
  getTelematicsStatus(): void,
  initDateFrom: string;
  initDateTo: string;
  initMachineFilter?: MachineTo,
  isSectionAllowed: boolean,
  isVerified: boolean;
  langId: LANGUAGE_ID;
  ngGoToAction: (actionId?: string) => void
}

const Telematics: FC<Props> = ({
  catalogueType,
  economicSystem,
  economicSystemDate,
  farmId,
  fetchOperations,
  getTelematicsStatus,
  initDateFrom,
  initDateTo,
  initMachineFilter,
  isSectionAllowed,
  isVerified,
  langId,
  ngGoToAction,
  ngRedirectToMainMapWithFilters,
}) => {
  const classes = useStyles();

  useEffect(() => {
    getTelematicsStatus();
  }, [getTelematicsStatus]);

  useEffect(() => {
    if (!isSectionAllowed || !isVerified) return;
    fetchOperations();
  }, [isSectionAllowed, fetchOperations, langId, isVerified]);

  if (!isVerified) {
    return <LinearProgress color="primary" style={{ marginTop: 8 }} />;
  }

  if (isSectionAllowed) {
    return (
      <TelematicsContext.Provider value={{
        langId,
        farmId,
        catalogueType,
        economicSystem,
        economicSystemDate,
      }}>
        <Switch>
          <Route
            exact
            path={`/farm/:farmId/${TELEMATICS_URLS.machines}`}
            render={(routerProps) =>
              <TelematicsTabs langId={langId} {...routerProps}>
                <TelematicsMachines ngRedirectToMainMapWithFilters={ngRedirectToMainMapWithFilters} />
              </TelematicsTabs>}
          />
          <Route
            exact
            path={`/farm/:farmId/${TELEMATICS_URLS.drivers}`}
            render={(routerProps) =>
              <TelematicsTabs langId={langId} {...routerProps}>
                <TelematicsAggregationsList ngRedirectToMainMapWithFilters={ngRedirectToMainMapWithFilters} />
              </TelematicsTabs>}
          />
          <Route
            exact
            path={`/farm/:farmId/${TELEMATICS_URLS.logbook}`}
            render={(routerProps) =>
              <TelematicsTabs langId={langId} {...routerProps}>
                <TelematicsList
                  farmId={farmId}
                  initDateFrom={initDateFrom}
                  initDateTo={initDateTo}
                  initMachineFilter={initMachineFilter}
                  langId={langId}
                  ngRedirectToMainMapWithFilters={ngRedirectToMainMapWithFilters}
                />
              </TelematicsTabs>
          }
          />
          {/* TODO: CFD-1265 re-enable after aggregation is done */}
          <Redirect
            from={`/farm/:farmId/${TELEMATICS_URLS.logbook}/new`}
            to={`/farm/:farmId/${TELEMATICS_URLS.logbook}`}
          />
          <Route
            exact
            path={`/farm/:farmId/${TELEMATICS_URLS.logbook}/:telematicsId`}
            render={() => <TelematicsDetail farmId={farmId} langId={langId} />}
          />
          <Route
            exact
            path={`/farm/:farmId/${TELEMATICS_URLS.drivers}/new`}
            render={() => <TelematicsAggregationNew />}
          />
          <Route
            exact
            path={`/farm/:farmId/${TELEMATICS_URLS.handwork}/new`}
            render={() => <TelematicsHandworkNew />}
          />
          <Route
            exact
            path={`/farm/:farmId/${TELEMATICS_URLS.drivers}/:driveId`}
            render={() => <TelematicsAggregationDetail catalogueType={catalogueType} ngGoToAction={ngGoToAction} />}
          />
          <Route
            exact
            path={`/farm/:farmId/${TELEMATICS_URLS.machines}/:driveId`}
            render={() => <TelematicsAggregationDetail catalogueType={catalogueType} ngGoToAction={ngGoToAction} />}
          />
        </Switch>
      </TelematicsContext.Provider>
    );
  }
  return (
    <div className={classes.noTelematics}>
      <PageHeader
        classes={{ header: classes.header }}
        heading={<PageHeading translationId="common.telematics" />}
      />
      <CfStatusPanel
        customContent={<ServiceNotPurchased serviceId={services.TELEMATICS} />}
        icon={TelematicsIcon}
        linkHref={langId === 'cs-CZ' ? links.cs.telematics : links.en.telematics}
        linkText={<FormattedMessage id="common.findOutMore" />}
        testId="no-telematics"
        title={<FormattedMessage id="Telematics.notPurchased.title" />}
        titleWithIcon={true}
      />
    </div>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  isSectionAllowed: isTelematicSectionAllowed(state),
  isVerified: isTelematicSectionVerified(state),
  catalogueType: getCatalogueType(state),
  economicSystem: getEconomicSystem(state),
  economicSystemDate: getEconomicSystemDate(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      fetchOperations,
      getTelematicsStatus,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Telematics);

import React, { FC, useContext, useEffect } from 'react';

import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { ConnectedProps, connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getFileImportError } from '../../../shared/api/sentinel/precision/precisionAsApplied.selectors';

import { resetFileImportError } from '../../../shared/api/sentinel/asAppliedTaskdata/asAppliedTaskdata.actions';
import { fetchPrecisionAsApplied } from '../actions/precision.actions';

import { ImportFilesApiType, ValidateFileApiType } from '../../../shared/api/sentinel/asAppliedTaskdata/asAppliedTaskdata.api';
import CfDialog from '../../../shared/components/common/CfDialog/CfDialog';
import useFileImport from '../../../shared/components/fileImport/useFileImport';
import { SnackbarContext } from '../../../shared/containers/SnackbarProvider/SnackbarProvider';
import FileUploader from '../../../telematics/components/FileUploader/FileUploader';
import { Thunk } from '../../../types';

import { PrecisionState } from '../../../reducers/precision.reducer.types';

const useStyles = makeStyles(() => ({
  dialogContainer: {
    padding: '16px 24px 24px 24px',
  },
  dialogContent: {
    padding: 0,
  },
  dialogActions: {
    padding: 0,
    paddingTop: 48,
  },
  title: {
    padding: 0,
    marginBottom: 24,
  },
  closeIcon: {
    top: 8,
    right: 8,
  },
}));

interface Props {
  handleClose: () => void;
  importFilesApi: (params: ImportFilesApiType) => void;
  onSuccess?: () => void;
  open: boolean;
  validateFileApi: (params: ValidateFileApiType) => void;
}

const ImportMachinesDialog: FC<Props & ReduxProps> = ({
  error,
  fetchPrecisionAsApplied,
  handleClose,
  importFilesApi,
  onSuccess,
  open,
  resetFileImportError,
  validateFileApi,
}) => {
  const classes = useStyles();
  const showSnackbar = useContext(SnackbarContext);
  const {
    allFiles, containsError, handleFilesImportApi, handleFilesValidationApi, loading, setAllFiles, success,
  } = useFileImport({ importFilesApi, validateFileApi });

  const isDisabled = (loading || allFiles.length === 0 || containsError()) && !success;

  useEffect(() => () => {
    resetFileImportError();
  }, [resetFileImportError]);

  useEffect(() => {
    if (error) {
      showSnackbar({ message: <FormattedMessage id="FileUploader.modal.validation.error" />, isError: true });
    }
  }, [error, showSnackbar]);

  useEffect(() => {
    if (success) {
      if (onSuccess) {
        onSuccess();
      } else {
        fetchPrecisionAsApplied();
      }
      handleClose();
    }
  }, [fetchPrecisionAsApplied, handleClose, onSuccess, success]);

  return (<CfDialog
    acceptButtonVariant="contained"
    acceptText={<FormattedMessage id="FileUploader.submit" />}
    disabled={isDisabled}
    externalStyle={classes}
    maxWidth="xs"
    onAccept={handleFilesImportApi}
    onCloseIcon={handleClose}
    opened={open}
    processing={loading}
    title={<FormattedMessage id="FileUploader.modal.title" />}
    >
    <FileUploader
      allFiles={allFiles}
      handleFilesValidationApi={handleFilesValidationApi}
      loading={loading}
      setAllFiles={setAllFiles}
      />
  </CfDialog>);
};

const mapStateToProps = (state: PrecisionState) => ({
  error: getFileImportError(state),
});
const mapDispatchToProps = (dispatch: Thunk<PrecisionState>) =>
  bindActionCreators(
    {
      fetchPrecisionAsApplied,
      resetFileImportError,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;
export default connector(ImportMachinesDialog);

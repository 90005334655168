import { intersection } from 'lodash';
import { createSelector } from 'reselect';

import { getParcelsCropDetails } from '../../../../shared/api/agroevidence/parcelsCropDetails/parcelsCropDetails.selectors';

import { ParcelsState } from '../../../../reducers/parcels.reducer.types';
import { ParcelCropDetailTo } from '../../../../shared/api/agroevidence/agroevidence.types';

export const getParcelCropDetailsListPage = (state: ParcelsState) =>
  state.ui.parcelCropDetailsList.page;
export const getParcelCropDetailsListOrder = (state: ParcelsState) =>
  state.ui.parcelCropDetailsList.order;
export const getParcelCropDetailsListOrderBy = (state: ParcelsState) =>
  state.ui.parcelCropDetailsList.orderBy;
export const getParcelCropDetailsListRowsPerPage = (state: ParcelsState) =>
  state.ui.parcelCropDetailsList.rowsPerPage;
export const getParcelCropDetailsListSelected = (state: ParcelsState) =>
  state.ui.parcelCropDetailsList.selected;
export const getParcelCropDetailsListTextFilter = (state: ParcelsState) =>
  state.ui.parcelCropDetailsList.textFilter;
export const getParcelCropDetailsListAdvancedFilter = (state: ParcelsState) =>
  state.ui.parcelCropDetailsList.advancedFilter;

export const getParcelListCropDetailsSelectedOnPage = createSelector(
  getParcelsCropDetails(),
  getParcelCropDetailsListSelected,
  (parcels, selected) =>
    intersection(
      parcels.map((parcel: ParcelCropDetailTo) => parcel.id),
      selected,
    ),
);

import React from 'react';

import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose } from 'react-recompose';

import CfLabel from '../../../../../../shared/components/common/CfLabel/CfLabel';
import EditableText from '../../../../../../shared/components/common/EditableText/EditableText';
import PageHeading from '../../../../../../shared/components/common/PageHeading/PageHeading';
import { ParcelsService } from '../../../../shared/services/Parcels.service';

const styles = theme => ({
  headingLabel: {
    padding: '3px 6px',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: 14,
    marginTop: 6,
  },
  heading: {
    display: 'flex',
    flexDirection: 'column',
  },
  blockNumber: {
    color: theme.palette.grey[500],
    fontSize: 18,
  },
  parcelName: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 4,
  },
});

export function ParcelDetailHeading(props) {
  const { classes, confirmEditing, isFetchingParcel, parcel, selectedParcel } = props;
  const isHistorical = ParcelsService.isParcelHistorical(parcel);
  return (
    <PageHeading
      dataTest="parcelDetail-heading"
      value={
        <div className={classes.heading}>
          <div className={classes.parcelName}>
            <EditableText
              initialValue={isFetchingParcel && selectedParcel ? selectedParcel.localName : parcel.localName}
              labelTestId="parcel-name"
              onConfirmEditing={newValue => confirmEditing(newValue)}
            />
          </div>
          <div className={classes.blockNumber}>
            {isFetchingParcel && selectedParcel ? selectedParcel.blockNumber : parcel.blockNumber}
          </div>
          {isHistorical && (
            <CfLabel
              classes={{ label: classes.headingLabel }}
              message={<FormattedMessage id={'ParcelDetail.historicalParcel'} />}
            />
          )}
        </div>
      }
    />
  );
}

ParcelDetailHeading.propTypes = {
  classes: PropTypes.object.isRequired,
  parcel: PropTypes.object,
  selectedParcel: PropTypes.object,
  isFetchingParcel: PropTypes.bool.isRequired,
  confirmEditing: PropTypes.func.isRequired,
};

ParcelDetailHeading.defaultProps = {
  parcel: null,
  selectedParcel: null,
};

export default compose(withStyles(styles))(ParcelDetailHeading);

import React, { useState } from 'react';

import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import CfFilter from '../../../../shared/containers/CfFilter/CfFilter';
import CropsSelector from '../../list/containers/ParcelAdvancedFilter/CropsSelector/CropsSelector';
import ZoneSelector from '../../list/containers/ParcelAdvancedFilter/ZoneSelector/ZoneSelector';
import { getParcelCropDetailsListAdvancedFilter } from '../selectors/parcelCropDetailsList.selector';

import { ParcelsState } from '../../../../reducers/parcels.reducer.types';
import { CropTo, ZoneTo } from '../../../../shared/api/agroevidence/agroevidence.types';
import { ParcelCropDetailsListAdvancedFilter } from '../../../../shared/api/agroevidence/parcelsCropDetails/parcelsCropDetails.types';

const useStyles = makeStyles({
  container: {
    padding: 20,
    minWidth: 300,
    maxWidth: 600,
  },
  select: {
    marginBottom: 10,
  },
});

interface IParcelCropsAdvancedFilterProps {
  advancedFilter: ParcelCropDetailsListAdvancedFilter;
  langId: string;
  namespace: string;
}

const ParcelCropsAdvancedFilter = ({
  advancedFilter,
  langId,
  namespace,
}: IParcelCropsAdvancedFilterProps) => {
  const classes = useStyles();

  const [advancedFilterState, setAdvancedFilterState] = useState<
    ParcelCropDetailsListAdvancedFilter
  >(advancedFilter);

  const handleChangeZone = (items: ZoneTo[]) => {
    setAdvancedFilterState({ ...advancedFilterState, zones: items });
  };
  const handleChangeCrop = (items: CropTo[]) => {
    setAdvancedFilterState({ ...advancedFilterState, crops: items });
  };

  return (
    <CfFilter
      filterState={advancedFilterState}
      langId={langId}
      namespace={namespace}
      setAdvancedFilterState={setAdvancedFilterState}
    >
      <div className={classes.container}>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <ZoneSelector
              defaultZones={advancedFilter.zones}
              label={<FormattedMessage id="common.zone" />}
              onChange={handleChangeZone}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className={classes.select} item xs={12}>
            <CropsSelector
              defaultCrops={advancedFilter.crops}
              label={<FormattedMessage id="common.currentCrop" />}
              onChange={handleChangeCrop}
            />
          </Grid>
        </Grid>
      </div>
    </CfFilter>
  );
};

const mapStateToProps = (state: ParcelsState) => ({
  advancedFilter: getParcelCropDetailsListAdvancedFilter(state),
});

export default connect(mapStateToProps)(ParcelCropsAdvancedFilter);

import React, { FC, Suspense, useCallback, useEffect, useState } from 'react';

import { Theme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { connect } from 'react-redux';
import { Route, Switch, match as matchType, useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { getIsFetchingPrecisionParcel, getPrecisionParcel } from '../../../shared/api/sentinel/precision/precision.selectors';

import { resetCropLegislativeCode } from '../actions/management.actions';
import { resetMonitoringInterval } from '../actions/monitoring.actions';

import { PRECISION_URLS } from '../precision.constants';

import { ImportFilesApiType, ValidateFileApiType, importFilesApi, validateFileApi } from '../../../shared/api/sentinel/asAppliedTaskdata/asAppliedTaskdata.api';
import { resetMonitoringData, resetCropIntervals } from '../../../shared/api/sentinel/monitoring/monitoring.api';
import { getPrecisionParcelApi, resetPrecisionParcelApi } from '../../../shared/api/sentinel/precision/precision.api';
import { getSatelliteProductsApi, resetSatelliteProductsApi } from '../../../shared/api/sentinel/satelliteProducts/satelliteProducts.api';
import CfBackButton from '../../../shared/components/common/CfBackButton/CfBackButton';
import CfLoader from '../../../shared/components/common/CfLoader/CfLoader';
import DroplistAdd from '../../../shared/components/common/DroplistAdd/DroplistAdd';
import PageHeader from '../../../shared/components/common/PageHeader/PageHeader';
import { useDialog } from '../../../shared/hooks/useDialog';
import useWidth from '../../../shared/hooks/useWidth';
import { Thunk } from '../../../types';
import ParcelDetailTabs, { Tabs } from '../../parcels/detail/shared/components/ParcelDetailTabs/ParcelDetailTabs';
import PrecisionDetailHeading from '../components/PrecisionDetailHeading';

import BioMonitoring from './BioMonitoring';
import ImportMachinesDialog from './ImportMachinesDialog';
import ManagementZones from './ManagementZones';
import SoilSamples from './SoilSamples';

import { PrecisionState } from '../../../reducers/precision.reducer.types';
import { PrecisionParcel } from '../../../shared/api/sentinel/precision/precision.types';

export const PRECISION_TABS: Tabs = {
  management: {
    id: 'management',
    code: 'historyPotential',
    section: 'PrecisionDetailTabs',
    path: 'management-zones',
  },
  monitoring: {
    id: 'monitoring',
    code: 'biomonitoring',
    section: 'PrecisionDetailTabs',
    path: 'bio-monitoring',
  },
  soilSamples: {
    id: 'soilSamples',
    code: 'soilSamples',
    section: 'PrecisionDetailTabs',
    path: 'soil-samples',
  },
  yieldMaps: {
    id: 'yieldMaps',
    code: 'yieldMaps',
    section: 'PrecisionDetailTabs',
    path: 'yield-Maps',
  },
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    height: '100%',
  },
  headerWithTabs: {
    backgroundColor: theme.palette.common.white,
    borderBottom: '1px solid #EFEFEF',
  },
  headerWrapper: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  body: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentWrapper: {
    height: '100%',
  },
  content: {
    padding: theme.spacing(2),
    flexGrow: 1,
    width: '100%',
  },
}));

interface PrecisionDetailProps {
  getPrecisionParcelApi: (parcelId: string) => void,
  getSatelliteProductsApi: (parcelId: string) => void,
  importFilesApi: (params: ImportFilesApiType) => void,
  item: PrecisionParcel,
  langId: string,
  match: matchType<{farmId: string, parcelId: string}>,
  ngRedirectToSowing: (parcelId: string[]) => void,
  ngRedirectToVrf: (parcelId: string[]) => void,
  ngRedirectToVrs: (parcelId: string[]) => void,
  resetCropIntervals: () => void,
  resetCropLegislativeCode: () => void,
  resetMonitoringData: () => void,
  resetMonitoringInterval: () => void,
  resetPrecisionParcelApi: () => void,
  resetSatelliteProductsApi: () => void,
  validateFileApi: (params: ValidateFileApiType) => void,
}

const PrecisionDetail: FC<PrecisionDetailProps> = ({
  getPrecisionParcelApi,
  getSatelliteProductsApi,
  importFilesApi,
  item,
  langId,
  match,
  ngRedirectToSowing,
  ngRedirectToVrf,
  ngRedirectToVrs,
  resetCropIntervals,
  resetCropLegislativeCode,
  resetMonitoringData,
  resetMonitoringInterval,
  resetPrecisionParcelApi,
  resetSatelliteProductsApi,
  validateFileApi,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const width = useWidth();

  const [activeTab, setActiveTab] = useState<string>(PRECISION_TABS.management.id);

  const { farmId, parcelId } = match.params;
  const { pathname } = history.location;

  const {
    handleClose: handleMachinesImportClose, handleOpen: handleMachinesImportOpen, isOpen: machinesImportIsOpen,
  } = useDialog();

  useEffect(() => {
    getPrecisionParcelApi(parcelId);
    getSatelliteProductsApi(parcelId);
    setTabByPathname(pathname);

    return () => {
      resetPrecisionParcelApi();
      resetSatelliteProductsApi();
      resetCropIntervals();
      resetMonitoringInterval();
      resetMonitoringData();
      resetCropLegislativeCode();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setTabByPathname = (pathname: string) => {
    const tabs = Object.keys(PRECISION_TABS);
    tabs.forEach(tab => {
      if (pathname.toLowerCase().includes(PRECISION_TABS[tab].path)) {
        setActiveTab(PRECISION_TABS[tab].id);
      }
    });
  };

  const handleGoBack = useCallback(() => {
    history.push(`/farm/${farmId}/${PRECISION_URLS.main}`);
  }, [history, farmId]);

  return (
    <Grid className={classes.wrapper} container>
      <Scrollbars>
        <div className={classes.body}>
          <div className={classes.headerWithTabs}>
            <div className={classes.headerWrapper}>
              <PageHeader
                actionButtons={
                  <DroplistAdd
                    options={[
                      {
                        dataTest: 'add-var-vert-link',
                        intlId: 'common.addVrfActivity',
                        onClick: () => ngRedirectToVrf([parcelId]),
                      },
                      {
                        dataTest: 'add-var-seed-link',
                        intlId: 'common.addVrsActivity',
                        onClick: () => ngRedirectToVrs([parcelId]),
                        divider: true,
                      },
                      {
                        intlId: 'PrecisionFarming.add.importRides',
                        dataTest: 'precision-import-rides-xlsx',
                        onClick: handleMachinesImportOpen,
                      },
                    ]}
                />
                }
                backButton={
                  <CfBackButton
                    onClick={handleGoBack}
                    translId={'PrecisionDetail.backToPrecision'}
                  />
                }
                heading={
                  item ? (
                    <PrecisionDetailHeading
                      farmId={farmId}
                      parcel={item}
                  />
                  ) : null}
              />
            </div>
            {item ? (
              <ParcelDetailTabs
                activateTab={(tabId: string) => setActiveTab(tabId)}
                activeTab={activeTab}
                farmId={farmId}
                parcelId={item.id}
                precisionParcel={item}
                precisionSection
                tabs={PRECISION_TABS}
                width={width}
            />
            ) : null}
          </div>
          <Grid className={classes.contentWrapper} container>
            <Grid className={classes.content} item>
              <Suspense fallback={<CfLoader />}>
                <Switch>
                  <Route
                    exact
                    path={`/farm/:farmId/${PRECISION_URLS.servicesDetail()}/${PRECISION_TABS.management.path}`}
                    render={() => (
                      <ManagementZones langId={langId} parcelId={parcelId} />
                    )}
                  />
                  <Route
                    exact
                    path={`/farm/:farmId/${PRECISION_URLS.servicesDetail()}/${PRECISION_TABS.monitoring.path}`}
                    render={() => (
                      <BioMonitoring
                        langId={langId}
                        ngRedirectToSowing={ngRedirectToSowing}
                        parcelId={parcelId}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`/farm/:farmId/${PRECISION_URLS.servicesDetail()}/${PRECISION_TABS.soilSamples.path}`}
                    render={() => (
                      <SoilSamples parcelId={parcelId} />
                    )}
                  />
                  <Route
                    exact
                    path={`/farm/:farmId/${PRECISION_URLS.servicesDetail()}/${PRECISION_TABS.yieldMaps.path}`}
                    render={() => (
                      <div>Yield Maps</div>
                    )}
                  />
                </Switch>
              </Suspense>
            </Grid>
          </Grid>
        </div>
      </Scrollbars>
      <ImportMachinesDialog
        handleClose={handleMachinesImportClose}
        importFilesApi={importFilesApi}
        onSuccess={() => history.push(`/farm/${farmId}/${PRECISION_URLS.asApplied()}`)}
        open={machinesImportIsOpen}
        validateFileApi={validateFileApi}
      />
    </Grid>
  );
};

const mapStateToProps = (state: PrecisionState) => ({
  item: getPrecisionParcel(state),
  isFetchingItem: getIsFetchingPrecisionParcel(state),
});

const mapDispatchToProps = (dispatch: Thunk<PrecisionState>) =>
  bindActionCreators(
    {
      getPrecisionParcelApi,
      getSatelliteProductsApi,
      resetPrecisionParcelApi,
      resetSatelliteProductsApi,
      resetCropIntervals,
      resetMonitoringData,
      resetMonitoringInterval,
      resetCropLegislativeCode,
      importFilesApi,
      validateFileApi,
    }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PrecisionDetail);

import { intersection } from 'lodash';
import { createSelector } from 'reselect';

import { getPrecisionAsAppliedOrder, getPrecisionAsAppliedOrderBy, getPrecisionAsAppliedPage, getPrecisionAsAppliedRowsPerPage, getPrecisionAsAppliedSelected, getPrecisionAsAppliedTextFilter } from '../../../../core/precision/selectors/precisionAsApplied.selectors';

import { paginationArray, sortArray } from '../../../misc/arraysAction.helpers';

import { PrecisionState } from '../../../../reducers/precision.reducer.types';

export const getPrecisionTaskdata = (state: PrecisionState) => state.api.precisionAsApplied.taskdata;
export const getPrecisionTaskdataCount = (state: PrecisionState) => state.api.precisionAsApplied.count;
export const getPrecisionAsAppliedIsFetching = (state: PrecisionState) => state.api.precisionAsApplied.isFetching;
export const getFileImportError = (state: PrecisionState) => state.api.precisionAsApplied.fileImportError;

export const getPrecisionAsAppliedFiltered = createSelector(
  getPrecisionTaskdata,
  getPrecisionAsAppliedTextFilter,
  (data, textFilter) => {
    if (textFilter.length === 0) return data;
    // const textFilterLowerCase = textFilter.toLowerCase();

    const dataFiltered = data;
    // const dataFiltered = parcels.filter(parcel =>
    //   parcel.localName.toLowerCase().includes(textFilterLowerCase) ||
    //   parcel.blockNr.toLowerCase().includes(textFilterLowerCase),
    // );
    return dataFiltered;
  });

export const getPrecisionParcelsSortedPaginated = createSelector(
  getPrecisionAsAppliedFiltered,
  getPrecisionAsAppliedOrder,
  getPrecisionAsAppliedOrderBy,
  getPrecisionAsAppliedPage,
  getPrecisionAsAppliedRowsPerPage,
  (dataFiltered, order, orderBy, page, rowsPerPage) => {
    const dataSorted = sortArray(order, orderBy, dataFiltered);
    return paginationArray(page, rowsPerPage, dataSorted);
  });

export const getPrecisionAsAppliedSelectedOnPage = createSelector(
  getPrecisionTaskdata,
  getPrecisionAsAppliedSelected,
  (data, selected) => intersection(data.map((row) => row.id), selected),
);

import React, { FC, useCallback, useEffect } from 'react';

import TableBody from '@mui/material/TableBody';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { isFetchingMachineAggregations, getMachineAggregations, getMachineAggregationsCount } from '../../../shared/api/telematics/machines/machines.selectors';
import {
  getTelematicsMachinesOrder,
  getTelematicsMachinesOrderBy,
  getTelematicsMachinesPage,
  getTelematicsMachinesRowsPerPage,
  getTelematicsMachinesTextFilter,
} from '../../selectors/telematicsMachines.selectors';
import { selectDateRange } from '../../selectors/telematicsTabs.selectors';

import { setAdvancedFilter } from '../../../shared/actions/filter.actions';
import { fetchAggregatedMachinesSaga } from '../../actions/telematicsMachines.actions';
import { setDateRange } from '../../actions/telematicsTabs.actions';

import { NAMESPACE as telListNamespace } from '../../reducer/telematicsList.reducer';
import { NAMESPACE } from '../../reducer/telematicsMachines.reducer';

import CfTableBodyEmpty from '../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty';
import CfTableBodyLoader from '../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader';
import CfTableWrapper from '../../../shared/components/tables/CfTableWrapper/CfTableWrapper';
import CfTableFooter from '../../../shared/containers/CfTableFooter/CfTableFooter';
import CfTableHead from '../../../shared/containers/CfTableHead/CfTableHead';
import { Thunk } from '../../../types';
import { TelematicsNgProps } from '../../containers/Telematics/Telematics';
import { getColDesc } from '../../helpers';
import DoubleLinedHeader from '../DoubleLinedHeader/DoubleLinedHeader';

import MachineRow from './MachineRow';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';
import { LogbookAggregatedMachineTo, MachineCategoryCode, MachineGroupCode } from '../../../shared/api/telematics/telematics.types';
import { TelematicsAdvancedFilter } from '../../telematics.types';

interface Props extends TelematicsNgProps {
    count: number;
    dateFilter: {
      dateFrom: string;
      dateTo: string;
    },
    getAggregations: () => void,
    isFetching: boolean;
    machineAggregations?: LogbookAggregatedMachineTo[],
    order: string;
    orderBy: string;
    page: number;
    rowsPerPage: number;
    setTelListAdvancedFilter: (values: TelematicsAdvancedFilter, namespace: string) => void;
    setTelListDate: (dateFrom: string, dateTo: string) => void;
    textFilter: string;
}

const columns = {
  duration: getColDesc(
    true,
    <DoubleLinedHeader
      leftOffset
      primaryId="TelematicsMachines.list.date"
      secondaryId="TelematicsMachines.list.duration"
    />,
  ),
  'machine.name': getColDesc(
    true,
    <FormattedMessage id="TelematicsAggregations.list.machine" />,
  ),
  'machine.operation': getColDesc(
    false,
    <FormattedMessage id="TelematicsMachines.list.cultivated" />,
    { width: 100 },
  ),
  distance: getColDesc(
    false,
    <FormattedMessage id="TelematicsMachines.list.distance" />,
    { width: 100 },
  ),
  time: getColDesc(false, <FormattedMessage id="TelematicsMachines.list.time" />),
};

const TelematicsMachinesTable: FC<Props> = ({
  count,
  dateFilter,
  getAggregations,
  isFetching,
  machineAggregations,
  ngRedirectToMainMapWithFilters,
  order,
  orderBy,
  page,
  rowsPerPage,
  setTelListAdvancedFilter,
  setTelListDate,
  textFilter,
}) => {
  useEffect(() => {
    getAggregations();
  }, [order, orderBy, page, rowsPerPage, dateFilter, textFilter, getAggregations]);

  const logbookFilterHandler = useCallback((dateFrom: string, dateTo: string, machine: Record<'code'|'name'|'gpsUnit', string>) => {
    setTelListDate(dateFrom, dateTo);
    setTelListAdvancedFilter({ machine: [{
      machineCode: machine.code,
      name: machine.name,
      validFrom: '',
      gpsUnit: machine.gpsUnit,
      category: MachineCategoryCode.AJE,
      group: MachineGroupCode.TRUCK,
    }] }, telListNamespace);
  }, [setTelListAdvancedFilter, setTelListDate]);

  return (
    <CfTableWrapper>
      <CfTableHead
        columns={columns}
        namespace={NAMESPACE}
        order={order}
        orderBy={orderBy}
      />
      {isFetching && <CfTableBodyLoader columns={columns} />}
      {!isFetching && machineAggregations && (
      <TableBody>
        {machineAggregations.map((m, i) =>
          <MachineRow
            data={m}
            dateFilter={dateFilter}
            key={i}
            logbookFilterHandler={logbookFilterHandler}
            ngRedirectToMainMapWithFilters={ngRedirectToMainMapWithFilters}
            rowId={i}
            />,
        )}
      </TableBody>
      )}
      {!isFetching && !machineAggregations?.length &&
      <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
      }
      <CfTableFooter
        count={count}
        namespace={NAMESPACE}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </CfTableWrapper>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  order: getTelematicsMachinesOrder(state),
  orderBy: getTelematicsMachinesOrderBy(state),
  isFetching: isFetchingMachineAggregations(state),
  machineAggregations: getMachineAggregations(state),
  dateFilter: selectDateRange(state),
  count: getMachineAggregationsCount(state),
  page: getTelematicsMachinesPage(state),
  rowsPerPage: getTelematicsMachinesRowsPerPage(state),
  textFilter: getTelematicsMachinesTextFilter(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) => bindActionCreators({
  getAggregations: fetchAggregatedMachinesSaga,
  setTelListAdvancedFilter: setAdvancedFilter,
  setTelListDate: setDateRange,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TelematicsMachinesTable);

const TOGGLES = {
  LPIS_UPDATE_TOGGLE: {
    name: 'LPIS_UPDATE_TOGGLE',
    jira: 'CFD-2361',
    description: 'LPIS update farm',
    active: false,
    permanent: false,
  },
  PRECISION: {
    name: 'PRECISION',
    jira: 'CFD-2361',
    description: 'PRECISION new tab as applied',
    active: false,
    permanent: false,
  },
  // example:
  // MY_FIRST_TOGGLE: {
  //   name: 'MY_FIRST_TOGGLE', //should be same as key (case sensitive)
  //   jira: 'JIRA ticket ID',
  //   active: false, //active by default
  //   permanent: true //overrides user preferences
  // }
};

export default TOGGLES;

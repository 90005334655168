import React, { useEffect } from 'react';

import { Button, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { ConnectedProps, connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getErrorDrives } from '../../../shared/api/telematics/drives/drives.selectors';
import { getTelematicsListAdvancedFilter, getTelematicsListTextFilter } from '../../selectors/telematicsList.selectors';
import { selectDateRange } from '../../selectors/telematicsTabs.selectors';

import { setAdvancedFilter } from '../../../shared/actions/filter.actions';
import { setDateRange } from '../../actions/telematicsTabs.actions';

import { TELEMATICS } from '../../../core/map/constants/contexts.constants';
import { DUPLICATED_TAB_KEY } from '../../../core/map/constants/localStorage.constants';
import { LANGUAGE_ID } from '../../../shared/lang/lang.constants';

import { NAMESPACE as namespace } from '../../reducer/telematicsList.reducer';

import { MAP_CONTEXT_LS_KEY } from '../../../saga/mainMap.saga';
import CfErrorPage from '../../../shared/components/common/CfErrorPage/CfErrorPage';
import CfTextFilter from '../../../shared/containers/CfTextFilter/CfTextFilter';
import LocalStorage from '../../../shared/services/LocalStorage.service';
import { Thunk } from '../../../types';
import { TelematicsNgProps } from '../Telematics/Telematics';
import TelematicsAdvancedFilter from '../TelematicsAdvancedFilter/TelematicsAdvancedFilter';
// import TelematicsListStateSwitcher from '../TelematicsListStateSwitcher/TelematicsListStateSwitcher';
import TelematicsTable from '../TelematicsTable/TelematicsTable';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';
import { MachineTo } from '../../../shared/api/telematics/telematics.types';

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  farmId: string;
  initDateFrom: string;
  initDateTo: string;
  initMachineFilter?: MachineTo;
  langId: LANGUAGE_ID;
};
type TelematicsListProps = ReduxProps & OwnProps & TelematicsNgProps;

export const TelematicsList = ({
  advancedFilter,
  dateFilter,
  errorDrives,
  farmId,
  initDateFrom,
  initDateTo,
  initMachineFilter,
  langId,
  ngRedirectToMainMapWithFilters,
  // selectedOnPage,
  setAdvancedFilter,
  setDateRange,
  textFilter,
}: TelematicsListProps) => {
  const classes = useStyles();

  useEffect(() => {
    if (initDateFrom && initDateTo) {
      setDateRange(initDateFrom, initDateTo);
    }
    const loadLocalStorageData = () => {
      const ls = LocalStorage.loadFromLocalStorage(DUPLICATED_TAB_KEY);
      if (ls?.duplicatedTab?.filters) {
        const { dateFrom, dateTo, driver, machine } = ls.duplicatedTab.filters;
        setDateRange(dateFrom, dateTo);
        if (driver) {
          setAdvancedFilter({ driver: [{ code: driver.code, name: driver.name, validFrom: '' }] }, namespace);
        } else if (machine) {
          setAdvancedFilter({ machine: [{
            machineCode: machine.code,
            name: machine.name,
            validFrom: '',
            gpsUnit: machine.gpsUnit,
            group: machine.group,
            category: machine.category,
          }] }, namespace);
        }

        LocalStorage.removeFromLocalStorage(DUPLICATED_TAB_KEY);
      }
    };
    loadLocalStorageData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isMachineOrDriverFilterSelected = !!(advancedFilter?.machine || advancedFilter?.driver);

  return (
    <CfErrorPage error={errorDrives}>
      <div className={classes.wrapper}>
        <div className={classes.filters}>
          {/* <div className={classes.listActionsItem}>
            <TelematicsListStateSwitcher selected={selectedOnPage} />
          </div> */}
          <div className={classes.textFilter}>
            <CfTextFilter
              initialValue={textFilter}
              name="telematics-list-text-filter"
              namespace={namespace}
              translId="TelematicsList.textFilterPlaceholder"
            />
          </div>

          <div className={classes.advancedFilter}>
            <TelematicsAdvancedFilter
              initMachineFilter={initMachineFilter}
              langId={langId}
              namespace={namespace}
            />
          </div>
          {isMachineOrDriverFilterSelected && <div>
            <Button
              className={classes.buttonToMap}
              data-test="show-in-map"
              size="small"
              variant="contained"
              onClick={() => {
                // change map context before redirection
                LocalStorage.saveToLocalStorage(TELEMATICS, MAP_CONTEXT_LS_KEY);
                ngRedirectToMainMapWithFilters(
                  dateFilter.dateFrom,
                  dateFilter.dateTo,
                  advancedFilter?.machine,
                  advancedFilter?.driver,
                );
              }}
              onMouseDown={(e) => {
                if (e.button === 1) {
                  LocalStorage.saveToLocalStorage({
                    duplicatedTab: {
                      filters: {
                        driver: advancedFilter?.driver,
                        machine: advancedFilter?.machine,
                        ...dateFilter,
                      },
                    },
                  }, DUPLICATED_TAB_KEY);
                  // change map context before redirection
                  LocalStorage.saveToLocalStorage(TELEMATICS, MAP_CONTEXT_LS_KEY);
                  ngRedirectToMainMapWithFilters(
                    dateFilter.dateFrom, dateFilter.dateTo, advancedFilter?.machine, advancedFilter?.driver, true);
                }
              }}
            >
              <FormattedMessage id="TelematicsList.buttonToMap" />
            </Button>
          </div>}

        </div>
        <TelematicsTable
          farmId={farmId}
          langId={langId}
        />
      </div>
    </CfErrorPage>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(2),
  },
  filters: {
    display: 'flex',
    paddingBottom: theme.spacing(1),
    gap: theme.spacing(1.5),
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'grid',
      gridTemplateColumns: 'auto auto min-content',
      gridTemplateAreas: `
        'text-filter text-filter text-filter'
        'date . filter'
      `,
    },
  },
  listActionsItem: {
    gridArea: 'actions',
  },
  date: {
    gridArea: 'date',
    display: 'flex',
  },
  textFilter: {
    gridArea: 'text-filter',
    maxWidth: 500,
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      maxWidth: 'none',
      width: '100%',
    },
  },
  advancedFilter: {
    gridArea: 'filter',
  },
  buttonToMap: {
    borderRadius: '50px',
    color: theme.palette.common.black,
  },

}));

const mapStateToProps = (state: TelematicsState) => ({
  // selectedOnPage: getTelematicsListSelectedOnPage(state),
  textFilter: getTelematicsListTextFilter(state),
  errorDrives: getErrorDrives(state),
  advancedFilter: getTelematicsListAdvancedFilter(state),
  dateFilter: selectDateRange(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      setDateRange,
      setAdvancedFilter,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connect(mapStateToProps, mapDispatchToProps)(TelematicsList);
export type { TelematicsListProps };

import React, { Fragment } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import WarningIcon from '@mui/icons-material/Warning';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose } from 'react-recompose';

import CfBackButton from '../../../../../shared/components/common/CfBackButton/CfBackButton';
import PageHeader from '../../../../../shared/components/common/PageHeader/PageHeader';
import PageHeading from '../../../../../shared/components/common/PageHeading/PageHeading';
import withFarm from '../../../../../shared/hocs/context/withFarm';
import VariableActionExport from '../VariableActionExport/VariableActionExport';

const styles = theme => ({
  headerWrapper: {
    padding: theme.spacing(2),
  },
  headingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const ActionFormHeader = props => {
  const {
    classes,
    handleStartIsSplitting,
    isDisabled,
    isDraft,
    isExisting,
    isLoading,
    ngGoToActions,
    onClick,
    onExport,
    title,
  } = props;
  return (
    <div className={classes.headerWrapper}>
      <PageHeader
        backButton={<CfBackButton onClick={() => ngGoToActions()} translId={'action.backToActivities'} />}
        actionButtons={
          isExisting ? (
            <Fragment>
              {onExport &&
              <VariableActionExport
                iconButton={true}
                isDisabled={isDisabled}
                isLoading={isLoading}
                onExport={onExport}
              />}
              <Tooltip title={<FormattedMessage id="ActionSplit.button.tooltip.label" />}>
                <IconButton data-test="eph-split" disabled={isDisabled} onClick={handleStartIsSplitting} size="large">
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={<FormattedMessage id="ActionEdit.button.tooltip.label" />}>
                <IconButton data-test="eph-edit" disabled={isDisabled} onClick={onClick} size="large">
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Fragment>
          ) : null
        }
        heading={
          <div className={classes.headingContainer}>
            {isDraft &&
            <Tooltip title={<FormattedMessage id="ActionDetail.isDraft" />}>
              <WarningIcon color="secondary" />
            </Tooltip>}
            <PageHeading dataTest="action-name" translationId={title} />
          </div>}
      />
    </div>
  );
};

ActionFormHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isExisting: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onExport: PropTypes.func,
  ngGoToActions: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isDraft: PropTypes.bool,
  handleStartIsSplitting: PropTypes.func,
};

ActionFormHeader.defaultProps = {
  onExport: undefined,
  isLoading: false,
  isDraft: false,
};

export default compose(withStyles(styles), withFarm())(ActionFormHeader);

import { CZ_LPIS, SK_LPIS, CZ_LPIS_PROD, SK_LPIS_PROD, API_IDENTIFIER } from './api.constants';

export const getLpisLayerName = (countryCode: string, environment: string) => {
  const isProdEnvironment = environment === 'prod';
  if (countryCode === 'SK') {
    return isProdEnvironment ? SK_LPIS_PROD : SK_LPIS;
  }
  return isProdEnvironment ? CZ_LPIS_PROD : CZ_LPIS;
};

const getStates = (prefix: string) => (identifier: API_IDENTIFIER) => ({
  ERROR: `${prefix}/${identifier}_ERROR`,
  REQUEST: `${prefix}/${identifier}_REQUEST`,
  SUCCESS: `${prefix}/${identifier}_SUCCESS`,
  RESET: `${prefix}/${identifier}_RESET`,
}) as const;

const getStatesForApiCall = (prefix: string) => (identifier: API_IDENTIFIER) => [
  `${prefix}/${identifier}_REQUEST`,
  `${prefix}/${identifier}_SUCCESS`,
  `${prefix}/${identifier}_ERROR`,
];

export { getStates, getStatesForApiCall };

import { PrecisionState } from '../../../reducers/precision.reducer.types';

export const getPrecisionAsAppliedPage = (state: PrecisionState) => state.ui.precisionAsApplied.page;
export const getPrecisionAsAppliedOrder = (state: PrecisionState) => state.ui.precisionAsApplied.order;
export const getPrecisionAsAppliedOrderBy = (state: PrecisionState) => state.ui.precisionAsApplied.orderBy;
export const getPrecisionAsAppliedRowsPerPage = (state: PrecisionState) => state.ui.precisionAsApplied.rowsPerPage;
export const getPrecisionAsAppliedTextFilter = (state: PrecisionState) => state.ui.precisionAsApplied.textFilter;
export const getPrecisionAsAppliedOpenedTaskRows = (state: PrecisionState) =>
  state.ui.precisionAsApplied.openedDriverRows;

export const getPrecisionAsAppliedSelected = (state: PrecisionState) => state.ui.precisionAsApplied.selected;

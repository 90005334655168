import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import Map from 'ol/Map';

import { PointWithAttribute } from '../../../selectors/asAppliedDetail.selectors';

type PointWithAttributeFeature = {
  get(param: 'layerName'): typeof AS_APPLIED_LAYER_NAME;
  getProperties: () => {
    point: PointWithAttribute;
  }
};

const AS_APPLIED_LAYER_NAME = 'as-applied';

const isAsAppliedFeature = (feature: Feature<Point>): feature is PointWithAttributeFeature & Feature<Point> => {
  const properties = feature.getProperties();
  const layerName = feature.get('layerName');
  return layerName === AS_APPLIED_LAYER_NAME && !!properties.point;
};

const getAsAppliedLayer = (map: Map) => map.getLayers().getArray().find(layer => layer.get('name') === AS_APPLIED_LAYER_NAME);

export { AS_APPLIED_LAYER_NAME, isAsAppliedFeature, getAsAppliedLayer };

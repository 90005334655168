import {
  getStoresPage,
  getStoresOrder,
  getStoresRowsPerPage,
  getStoresMaterialType,
  getStoresShowNullValues,
  getStoresTextFilter,
} from '../selectors/stores.selectors';

import * as types from './stores.constants';

import { getFertilizersSuggestionsApi, resetFertilizersApi } from '../../../../shared/api/agroevidence/catalogues/fertilizers/fertilizers.api';
import { getPlantProtectionApi, resetPlantProtectionApi } from '../../../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.api';
import { getSeedsApi, resetSeedsApi } from '../../../../shared/api/agroevidence/catalogues/seeds/seeds.api';
import { getStores, deleteStoreItem, exportStores as exportStoresApi } from '../../../../shared/api/stores/stores/stores.api';
import { createTransaction } from '../../../../shared/api/stores/transactions/transactions.api';
import FileService from '../../../../shared/services/File.service';
import LocalStorage from '../../../../shared/services/LocalStorage.service';

export const fetchStores = () => (dispatch, getState) => {
  const state = getState();
  const params = {
    page: getStoresPage(state),
    order: getStoresOrder(state),
    rowsPerPage: getStoresRowsPerPage(state),
    materialTypeId: getStoresMaterialType(state),
    showNullValues: getStoresShowNullValues(state),
    textFilter: getStoresTextFilter(state),
  };
  return dispatch(getStores(params));
};

export const setStoreMaterialType = materialTypeId => ({
  type: types.SET_STORES_MATERIAL_TYPE,
  material: materialTypeId,
});

export const setStoreShowNullValues = showNullValues => ({
  type: types.SET_STORES_DISPLAY_NULL,
  showNullValues,
});

export const getMaterialsByName = textFilter => dispatch => {
  dispatch(getPlantProtectionApi({ search: textFilter }));
  dispatch(getFertilizersSuggestionsApi({ search: textFilter }));
  dispatch(getSeedsApi({ search: textFilter }));
};

export const resetMaterials = () => dispatch => {
  dispatch(resetFertilizersApi());
  dispatch(resetPlantProtectionApi());
  dispatch(resetSeedsApi());
};

export const saveNewStore = item => dispatch =>
  dispatch(createTransaction(item)).then(() => {
    dispatch(fetchStores());
  });

export const deleteStore = (farmId, itemId) => dispatch =>
  dispatch(deleteStoreItem(itemId)).then(() => {
    deleteStoreUnitIdFromLS(farmId, itemId);
    dispatch(fetchStores());
  });

const deleteStoreUnitIdFromLS = (farmId, storeId) => {
  const records = LocalStorage.loadFromLocalStorage(`${farmId}-stores`) || {};
  delete records[storeId];
  LocalStorage.saveToLocalStorage(records, `${farmId}-stores`);
};

export const exportStores = exportFormat => (dispatch, getState) => {
  const state = getState();
  const params = {
    type: getStoresMaterialType(state),
    nulls: getStoresShowNullValues(state),
    name: getStoresTextFilter(state),
    'sort-dir': getStoresOrder(state),
    format: exportFormat,
  };
  dispatch(exportStoresApi(params)).then(res => {
    FileService.processFileResponse(res);
  });
};

import { RSAAAction, RSAAResultAction } from 'redux-api-middleware';

import { getTelematicsAggregationsTextFilter } from '../selectors/telematicsAggregations.selectors';
import { selectDateRange } from '../selectors/telematicsTabs.selectors';

import * as types from './telematicsAggregations.constants';

import { EditBatchDriverType, calculateAreaFromEquipment, editBatchDriverApi, exportAggregatedDrivesApi, exportTosDataApi, editBatchEquipmentApi, EditBatchEquipmentType, editBatchProductionOperationApi, EditBatchProductionOperationType, editBatchBonusApi, EditBatchBonusType, editBatchSubjectApi, EditBatchSubjectType, editBatchCustomerKeyApi, EditBatchCustomerKeyType } from '../../shared/api/telematics/aggregations/aggregations.api';
import FileService from '../../shared/services/File.service';

import { TelematicsState } from '../../reducers/telematics.reducer.types';
import { ExportPayload } from '../../shared/api/api.types';
import { ExportAggregatedDrivesApiParams } from '../../shared/api/telematics/aggregations/aggregations.types';
import { DriveRecalculateTo } from '../../shared/api/telematics/telematics.types';

export const setDate = (dateFrom: string, dateTo: string) => ({
  type: types.SET_DATE,
  dateFrom,
  dateTo,
});

export const setUnapprovedRidesFilter = (payload: boolean) => ({
  type: types.SET_UNAPPROVED_RIDES_FILTER,
  payload,
});

export const setOpenedDriverRows = (payload: number[]) => ({
  type: types.SET_OPENED_DRIVER_ROWS,
  payload,
});

export const setBulkEditMode = (payload: boolean) => ({
  type: types.SET_BULK_EDIT_MODE,
  payload,
});

export const fetchAggregationsSaga = () => ({
  type: types.FETCH_AGGREGATED_DRIVES,
});

export const refetchAggregationsSaga = () => ({
  type: types.FETCH_AGGREGATED_DRIVES,
  refetch: true,
});

export const setRidesStatusUpdate = (payload: string[]) => ({
  type: types.SET_RIDES_STATUS_UPDATE,
  payload,
});

export const exportAggregatedDrives = () =>
  (dispatch: (action: RSAAAction) => Promise<ExportPayload>, getState: () => TelematicsState) => {
    const state = getState();

    const params: ExportAggregatedDrivesApiParams = {
      ...selectDateRange(state),
      driver: getTelematicsAggregationsTextFilter(state),
    };

    dispatch(exportAggregatedDrivesApi(params)).then((res: ExportPayload) => {
      FileService.processFileResponse(res);
    });
  };

export const exportTosData = () =>
  (dispatch: (action: RSAAAction) => Promise<ExportPayload>) => {
    dispatch(exportTosDataApi()).then((res: ExportPayload) => {
      FileService.processFileResponse(res);
    });
  };

export const calculateArea = (driveId: number, equipment: DriveRecalculateTo, changeHandler: (area: number) => void) =>
  (dispatch: (action: RSAAAction) => Promise<RSAAResultAction<{area: number}>>) => {
    dispatch(calculateAreaFromEquipment(driveId, equipment)).then(res => {
      if (res.error) return;
      if (res.payload && typeof res.payload?.area === 'number') {
        changeHandler(res.payload.area);
      }
    });
  };

export const editBatchDriver = (params: EditBatchDriverType) => (
  dispatch: (action: RSAAAction) => Promise<RSAAResultAction>,
) => dispatch(editBatchDriverApi(params));

export const editBatchEquipment = (params: EditBatchEquipmentType) => (
  dispatch: (action: RSAAAction) => Promise<RSAAResultAction>,
) => dispatch(editBatchEquipmentApi(params));

export const editBatchProductionOperation = (params: EditBatchProductionOperationType) => (
  dispatch: (action: RSAAAction) => Promise<RSAAResultAction>,
) => dispatch(editBatchProductionOperationApi(params));

export const editBatchBonus = (params: EditBatchBonusType) => (
  dispatch: (action: RSAAAction) => Promise<RSAAResultAction>,
) => dispatch(editBatchBonusApi(params));

export const editBatchSubject = (params: EditBatchSubjectType) => (
  dispatch: (action: RSAAAction) => Promise<RSAAResultAction>,
) => dispatch(editBatchSubjectApi(params));

export const editBatchCustomerKey = (params: EditBatchCustomerKeyType) => (
  dispatch: (action: RSAAAction) => Promise<RSAAResultAction>,
) => dispatch(editBatchCustomerKeyApi(params));

import React, { FC, useState } from 'react';

import Grid from '@mui/material/Grid';

import { ActionRestrictionCard } from './ActionRestrictionCard';
import ActionRestrictionsDetailsDialog from './ActionRestrictionsDetailsDialog';

import { ValidationResponseTo, ViolationSeverity, ViolationTo } from '../../../../../shared/api/agroevidence/agroevidence.types';

export interface ActionRestrictionsInfoProps {
  validationDetails: ValidationResponseTo,
}

export const ActionRestrictionsInfo: FC<ActionRestrictionsInfoProps> = ({
  validationDetails,
}) => {
  const [dialogViolationContent, setDialogViolationContent] = useState<ViolationTo[]>([]);

  const hasRestrictions = validationDetails.valid === false;

  const handleDialogOpen = (violation: ViolationTo[]) => setDialogViolationContent(violation);

  const handleDialogBack = () => setDialogViolationContent([]);

  const getViolationsBySeverity = (severity: ViolationSeverity) =>
    validationDetails.restrictions.map((restriction) =>
      restriction.violations.filter(
        (violation) => violation.severity === severity,
      ),
    );

  const warningViolations: ViolationTo[][] = hasRestrictions ? getViolationsBySeverity(ViolationSeverity.WARNING) : [];

  const errorViolations: ViolationTo[][] = hasRestrictions
    ? getViolationsBySeverity(ViolationSeverity.ERROR)
    : [];

  return hasRestrictions ? (
    <Grid item md={8} sm={10} xs={12}>
      <div id="validation-info">
        {hasRestrictions ? (
          <>
            {errorViolations.map((violations, idx) => (
              <ActionRestrictionCard
                actionInfoType="ERROR"
                handleDialogOpen={handleDialogOpen}
                isAction
                key={idx}
                violations={violations}
              />
            ))}
            {warningViolations.map((violations, idx) => (
              <ActionRestrictionCard
                actionInfoType="WARNING"
                handleDialogOpen={handleDialogOpen}
                isAction
                key={idx}
                violations={violations}
              />
            ))}
          </>
        ) : (
          <ActionRestrictionCard actionInfoType="SUCCESS" />
        )}
        <ActionRestrictionsDetailsDialog
          onBack={handleDialogBack}
          opened={dialogViolationContent.length > 0}
          violationContent={dialogViolationContent}
        />
      </div>
    </Grid>
  ) : null;
};

export default ActionRestrictionsInfo;

import React, { FC } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { usePrecisionListStyles } from './styles';

interface Props {
  onClick: () => void;
  selected: number[];
}

const BulkDeleteButton: FC<Props> = ({
  onClick,
  selected,
}) => {
  const classes = usePrecisionListStyles();

  return (
    <Button
      aria-label="precision-task-selected"
      className={classes.bulkDeleteButton}
      color="primary"
      disabled={!selected.length}
      onClick={onClick}
    >
      <DeleteIcon />
      <FormattedMessage
        id={'PrecisionFarming.asApplied.tasks.selected'}
        values={{ count: selected.length }}
      />
    </Button>
  );
};

export default BulkDeleteButton;

import React from 'react';

import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import { CfFormikErrors } from '../../../types';
import { CreateHandworkType } from '../TelematicsHandwork/TelematicsHandworkForm';

import { DriveKeysFormValues } from './DriveKeysForm';
import { CollisionsFormValues, SplitTimeFormValues } from './TimelineContainer';

import { TelematicsOperation, Type } from '../../../shared/api/telematics/telematics.types';
import { DetailContentFormValues } from './DetailContentForm.types';

export const MESSAGES = {
  required: <FormattedMessage id="validation.required" />,
  bonus: <FormattedMessage id="TelematicsList.validation.bonus" />,
  noFutureOrToday: <FormattedMessage id="validation.noFutureOrToday" />,
  invalidTimeRange: <FormattedMessage id="validation.invalidTimeRange" />,
  invalidTimeMinLength: <FormattedMessage id="error.message.record.minLength" />,
  invalidDate: <FormattedMessage id="validation.invalidDate" />,
  noAreaForOperation: <FormattedMessage id="Telematics.validation.noAreaForOperation" />,
};

export const validateWorkingWidth = (value: number) => {
  if (value > 50 || value < 0) {
    return 'TelematicsList.validation.workingtWidth';
  }
  return '';
};

export const isBelowMinute = (seconds: number) => seconds < 60;

export const validateSplitTimeForm = (
  values: SplitTimeFormValues, times: {remainingPart: number, selectedPart: number},
) => {
  const errors: CfFormikErrors<SplitTimeFormValues> = {};

  if (!values.time) {
    errors.time = <FormattedMessage id="validation.required" />;
  } else if (!values.time.isValid()) {
    errors.time = <FormattedMessage id="validation.invalid.format" />;
  } else if (values?.time) {
    if (isBelowMinute(times.remainingPart) || isBelowMinute(times.selectedPart)) {
      errors.time = <FormattedMessage id="TelematicsAggregations.detail.splitting.error.minLength" />;
    }
  }
  return errors;
};

export const validateCollisionForm = (
  values: CollisionsFormValues,
) => {
  const errors: CfFormikErrors<CollisionsFormValues> = {};
  if (values.checkedIds.length === 0) {
    errors.checkedIds = <FormattedMessage id="TelematicsAggregations.detail.collision.error.minLength" />;
  } else if (!values.validIntervals) {
    errors.validIntervals = <FormattedMessage id="TelematicsAggregations.detail.splitting.error.minLength" />;
  }
  return errors;
};

export const validateHandworkForm = (
  values: Partial<CreateHandworkType>,
  sumTimeFormatted: string,
) => {
  const errors: CfFormikErrors<CreateHandworkType> = {};

  if (!values.operation) {
    errors.operation = MESSAGES.required;
  }

  if (typeof values.bonus !== 'number') {
    errors.bonus = MESSAGES.required;
  }

  if (values.bonus && (values.bonus < -999 || values.bonus > 999)) {
    errors.bonus = MESSAGES.bonus;
  }

  if (values.operation === TelematicsOperation.PARCEL && !values.isExternal) {
    if (!values.cultivated) {
      errors.cultivated = MESSAGES.required;
    }
    if (!values.parcelId) {
      errors.parcelId = MESSAGES.required;
    }
  }

  if (values.type === Type.HANDWORK) {
    const yesterday = moment().subtract(1, 'days').endOf('day');

    if (!values.date?.isValid()) {
      errors.date = MESSAGES.invalidDate;
    }
    if (!values.timeFrom?.isValid()) {
      errors.timeFrom = MESSAGES.invalidTimeRange;
    }
    if (!values.timeTo?.isValid()) {
      errors.timeTo = MESSAGES.invalidTimeRange;
    }

    if (values.date?.isAfter(yesterday)) {
      errors.date = MESSAGES.noFutureOrToday;
    }

    if (!values.productionOperation) {
      errors.productionOperation = MESSAGES.required;
    }

    if (values.timeTo?.isBefore(values.timeFrom)) {
      errors.timeTo = MESSAGES.invalidTimeRange;
    }

    if (values.timeTo?.isSame(values.timeFrom) || sumTimeFormatted === '00:00') {
      errors.timeTo = MESSAGES.invalidTimeMinLength;
    }

    if (!values.driverCode) {
      errors.driverCode = MESSAGES.required;
    }
  }

  if (!values.supplierKey?.id) {
    errors.supplierKey = 'validation.required';
  }
  if (!values.customerKey?.id) {
    errors.customerKey = 'validation.required';
  }

  if (
    values.supplierKey && values.customerKey &&
    values.supplierKey?.id === values.customerKey?.id
  ) {
    if (!values.client) {
      errors.client = 'validation.required';
    }
  } else if (values.client) {
    errors.client = 'Telematics.validation.emptyValueRequired';
  }

  return errors;
};

export const validateKeysForm = (values: DriveKeysFormValues) => {
  const errors: CfFormikErrors<DriveKeysFormValues> = {};

  if (!values.supplierKey?.id) {
    errors.supplierKey = 'validation.required';
  }
  if (!values.customerKey?.id) {
    errors.customerKey = 'validation.required';
  }

  if (
    values.supplierKey && values.customerKey &&
    values.supplierKey?.id === values.customerKey?.id
  ) {
    if (!values.client) {
      errors.client = 'validation.required';
    }
  } else if (values.client) {
    errors.client = 'Telematics.validation.emptyValueRequired';
  }
  return errors;
};

export const validateContentForm = (
  values: DetailContentFormValues,
  sumTimeFormatted: string,
) => {
  const errors: CfFormikErrors<DetailContentFormValues> = {};

  if (!values.gpsUnit) {
    errors.gpsUnit = MESSAGES.required;
  }

  if (!values.operation) {
    errors.operation = MESSAGES.required;
  }

  if (typeof values.bonus !== 'number') {
    errors.bonus = MESSAGES.required;
  }

  if (values.bonus < -999 || values.bonus > 999) {
    errors.bonus = MESSAGES.bonus;
  }

  if (values.operation === TelematicsOperation.PARCEL && !values.isExternal) {
    if (!values.cultivated) {
      errors.cultivated = MESSAGES.required;
    }
    if (!values.parcelId) {
      errors.parcelId = MESSAGES.required;
    }
  }

  if (values.operation !== TelematicsOperation.PARCEL) {
    if (values.cultivated > 0) {
      errors.cultivated = MESSAGES.noAreaForOperation;
    }
  }

  if (values.type === Type.MANUAL) {
    const yesterday = moment().subtract(1, 'days').endOf('day');

    if (!values.date?.isValid()) {
      errors.date = MESSAGES.invalidDate;
    }
    if (!values.timeFrom?.isValid()) {
      errors.timeFrom = MESSAGES.invalidTimeRange;
    }
    if (!values.timeTo?.isValid()) {
      errors.timeTo = MESSAGES.invalidTimeRange;
    }

    if (values.date?.isAfter(yesterday)) {
      errors.date = MESSAGES.noFutureOrToday;
    }

    if (!values.productionOperation) {
      errors.productionOperation = MESSAGES.required;
    }

    if (values.timeTo?.isBefore(values.timeFrom)) {
      errors.timeTo = MESSAGES.invalidTimeRange;
    }

    if (values.timeTo?.isSame(values.timeFrom) || sumTimeFormatted === '00:00') {
      errors.timeTo = MESSAGES.invalidTimeMinLength;
    }

    if (!values.driverCode) {
      errors.driverCode = MESSAGES.required;
    }
  }

  return errors;
};

import React, { Component } from 'react';

import { Tooltip } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose } from 'react-recompose';

import CfFormattedNumber from '../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';
import SelectionItem from '../../../../../shared/components/common/SelectionItem/SelectionItem';
import SelectionItemColumn from '../../../../../shared/components/common/SelectionItemColumn/SelectionItemColumn';
import SelectionItemHeading from '../../../../../shared/components/common/SelectionItemHeading/SelectionItemHeading';
import { CropName } from '../../../../../shared/components/specific/CropName/CropName';
import withFarm from '../../../../../shared/hocs/context/withFarm';
import SubtractableAreaLink from '../../../eph/components/SubtractableAreaLink/SubtractableAreaLink';
import ActionToTelematicsLinks from '../ActionToTelematicsLinks/ActionToTelematicsLinks';

const styles = {
  tooltip: {
    fontSize: 12,
  },
  subheading: {
    color: 'inherit',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  parcelItemWrapper: {
    width: '100%',
  },
};

class ActionParcelsList extends Component {
   renderParcelItem = (
     fieldName,
     index,
     field,
     actionRelatedDrivesForParcel,
     showAccountableNitrogen,
     handleCheckboxChange,
     checkedItems,
     isSplitting,
   ) => (
     <Stack direction="row" key={fieldName}>
       {isSplitting &&
       <Stack
         alignItems="center"
         direction="column"
         justifyContent="center"
         spacing={2}
                  >
         <Checkbox
           checked={checkedItems[field.id] || false}
           color="primary"
           disabled={false}
           onChange={(e) => {
             handleCheckboxChange(field.id, e.target.checked);
           }}
        />
       </Stack>
     }
       <div style={styles.parcelItemWrapper}>
         <SelectionItem
           editing={this.props.isEditing}
           onRemoveItem={() => {
             this.props.onItemRemove(index);
           }}
    >
           <Grid container spacing={0}>
             <Grid item sm={3} xs={12}>
               {
            // TODO: "a" is temporary
            // change to "<Link to=" after remove angular and updating react-router-dom to version 6 (or higher)
          }
               <a href={`/farm/${this.props.farm.id}/parcels/${field.id}/overview`}>
                 <SelectionItemHeading
                   classes={{ subheading: this.props.classes.subheading }}
                   reversed={true}
                   subheading={field.blockNumber}
              >
                   <div>{field.localName}</div>
                 </SelectionItemHeading>
               </a>
             </Grid>
             <Grid item sm={2} xs={6}>
               <SelectionItemColumn label={<FormattedMessage id="common.area-ha" />}>
                 <SubtractableAreaLink
                   editing={this.props.isEditing}
                   formName={this.props.formName}
                   index={index}
                   parcel={field}
                   subtractableAreasIds={this.props.subtractableAreasIds}
            />
               </SelectionItemColumn>
             </Grid>
             <Grid item sm={3} xs={6}>
               <SelectionItemColumn label={
            field.seedApplication?.type === 'CATCH_CROP' ?
              <FormattedMessage id="ActionParcelsList.catchCrop" /> :
              <FormattedMessage id="common.crop" />
            }
          >
                 <div>
                   <CropName cropType={field.seedApplication?.type}>
                     {field.seedApplication?.seed.name}
                   </CropName>
                 </div>
               </SelectionItemColumn>
             </Grid>
             <Grid item sm={2} xs={6}>
               <SelectionItemColumn label={<FormattedMessage id="ActionParcelsList.NSA" />}>
                 <div>
                   {field.nitrateVulnerable ? <FormattedMessage id="common.yes" /> : <FormattedMessage id="common.no" />}
                 </div>
               </SelectionItemColumn>
             </Grid>
             <Grid item sm={actionRelatedDrivesForParcel ? 1 : 2} xs={actionRelatedDrivesForParcel ? 3 : 6}>
               <SelectionItemColumn
                 label={
                   <span>
                     N <FormattedMessage id={'unit.kg'} />
                     /ha
                   </span>
              }
            >
                 <Tooltip
                   classes={{ tooltip: this.props.classes.tooltip }}
                   placement="bottom"
                   title={<FormattedMessage id="ActionParcelsList.accountableNitrogen" />}
                >
                   <div>{this.renderNitrogen(field, showAccountableNitrogen)}</div>
                 </Tooltip>
               </SelectionItemColumn>
             </Grid>
             {!!actionRelatedDrivesForParcel &&
             <Grid className={this.props.classes.iconContainer} item sm={1} xs={3}>
               <ActionToTelematicsLinks data={actionRelatedDrivesForParcel} />
             </Grid>}
           </Grid>
         </SelectionItem>
       </div>
     </Stack>
   );

   renderNitrogen(field, showAccountableNitrogen) {
     if (field.accountableNitrogen >= 0 && showAccountableNitrogen) {
       return (
         <span>
           <CfFormattedNumber value={field.accountableNitrogen} />
           {this.renderExpectedNitrogen(field)}
         </span>
       );
     }
     return <span>...</span>;
   }

   renderExpectedNitrogen(field) {
     const { accountableNitrogen, actionParcelTotalArea, area } = field;

     // check expense.n !== undefined because n set only for fertilizer
     const expectedNitrogen = this.props.expenses?.reduce((acc, expense) =>
       acc + (expense.n !== undefined ? ((actionParcelTotalArea / area) * expense.n) : 0), 0);

     const addedNitrogen = accountableNitrogen + expectedNitrogen;
     return (
       <span>
         {'\u2192'} <CfFormattedNumber value={addedNitrogen} />
       </span>
     );
   }

   getActionRelatedDrivesPerParcel(parcelId) {
     const { actionRelatedDrives } = this.props;
     return actionRelatedDrives && actionRelatedDrives[parcelId];
   }

   render() {
     const { checkedItems, fields, handleCheckboxChange, isSplitting, targetCrop } = this.props;

     return (
       <div id="action-parcel-list">
         {fields.map((fieldName, index) => {
           const field = fields.get(index);
           const showAccountableNitrogen = field.seedApplication?.seed?.legislativeCode === targetCrop?.legislativeCode;
           const actionRelatedDrivesForParcel = this.getActionRelatedDrivesPerParcel(field.id);
           return this.renderParcelItem(
             fieldName,
             index,
             field,
             actionRelatedDrivesForParcel,
             showAccountableNitrogen,
             handleCheckboxChange,
             checkedItems,
             isSplitting,
           );
         })}
       </div>
     );
   }
}

ActionParcelsList.propTypes = {
  classes: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  onItemRemove: PropTypes.func,
  formName: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
  subtractableAreasIds: PropTypes.array,
  farm: PropTypes.object.isRequired,
  expenses: PropTypes.array.isRequired,
  actionRelatedDrives: PropTypes.object,
  targetCrop: PropTypes.object,
  handleCheckboxChange: PropTypes.func,
  checkedItems: PropTypes.object,
  isSplitting: PropTypes.bool,
};

ActionParcelsList.defaultProps = {
  onItemRemove: () => {},
  handleCheckboxChange: () => {},
  checkedItems: {},
  subtractableAreasIds: undefined,
  isDraft: false,
  targetCrop: null,
};

export default compose(withStyles(styles), withFarm())(ActionParcelsList);

import React from 'react';

import { FormikProps } from 'formik';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { editBatchEquipment } from '../../actions/telematicsAggregations.actions';

import CfFormControl from '../../../shared/components/form/CfFormControl/CfFormControl';
import { stringDateToMoment } from '../../../shared/misc/timeHelpers';
import { CfFormikErrors } from '../../../types';
import { MESSAGES } from '../../components/TelematicsAggregationDetailContent/validators';
import TelematicsDetailEquipmentSelector from '../../components/TelematicsDetailSelectors/TelematicsDetailEquipmentSelector';

import { useCustomStyles } from './styles/useCustomStyles';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';
import { EquipmentTo } from '../../../shared/api/telematics/telematics.types';

const useEquipment = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const customClasses = useCustomStyles();
  const [selectedEquipment, setSelectedEquipment] = React.useState<EquipmentTo | null | undefined>(undefined);

  const selectedRides = useSelector((state: TelematicsState) => state.ui.telematicsAggregations.selectedRides);
  const dateFrom = useSelector((state: TelematicsState) => state.ui.telematicsTabs.dateFilter.dateFrom);
  const dateFromMoment = stringDateToMoment(dateFrom);
  let dateForClassifiers: string;
  if (dateFromMoment) {
    dateForClassifiers = dateFromMoment.toISOString() ?? moment().startOf('day').toISOString();
  }

  const initialValues = {
    equipmentCode: '',
  };

  const validateContentForm = (values: typeof initialValues) => {
    const errors: CfFormikErrors<typeof initialValues> = {};

    if (!values.equipmentCode) {
      errors.equipmentCode = MESSAGES.required;
    }
    return errors;
  };

  const onSubmit = () => {
    const payload = { drive: selectedRides, equipment: selectedEquipment?.id ?? -1 };
    return editBatchEquipment(payload)(dispatch);
  };

  const component = (formikProps: FormikProps<typeof initialValues>) => (<CfFormControl>
    <TelematicsDetailEquipmentSelector
      customClasses={customClasses}
      dateFrom={dateForClassifiers}
      dateTo={dateForClassifiers}
      disabled={formikProps.isSubmitting}
      error={!!formikProps.errors.equipmentCode}
      helperText={(formikProps.errors.equipmentCode as string) ?? ''}
      placeholder={intl.formatMessage({ id: 'TelematicsAggregations.list.bulk.secondary.placeholder.equipment' })}
      selectedCode={formikProps.values.equipmentCode}
      onChange={(equipment: EquipmentTo | null) => {
        formikProps.setFieldValue('equipmentCode', equipment?.code ?? '');
        setSelectedEquipment(equipment);
      }}
    />
  </CfFormControl>);

  return {
    initialValues,
    validateContentForm,
    onSubmit,
    component,
  };
};

export default useEquipment;

import React, { FC, Fragment, useMemo } from 'react';

import Popover from '@mui/material/Popover';
import { makeStyles } from '@mui/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getZones } from '../../../../../shared/api/agroevidence/zones/zones.selectors';

import { getZonesApi } from '../../../../../shared/api/agroevidence/zones/zones.api';
import CfSwitcherButton from '../../../../../shared/components/common/CfSwitcherButton/CfSwitcherButton';
import withPopover from '../../../../../shared/hocs/withPopover';
import { Thunk } from '../../../../../types';
import { ParcelsService } from '../../services/Parcels.service';

import ZoneAutosuggest from './ZoneAutosuggest';

import { ParcelsState } from '../../../../../reducers/parcels.reducer.types';
import { ParcelTo, ParcelDetailTo, ZoneTo } from '../../../../../shared/api/agroevidence/agroevidence.types';

const useStyles = makeStyles(() => ({
  paper: {
    overflow: 'hidden',
    height: 250,
    width: 250,
  },
  switcherButtonLabel: {
    fontSize: 13,
  },
  button: {
    marginLeft: -9,
  },
}));

export interface ParcelZoneAssignProps {
    anchorEl?: null | Element,
    getZonesApi: (search: string) => void,
    handlePopoverClose: () => void,
    handlePopoverOpen: () => void,
    isOpen: boolean,
    onZoneChange: (zoneId: string, parcelId?: string) => void,
    parcel?: ParcelDetailTo | ParcelTo,
    suggestions: ZoneTo[],
    withPopoverEl?: boolean,
  }

const ParcelZoneAssign: FC<ParcelZoneAssignProps> = ({
  anchorEl = null,
  getZonesApi,
  handlePopoverClose,
  handlePopoverOpen,
  isOpen,
  onZoneChange,
  parcel,
  suggestions,
  withPopoverEl = true,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const extendedeSuggestions = useMemo(() => ([
    {
      id: null,
      name: intl.formatMessage({ id: 'ParcelDetailOverview.noZoneOption' }),
    },
    ...suggestions,
  ]), [suggestions, intl]);

  const onSuggestionSelect = (suggestion: ZoneTo) => {
    if (parcel?.zone?.id === suggestion.id) {
      return;
    }
    onZoneChange(suggestion.id, parcel?.id);
    handlePopoverClose();
  };

  const onSuggestionClear = () => {
    handlePopoverClose();
  };

  const isHistorical = ParcelsService.isParcelHistorical(parcel);

  return withPopoverEl ? (
    <Fragment>
      <CfSwitcherButton
        classes={{ custom: classes.button }}
        disabled={isHistorical}
        onClick={handlePopoverOpen}
        transparent={true}>
        <span className={classes.switcherButtonLabel}>
          {parcel?.zone?.name ?? <FormattedMessage id="ParcelDetailOverview.noZoneOption" />}
        </span>
      </CfSwitcherButton>
      <Popover
        anchorEl={anchorEl}
        classes={{ paper: classes.paper }}
        onClose={handlePopoverClose}
        open={isOpen}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        transformOrigin={{
          horizontal: 250,
          vertical: 0,
        }}
      >
        <ZoneAutosuggest
          getSuggestions={getZonesApi}
          onSuggestionClear={onSuggestionClear}
          onSuggestionSelect={onSuggestionSelect}
          suggestions={extendedeSuggestions}
        />
      </Popover>
    </Fragment>
  ) : (
    <ZoneAutosuggest
      getSuggestions={getZonesApi}
      onSuggestionClear={onSuggestionClear}
      onSuggestionSelect={onSuggestionSelect}
      suggestions={extendedeSuggestions}
    />
  );
};

const mapStateToProps = (state: ParcelsState) => ({
  suggestions: getZones(state),
});

const mapDispatchToProps = (dispatch: Thunk<ParcelsState>) =>
  bindActionCreators(
    {
      getZonesApi,
    },
    dispatch,
  );

export default withPopover(connect(mapStateToProps, mapDispatchToProps)(ParcelZoneAssign));

import React, { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import FAButton from '../FAButton/FAButton';

const useStyles = makeStyles({
  menuPaper: {
    marginLeft: 15,
  },
});

type DroplistAddOption = {
  dataTest?: string;
  disabled?: boolean;
  divider?: boolean;
  intlId: string;
  onClick: () => void;
  visible?: boolean;
}

type Props = {
  options: DroplistAddOption[];
};

const DroplistAdd: React.FC<Props> = ({ options }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleClick = () => () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <FAButton onClick={handlePopoverOpen}>
        <AddIcon />
      </FAButton>
      <Menu
        anchorEl={anchorEl}
        className={classes.menuPaper}
        disableScrollLock={true}
        onClose={handlePopoverClose}
        open={!!anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map((op, i) => {
          const { dataTest, disabled, divider, intlId, onClick, visible = true } = op;
          return visible && (
          <MenuItem
            data-test={dataTest}
            disabled={disabled}
            divider={Boolean(divider)}
            key={i}
            onClick={() => {
              handleClick();
              onClick();
            }}
        >
            <FormattedMessage id={intlId} />
          </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default DroplistAdd;

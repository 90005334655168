import _ from 'lodash';
import { AnyAction } from 'redux';

import * as types from './aggregations.constants';

import { KeyType } from '../telematics.types';
import { AggregationsState } from './aggregations.types';

const initialState: AggregationsState = {
  driverAggregations: null,
  isFetchingDriverAggregations: false,
  driverAggregationsCount: 0,
  driveDetail: null,
  isFetchingDriveDetail: false,
  isExportingData: false,
  apiError: null,
  apiErrorTriggeredBy: '',
  exportError: null,
  keyTypes: {
    [KeyType.CUSTOMER]: null,
    [KeyType.SUPPLIER]: null,
    [KeyType.EQUIPMENT]: null,
  },
  winfasClients: null,
  isFetchingKeyTypes: false,
  driveKeys: null,
  isFetchingDriveKeys: false,
  tosStatus: null,
  actionRelatedDrives: null,
  isFetchingActionRelatedDrives: false,
};

export default () => (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case types.GET_DRIVER_AGGREGATIONS_REQUEST:
      return {
        ...state,
        isFetchingDriverAggregations: !action.payload.refetch,
        apiError: null,
        apiErrorTriggeredBy: '',
      };

    case types.GET_DRIVER_AGGREGATIONS_SUCCESS:
      return {
        ...state,
        driverAggregations: action.payload,
        driverAggregationsCount: Number(action.meta.headers.get('X-Total-Count')),
        isFetchingDriverAggregations: false,
      };

    case types.GET_DRIVER_AGGREGATIONS_ERROR:
      return {
        ...state,
        isFetchingDriverAggregations: false,
        apiError: action.payload,
        apiErrorTriggeredBy: action.type,
      };

    case types.GET_AGGREGATED_DRIVE_REQUEST:
      return {
        ...state,
        isFetchingDriveDetail: true,
        apiError: null,
        apiErrorTriggeredBy: '',
      };
    case types.GET_AGGREGATED_DRIVE_SUCCESS:
      return {
        ...state,
        isFetchingDriveDetail: false,
        driveDetail: action.payload,
      };
    case types.GET_AGGREGATED_DRIVE_ERROR:
      return {
        ...state,
        driveDetail: null,
        isFetchingDriveDetail: false,
        apiError: action.payload,
        apiErrorTriggeredBy: action.type,
      };

    case types.RESET_AGGREGATED_DRIVE:
      return {
        ...state,
        driveDetail: null,
        driveKeys: null,
        isFetchingDriveDetail: false,
        apiError: null,
        apiErrorTriggeredBy: '',
      };

    case types.RESET_API_ERROR:
      return {
        ...state,
        apiError: null,
        apiErrorTriggeredBy: '',
      };

    case types.GET_TOS_STATUS_REQUEST:
      return {
        ...state,
        apiError: null,
        apiErrorTriggeredBy: '',
      };

    case types.GET_TOS_STATUS_SUCCESS:
      return {
        ...state,
        tosStatus: action.payload,
      };

    case types.GET_TOS_STATUS_ERROR:
      return {
        ...state,
        tosStatus: null,
        apiError: action.payload,
        apiErrorTriggeredBy: action.type,
      };

    case types.EXPORT_AGGREGATED_DRIVES_REQUEST:
    case types.EXPORT_TOS_DATA_REQUEST:
      return {
        ...state,
        isExportingData: true,
        exportError: null,
      };
    case types.EXPORT_AGGREGATED_DRIVES_SUCCESS:
    case types.EXPORT_TOS_DATA_SUCCESS:
      return {
        ...state,
        isExportingData: false,
        exportError: null,
      };
    case types.EXPORT_AGGREGATED_DRIVES_ERROR:
    case types.EXPORT_TOS_DATA_ERROR:
      return {
        ...state,
        isExportingData: false,
        exportError: action.payload,
      };
    case types.RESET_EXPORT_ERROR:
      return {
        ...state,
        exportError: null,
      };

    case types.GET_AGGREGATED_DRIVE_KEYS_REQUEST:
      return {
        ...state,
        isFetchingDriveKeys: true,
        apiError: null,
        apiErrorTriggeredBy: '',
      };
    case types.GET_AGGREGATED_DRIVE_KEYS_SUCCESS:
      return {
        ...state,
        isFetchingDriveKeys: false,
        driveKeys: action.payload,
      };
    case types.GET_AGGREGATED_DRIVE_KEYS_ERROR:
      return {
        ...state,
        driveKeys: null,
        isFetchingDriveKeys: false,
        apiError: action.payload,
        apiErrorTriggeredBy: action.type,
      };
    case types.GET_KEY_COMBINATIONS_REQUEST:
      return {
        ...state,
        isFetchingKeyTypes: true,
        apiError: null,
        apiErrorTriggeredBy: '',
      };
    case types.GET_KEY_COMBINATIONS_SUCCESS:
      return {
        ...state,
        isFetchingKeyTypes: false,
        keyTypes: {
          ...state.keyTypes,
          [action.payload.key]: action.payload.data,
        },
      };
    case types.GET_KEY_COMBINATIONS_ERROR:
      return {
        ...state,
        apiError: action.payload,
        apiErrorTriggeredBy: action.type,
      };
    case types.GET_ACTION_RELATED_DRIVES_REQUEST:
      return {
        ...state,
        apiError: null,
        isFetchingActionRelatedDrives: true,
        apiErrorTriggeredBy: '',
      };

    case types.GET_ACTION_RELATED_DRIVES_SUCCESS:
      return {
        ...state,
        isFetchingActionRelatedDrives: false,
        actionRelatedDrives: _.isEmpty(action.payload?.parcels) ? null : action.payload.parcels,
      };

    case types.GET_ACTION_RELATED_DRIVES_ERROR:
      return {
        ...state,
        isFetchingActionRelatedDrives: false,
        apiError: action.payload,
        apiErrorTriggeredBy: action.type,
      };

    case types.GET_WINFAS_CLIENT_COMBINATIONS_SUCCESS:
      return {
        ...state,
        winfasClients: action.payload,
      };

    case types.GET_WINFAS_CLIENT_COMBINATIONS_ERROR:
      return {
        ...state,
        apiError: action.payload,
        apiErrorTriggeredBy: action.type,
      };

    default:
      return state;
  }
};

import { AnyAction } from 'redux';

import * as types from './parcelsCropDetails.constants';

const initialState = {
  items: [],
  isFetchingItems: false,
  itemsError: undefined,
  totalCount: 0,
  itemsSuggestion: [],
  isFetchingItemsSuggestion: false,
  statistics: undefined,
  isFetchingStatistics: false,
  item: undefined,
  isFetchingItem: false,
  itemError: undefined,
  isFetchingCenters: false,
  centers: [],
  itemSeedApplication: [],
  isFetchingItemSeedApplication: false,
  itemPredecessors: [],
  isFetchingItemPredecessors: false,
  itemEagriRestrictions: undefined,
  isFetchingItemEagriRestrictions: false,
  isExportingData: false,
  isFetchingLandUse: false,
  landUse: [],
  itemSubtractionsGeometry: undefined,
  isFetchingItemSubtractionsGeometry: false,
};

export default (reducerContext?: string) => (
  state = initialState,
  action: AnyAction,
) => {
  const actionContext = action.meta?.context;
  if (actionContext && actionContext !== reducerContext) return state;

  switch (action.type) {
    case types.GET_PARCELS_CROP_REQUEST:
      return {
        ...state,
        isFetchingItems: true,
        itemsError: undefined,
      };
    case types.GET_PARCELS_CROP_SUCCESS:
      return {
        ...state,
        isFetchingItems: false,
        items: action.payload,
        totalCount: Number(action.meta.headers.get('X-Total-Count')),
      };
    case types.GET_PARCELS_CROP_ERROR:
      return {
        ...state,
        isFetchingItems: false,
        itemsError: action.payload,
        totalCount: 0,
      };
    case types.RESET_PARCELS_CROP:
      return {
        ...state,
        items: [],
        totalCount: 0,
      };
    default:
      return state;
  }
};

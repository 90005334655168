import React, { Component, Suspense } from 'react';

import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import forEach from 'lodash/forEach';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import {
  getIsFetchingParcel,
  getParcel,
} from '../../../../../../shared/api/agroevidence/parcels/parcels.selectors';
import { getParcelSowingPlan } from '../../../../../../shared/api/agroevidence/sowingPlan/sowingPlan.selectors';
import {
  getIsFetchingPrecisionParcel,
  getPrecisionParcel,
} from '../../../../../../shared/api/sentinel/precision/precision.selectors';
import { getVariableApplicationStatus } from '../../selectors/parcelDetail.selectors';

import { updateParcelName } from '../../actions/parcelDetail.actions';

import * as satelliteProductsTypes from '../../../../../../shared/constants/satelliteProductsTypes.constants';

import { isParcelSown } from '../../../../../../evidence/actions/others/helpers/others.helpers';
import {
  getParcelApi,
  resetParcelApi,
  getParcelSeedApplicationApi,
  getParcelPredecessorsApi,
  getParcelEagriRestrictionsApi,
} from '../../../../../../shared/api/agroevidence/parcels/parcels.api';
import {
  getParcelSowingPlanApi,
  resetParcelSowingPlanApi,
  updateSeasonsSowingPlanApi,
  resetSeasonsSowingPlanApi,
} from '../../../../../../shared/api/agroevidence/sowingPlan/sowingPlan.api';
import {
  getPrecisionParcelApi,
  resetPrecisionParcelApi,
} from '../../../../../../shared/api/sentinel/precision/precision.api';
import {
  getSatelliteProductsApi,
  resetSatelliteProductsApi,
} from '../../../../../../shared/api/sentinel/satelliteProducts/satelliteProducts.api';
import CfBackButton from '../../../../../../shared/components/common/CfBackButton/CfBackButton';
import CfErrorPage from '../../../../../../shared/components/common/CfErrorPage/CfErrorPage';
import CfLoader from '../../../../../../shared/components/common/CfLoader/CfLoader';
import PageHeader from '../../../../../../shared/components/common/PageHeader/PageHeader';
import ShowHideMap from '../../../../../../shared/components/common/ShowHideMap/ShowHideMap';
import withWidth from '../../../../../../shared/hocs/withWidth';
import {
  ParcelsService,
  SHOWN_NUMBER_SOWING_SEASONS,
  START_YEAR_FIRST_SOWING_SEASONS,
} from '../../../../shared/services/Parcels.service';
import ParcelActivities from '../../../activities/containers/ParcelActivities/ParcelActivities';
import ParcelDetailOverview from '../../../overview/containers/ParcelDetailOverview/ParcelDetailOverview';
import ParcelDetailFabButton from '../../components/ParcelDetailFabButton/ParcelDetailFabButton';
import ParcelDetailHeading from '../../components/ParcelDetailHeading/ParcelDetailHeading';
import ParcelDetailTabs from '../../components/ParcelDetailTabs/ParcelDetailTabs';
import ParcelDetailMap from '../ParcelDetailMap/ParcelDetailMap';

const styles = (theme) => ({
  wrapper: {
    height: '100%',
  },
  headerWithTabs: {
    backgroundColor: theme.palette.common.white,
    borderBottom: '1px solid #EFEFEF',
  },
  headerWrapper: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  bodyWrapper: {
    position: 'relative',
  },
  body: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentWrapper: {
    height: '100%',
  },
  content: {
    padding: theme.spacing(2),
    flexGrow: 1,
    width: '100%',
  },
  mapWrapper: {
    height: '100%',
  },
});

export class ParcelDetail extends Component {
  constructor(props) {
    super(props);
    this.tabs = {
      overview: {
        id: 'overview',
        section: 'ParcelDetailTabs',
        path: 'overview',
      },
      activities: {
        id: 'activities',
        section: 'ParcelDetailTabs',
        path: 'activities',
      },
    };
    this.state = {
      displayMap: this.isDesktop(props.width),
      activeTab: this.tabs.overview.id,
      selectedParcel: null,
      geometries: null,
      mapImage: null,
    };
    this.fetchParcelData(props.match.params.parcelId);
  }

  componentDidMount() {
    this.setTabByPathname(this.props.location.pathname);
  }

  componentDidUpdate(prevProps) {
    const { langId, location, match, width } = this.props;

    if (prevProps.match.params.parcelId !== match.params.parcelId) {
      this.fetchParcelData(this.props.match.params.parcelId);
      this.setMapExtraLayer(null);
    }

    if (langId && langId !== prevProps.langId) {
      this.props.getParcelApi(match.params.parcelId);
    }

    if (width !== prevProps.width) {
      if (
        this.isDesktop(prevProps.width) &&
        !this.isDesktop(width) &&
        this.state.displayMap
      ) {
        this.toggleMap();
      }

      if (
        !this.isDesktop(prevProps.width) &&
        this.isDesktop(width) &&
        !this.state.displayMap
      ) {
        this.toggleMap();
      }
    }

    if (location.pathname !== prevProps.location.pathname) {
      const tabPath = this.tabs[this.state.activeTab].path;
      if (!location.pathname.toLowerCase().includes(tabPath)) {
        this.setTabByPathname(location.pathname);
      }
    }
  }

  componentWillUnmount() {
    this.props.resetParcelApi();
    this.props.resetPrecisionParcelApi();
    this.props.resetParcelSowingPlanApi();
    this.props.resetSeasonsSowingPlanApi();
  }

  setTabByPathname = (pathname) => {
    let tabId = this.tabs.overview.id;
    forEach(this.tabs, (tab) => {
      if (pathname.toLowerCase().includes(tab.path)) {
        tabId = tab.id;
      }
    });

    this.activateTab(tabId);
  };

  setMapExtraLayer = (source, isImage = false) => {
    this.setState({
      geometries: isImage ? null : source,
      mapImage: isImage ? source : null,
    });
  };

  fetchParcelData = (parcelId) => {
    this.props.resetSatelliteProductsApi();
    this.props.getParcelApi(parcelId);
    this.props.getParcelSeedApplicationApi(parcelId, true);
    this.props.getParcelPredecessorsApi(parcelId);
    this.props.getSatelliteProductsApi(parcelId);
    this.props.getPrecisionParcelApi(parcelId).then((res) => {
      if (res?.error) {
        this.props.resetPrecisionParcelApi();
      }
    });
    this.props
      .updateSeasonsSowingPlanApi(
        START_YEAR_FIRST_SOWING_SEASONS,
        SHOWN_NUMBER_SOWING_SEASONS,
      )
      .then(() => {
        this.props.getParcelSowingPlanApi(
          parcelId,
          START_YEAR_FIRST_SOWING_SEASONS,
          SHOWN_NUMBER_SOWING_SEASONS,
        );
      });
    if (
      this.props.farm &&
      this.props.farm.customer &&
      this.props.farm.customer.countryCode === 'CZ'
    ) {
      this.props.getParcelEagriRestrictionsApi(parcelId);
    }
  };

  isDesktop = (width) => ['md', 'lg', 'xl'].indexOf(width) >= 0;

  confirmEditing = (newName) =>
    this.props.updateParcelName(this.props.parcel.id, newName);

  toggleMap = () => {
    this.setState({
      displayMap: !this.state.displayMap,
    });
  };

  goToParcel = (selectedParcel) => {
    const { farm, history, width } = this.props;
    const { activeTab, displayMap } = this.state;
    history.push(
      `/farm/${farm.id}/parcels/${selectedParcel.id}/${this.tabs[activeTab].path}`,
    );
    this.setState({
      selectedParcel,
      displayMap: this.isDesktop(width) ? displayMap : false,
    });
  };

  activateTab(tabId) {
    const { activeTab, displayMap } = this.state;
    const { width } = this.props;
    let displayMapNew = displayMap;
    if (activeTab === this.tabs.activities.id && this.isDesktop(width)) {
      displayMapNew = true;
    }
    if (tabId === this.tabs.activities.id) {
      displayMapNew = false;
    }
    this.setState({
      activeTab: tabId,
      displayMap: displayMapNew,
      geometries: null,
    });
  }

  render() {
    const error = {};
    const {
      classes,
      history,
      isFetchingParcel,
      langId,
      match,
      ngGoToMainMap,
      ngRedirectToEph,
      ngRedirectToHarvest,
      ngRedirectToMowing,
      ngRedirectToOtherActionsNew,
      ngRedirectToSowing,
      ngRedirectToVrf,
      ngRedirectToVrs,
      parcel,
      parcelSowingPlan,
      precisionParcel,
      variableApplicationStatus,
      width,
    } = this.props;

    const isHistorical = ParcelsService.isParcelHistorical(parcel);
    const { farmId, parcelId } = match.params;
    const {
      activeTab,
      displayMap,
      geometries,
      mapImage,
      selectedParcel,
    } = this.state;
    const { countryCode } = this.props.farm.customer;
    const displayContent = !displayMap || (width !== 'xs' && width !== 'sm');
    const isSown = isParcelSown(parcel);

    let parcelColor = null;
    if (!isFetchingParcel && parcel?.seedApplication) {
      parcelColor = parcel.seedApplication.seed.crop.color;
    }

    return (
      <CfErrorPage error={error}>
        <Grid className={classes.wrapper} container>
          {displayContent && (
            <Grid
              className={classes.bodyWrapper}
              item
              md={displayMap ? 8 : 12}
              xs={12}
            >
              <Scrollbars>
                <div className={classes.body}>
                  <div className={classes.headerWithTabs}>
                    <div className={classes.headerWrapper}>
                      <PageHeader
                        actionButtons={
                          <ParcelDetailFabButton
                            isHistorical={isHistorical}
                            isSown={isSown}
                            isVraAllowed={variableApplicationStatus === satelliteProductsTypes.ACTIVE}
                            ngRedirectToEph={ngRedirectToEph}
                            ngRedirectToHarvest={ngRedirectToHarvest}
                            ngRedirectToMowing={ngRedirectToMowing}
                            ngRedirectToOtherActionsNew={ngRedirectToOtherActionsNew}
                            ngRedirectToSowing={ngRedirectToSowing}
                            ngRedirectToVrf={ngRedirectToVrf}
                            ngRedirectToVrs={ngRedirectToVrs}
                            parcelId={parcelId}
                          />
                        }
                        backButton={
                          <CfBackButton onClick={() => history.push(`/farm/${farmId}/parcels`)} translId={'ParcelDetail.backToParcels'} />}
                        classes={{
                          header: classes.header,
                        }}
                        heading={
                          parcel ? (
                            <ParcelDetailHeading
                              confirmEditing={this.confirmEditing}
                              isFetchingParcel={isFetchingParcel}
                              parcel={parcel}
                              selectedParcel={selectedParcel}
                            />
                          ) : null
                        }
                      />
                    </div>
                    {parcel ? (
                      <ParcelDetailTabs
                        activateTab={(tabId) => this.activateTab(tabId)}
                        activeTab={activeTab}
                        farmId={farmId}
                        parcelId={parcel.id}
                        parcelsSection
                        tabs={this.tabs}
                        width={width}
                      />
                    ) : null}
                  </div>
                  <Grid className={classes.contentWrapper} container>
                    <Grid
                      item
                      lg={displayMap ? 'auto' : 1}
                      xl={displayMap ? 'auto' : 2}
                    />
                    <Grid
                      className={classes.content}
                      item
                      lg={displayMap ? 12 : 10}
                      xl={displayMap ? 12 : 8}
                    >
                      {parcel?.id && (
                        <Suspense fallback={<CfLoader />}>
                          <Switch>
                            <Route
                              exact
                              path={`/farm/:farmId/parcels/:parcelId/${this.tabs.overview.path}`}
                              render={() => (
                                <ParcelDetailOverview
                                  countryCode={countryCode}
                                  displayedMap={displayMap}
                                  farmId={farmId}
                                  goToParcel={this.goToParcel}
                                  langId={langId}
                                  parcel={parcel}
                                  parcelSowingPlan={parcelSowingPlan}
                                  precisionParcel={precisionParcel}
                                />
                              )}
                            />
                            <Route
                              exact
                              path={`/farm/:farmId/parcels/:parcelId/${this.tabs.activities.path}`}
                              render={() => (
                                <ParcelActivities
                                  farmId={farmId}
                                  history={history}
                                  langId={langId}
                                  match={match}
                                  parcelId={parcelId}
                                />
                              )}
                            />
                          </Switch>
                        </Suspense>
                      )}
                    </Grid>
                    <Grid
                      item
                      lg={displayMap ? 'auto' : 1}
                      xl={displayMap ? 'auto' : 2}
                    />
                  </Grid>
                </div>
              </Scrollbars>
              {
                <ShowHideMap
                  handleClick={() => this.toggleMap()}
                  isMapHidden={!displayMap}
                />
              }
            </Grid>
          )}
          <Grid
            className={classes.mapWrapper}
            item
            md={4}
            style={{ display: displayMap ? 'block' : 'none' }}
            xs={12}
          >
            {!displayContent && (
              <ShowHideMap
                handleClick={() => this.toggleMap()}
                isMapHidden={false}
              />
            )}
            <ParcelDetailMap
              displayMap={displayMap}
              geometries={geometries}
              goToParcel={this.goToParcel}
              isHistorical={isHistorical}
              mapImage={mapImage}
              ngGoToMainMap={ngGoToMainMap}
              parcelColor={parcelColor}
              parcelId={parcelId}
            />
          </Grid>
        </Grid>
      </CfErrorPage>
    );
  }
}

ParcelDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  langId: PropTypes.string.isRequired,
  farm: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  getPrecisionParcelApi: PropTypes.func.isRequired,
  resetPrecisionParcelApi: PropTypes.func.isRequired,
  getParcelSowingPlanApi: PropTypes.func.isRequired,
  resetParcelSowingPlanApi: PropTypes.func.isRequired,
  updateSeasonsSowingPlanApi: PropTypes.func.isRequired,
  resetSeasonsSowingPlanApi: PropTypes.func.isRequired,
  getParcelApi: PropTypes.func.isRequired,
  getParcelSeedApplicationApi: PropTypes.func.isRequired,
  getParcelPredecessorsApi: PropTypes.func.isRequired,
  getParcelEagriRestrictionsApi: PropTypes.func.isRequired,
  resetParcelApi: PropTypes.func.isRequired,
  resetSatelliteProductsApi: PropTypes.func.isRequired,
  parcel: PropTypes.object,
  precisionParcel: PropTypes.object,
  parcelSowingPlan: PropTypes.array,
  width: PropTypes.string.isRequired,
  updateParcelName: PropTypes.func.isRequired,
  isFetchingParcel: PropTypes.bool.isRequired,
  ngGoToMainMap: PropTypes.func.isRequired,
  ngRedirectToEph: PropTypes.func.isRequired,
  ngRedirectToSowing: PropTypes.func.isRequired,
  ngRedirectToOtherActionsNew: PropTypes.func.isRequired,
  ngRedirectToHarvest: PropTypes.func.isRequired,
  ngRedirectToVrf: PropTypes.func.isRequired,
  ngRedirectToVrs: PropTypes.func.isRequired,
  ngRedirectToMowing: PropTypes.func.isRequired,
  getSatelliteProductsApi: PropTypes.func.isRequired,
  variableApplicationStatus: PropTypes.string,
};

ParcelDetail.defaultProps = {
  parcel: null,
  precisionParcel: null,
  parcelSowingPlan: null,
  variableApplicationStatus: undefined,
};

const mapStateToProps = (state) => ({
  parcel: getParcel(state),
  isFetchingParcel: getIsFetchingParcel(state),
  precisionParcel: getPrecisionParcel(state),
  parcelSowingPlan: getParcelSowingPlan(state),
  isFetchingPrecisionParcel: getIsFetchingPrecisionParcel(state),
  variableApplicationStatus: getVariableApplicationStatus(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getParcelApi,
      getParcelSeedApplicationApi,
      getParcelPredecessorsApi,
      getParcelEagriRestrictionsApi,
      updateParcelName,
      getSatelliteProductsApi,
      resetParcelApi,
      resetSatelliteProductsApi,
      getPrecisionParcelApi,
      resetPrecisionParcelApi,
      getParcelSowingPlanApi,
      resetParcelSowingPlanApi,
      updateSeasonsSowingPlanApi,
      resetSeasonsSowingPlanApi,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(compose(withStyles(styles), withWidth())(ParcelDetail));

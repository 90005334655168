import { useCallback, useEffect } from 'react';

import { Location } from 'history';
import { useDispatch } from 'react-redux';

import { setVisitedTab } from '../../actions/telematicsTabs.actions';

import { API_MODULES } from '../../../shared/api/api.constants';

import { initialDateFilter } from '../../reducer/telematicsTabs.reducer';

import UiCacheService from '../../../shared/services/UiCache.service';

import { Tab } from './TelematicsTabs';

export const getPreviousTelematicsPage = (location: Location, TELEMATICS_URLS: {[key: string]: string;}) => {
  if (location.pathname.includes(TELEMATICS_URLS.drivers)) {
    return 'drivers';
  } else if (location.pathname.includes(TELEMATICS_URLS.machines)) {
    return 'machines';
  }
};

const useTelematicsLogger = (tab: Tab) => {
  const logLastVisitedTab = useLogLastVisitedTab();

  useEffect(() => {
    logLastVisitedTab(tab);
  }, [tab, logLastVisitedTab]);
};

const useLogLastVisitedTab = () => {
  const dispatch = useDispatch();
  const saveToCache = useLocalStorageCache();

  return useCallback((tab: Tab) => {
    dispatch(setVisitedTab(tab));
    saveToCache(tab);
  }, [dispatch, saveToCache]);
};

const useLocalStorageCache = () => useCallback((tab: Tab) => {
  const currentCache = UiCacheService.getCachePerSection(API_MODULES.TELEMATICS) ?? {};
  const futureCache = JSON.parse(JSON.stringify((currentCache)));
  futureCache.telematicsTabs = futureCache.telematicsTabs ?? {};
  futureCache.telematicsTabs.lastVisitedTab = tab;
  futureCache.telematicsTabs.dateFilter = futureCache.telematicsTabs.dateFilter ?? initialDateFilter;
  UiCacheService.storeSettings(API_MODULES.TELEMATICS, futureCache);
}, []);

export { useLogLastVisitedTab, useTelematicsLogger };

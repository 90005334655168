import React, { FC, useEffect } from 'react';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getApiError } from '../../../shared/api/telematics/aggregations/aggregations.selectors';
import { getTelematicsAggregationsTextFilter, getTelematicsAggregationsUnapprovedFilter, getTelematicsAggregationsBulkEditMode } from '../../selectors/telematicsAggregations.selectors';
import { selectDateRange } from '../../selectors/telematicsTabs.selectors';

import { setUnapprovedRidesFilter, setBulkEditMode } from '../../actions/telematicsAggregations.actions';
import { setDateRange } from '../../actions/telematicsTabs.actions';

import { resetApiError } from '../../../shared/api/telematics/aggregations/aggregations.api';
import CfErrorPage from '../../../shared/components/common/CfErrorPage/CfErrorPage';
import { RsaaApiError, Thunk } from '../../../types';
import TelematicsAggregationsTable from '../../components/TelematicsAggregationsTable/TelematicsAggregationsTable';
import { TelematicsNgProps } from '../Telematics/Telematics';

import TableBulkFilters from './TableBulkFilters';
import TableFilters from './TableFilters';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';

interface Props extends TelematicsNgProps {
  apiError: RsaaApiError;
  bulkEditMode: boolean;
  resetApiError: () => void;
  setBulkEditMode: (visible: boolean) => void;
  setUnapprovedRidesFilter: (payload: boolean) => void;
  textFilter: string;
  unapprovedRidesOnly: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(2),
  },
}));

const TelematicsAggregationsList: FC<Props> = ({
  apiError,
  bulkEditMode,
  ngRedirectToMainMapWithFilters,
  resetApiError,
  setBulkEditMode,
  setUnapprovedRidesFilter,
  textFilter,
  unapprovedRidesOnly,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => resetApiError(), []);
  const classes = useStyles();

  return (
    <CfErrorPage error={apiError}>
      <div className={classes.wrapper}>
        {!bulkEditMode && (
          <TableFilters
            setUnapprovedRidesFilter={setUnapprovedRidesFilter}
            textFilter={textFilter}
            unapprovedRidesOnly={unapprovedRidesOnly}
        />
        )}
        {bulkEditMode && (
          <TableBulkFilters />
        )}
        <TelematicsAggregationsTable
          ngRedirectToMainMapWithFilters={ngRedirectToMainMapWithFilters}
          setBulkEditMode={() => setBulkEditMode(true)}
        />
      </div>
    </CfErrorPage>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  dateFilter: selectDateRange(state),
  textFilter: getTelematicsAggregationsTextFilter(state),
  apiError: getApiError(state),
  unapprovedRidesOnly: getTelematicsAggregationsUnapprovedFilter(state),
  bulkEditMode: getTelematicsAggregationsBulkEditMode(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) => bindActionCreators({
  setDateRange,
  resetApiError,
  setUnapprovedRidesFilter,
  setBulkEditMode,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(TelematicsAggregationsList);

import chroma from 'chroma-js';
import _, { isEqual } from 'lodash';
import moment from 'moment';
import { Extent } from 'ol/extent';
import Feature from 'ol/Feature';
import { Point as OlPoint } from 'ol/geom';
import WebGLPointsLayer from 'ol/layer/WebGLPoints';
import VectorSource from 'ol/source/Vector';
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import invariant from 'tiny-invariant';

import ErrorService from '../../../shared/services/Error.service';
import MapService from '../../map/services/Map.service';
import { MAX_COLOR, MIN_COLOR, MIDDLE_COLOR } from '../components/AsApplied/AsAppliedDetail/settings';
import { AS_APPLIED_LAYER_NAME } from '../components/AsApplied/AsAppliedDetail/utils';

import { PrecisionState } from '../../../reducers/precision.reducer.types';
import { TaskDataTimelineTo } from '../../../shared/api/satellite/satellite.types';

type Point = Exclude<ReturnType<typeof selectPoints>, null>[number];
type PointWithAttribute = Exclude<Point, 'attribute'> & { attribute: Exclude<Point['attribute'], undefined> };

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const selectIsFetchingPoints = (state: PrecisionState) => state.api.asApplied.detail.map.isFetching;

const selectIsFetchingItem = (state: PrecisionState) => state.api.asApplied.detail.content.isFetching;

const selectItemError = createSelector(
  (state: PrecisionState) => state.api.asApplied.detail.content.error,
  error => ErrorService.getResErrorDto(error),
);

const selectPoints = (state: PrecisionState) => state.api.asApplied.detail.map.data;

// eslint-disable-next-line max-len
const selectPointsWithAttribute = (state: PrecisionState) => state.api.asApplied.detail.map.data?.filter((point): point is PointWithAttribute => !!point.attribute);

const getChartHighlight = (state: PrecisionState) => (state.ui.precisionAsAppliedDetail.target === 'chart'
  ? state.ui.precisionAsAppliedDetail.highlight
  : undefined);

const selectChartHighlight = createSelector([
  selectPointsWithAttribute,
  getChartHighlight,
], (points, highlight) => points?.findIndex(point => point === highlight));

const selectMinMaxValues = createSelector(selectPointsWithAttribute, pointsWithAttribute => {
  const interval = getInterval(pointsWithAttribute);

  return interval;
});

const selectHighlightPoint = (state: PrecisionState) => {
  const point = state.ui.precisionAsAppliedDetail.highlight;
  return point ? new Feature(new OlPoint(MapService.transformFromWgs([point.longitude, point.latitude]))) : undefined;
};

// eslint-disable-next-line max-len
const selectChartData = createSelector([selectPointsWithAttribute], (pointsWithAttribute) => pointsWithAttribute?.map(point => ({
  point,
  time: moment(point.time).format('HH:mm'),
})));

const selectAttributeUnit = createSelector(selectPointsWithAttribute, pointsWithAttribute => {
  if (!(pointsWithAttribute && pointsWithAttribute.length > 0)) {
    return;
  }

  const pointWithAttributeUnit = pointsWithAttribute.find(point => point.attribute.unit);

  if (!pointWithAttributeUnit) {
    return;
  }

  return pointWithAttributeUnit.attribute.unit;
});

const selectMapData = createSelector([
  selectPointsWithAttribute,
  selectMinMaxValues,
], (points, interval) => {
  if (!(points && points.length > 0)) {
    return;
  }

  const layer = getVectorLayer(points, interval);

  return layer;
});

const _selectExtent = createSelector([selectMapData], layer => layer?.getSource()?.getExtent());

const selectExtent = createDeepEqualSelector([_selectExtent], extent => (isInvalidExtent(extent) ? undefined : extent));

const isInvalidExtent = (extent?: Extent) => extent?.find(corner => Math.abs(corner) === Infinity);

const getVectorLayer = (points: TaskDataTimelineTo[], interval?: [number, number]) => {
  const source = new VectorSource<OlPoint>();
  const features = points.map(point => {
    const geometry = new OlPoint(MapService.transformFromWgs([point.longitude, point.latitude]));
    // eslint-disable-next-line max-len
    const [red, green, blue] = point.attribute && interval ? interpolateColor(point.attribute.value, interval) : [255, 255, 255];
    const feature = new Feature({
      geometry,
      red,
      green,
      blue,
    });
    feature.setProperties({ point });
    feature.set('layerName', AS_APPLIED_LAYER_NAME);

    return feature;
  });

  source.addFeatures(features);

  const layer = new WebGLPointsLayer<VectorSource<OlPoint>>({
    source,
    style: {
      symbol: {
        symbolType: 'circle',
        size: 8,
        color: ['color', ['get', 'red'], ['get', 'green'], ['get', 'blue']],
      },
    },
    zIndex: 50,
  });
  layer.set('name', AS_APPLIED_LAYER_NAME);

  return layer;
};

const getInterval = (points: PointWithAttribute[] | undefined): [number, number] | undefined => {
  if (!(points && points.length > 0)) {
    return;
  }
  const min = _.minBy(points, point => point.attribute.value)?.attribute.value;
  const max = _.maxBy(points, point => point.attribute.value)?.attribute.value;
  invariant(min !== undefined, 'Min value should be defined by now');
  invariant(max !== undefined, 'Max value should be defined by now');

  return [min, max];
};

const interpolateColor = (value: number, interval: [number, number]) => {
  const scale = chroma.scale([MIN_COLOR, MIDDLE_COLOR, MAX_COLOR]).domain(interval);
  return scale(value).rgb(true);
};

export {
  selectAttributeUnit,
  selectChartData,
  selectExtent,
  selectHighlightPoint,
  selectIsFetchingItem,
  selectIsFetchingPoints,
  selectItemError,
  selectMapData,
  selectMinMaxValues,
  selectChartHighlight,
};

export type { PointWithAttribute };

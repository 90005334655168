import React, { FC, useCallback } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { getDriveDetail, getIsFetchingDriveDetail } from '../../../shared/api/telematics/drives/drives.selectors';

import { TELEMATICS_URLS } from '../../telematics.constants';

import CfErrorPage from '../../../shared/components/common/CfErrorPage/CfErrorPage';
import { Thunk } from '../../../types';
import EditBox from '../../components/TelematicsAggregationDetailContent/EditBox';
import { useNewHandworkFormStyles } from '../../components/TelematicsHandwork/style';
import TelematicsHandworkForm from '../../components/TelematicsHandwork/TelematicsHandworkForm';

import { useTelematicsRideDetailStyles } from './TelematicsRideDetail';
import TelematicsRideDetailHeader from './TelematicsRideDetailHeader';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';
import { DrivePartDetailTo } from '../../../shared/api/telematics/telematics.types';

interface Props {
  driveDetail?: DrivePartDetailTo;
  farmId: string;
  isFetchingDriveDetail: boolean;
}

const TelematicsRideHandworkDetail: FC<Props> = ({
  driveDetail,
  farmId,
  isFetchingDriveDetail,
}) => {
  const classes = useTelematicsRideDetailStyles();
  const newHandworkClasses = useNewHandworkFormStyles();

  const history = useHistory();
  const { telematicsId: driveId } = useParams<{ telematicsId: string }>();

  const isNew = driveId === 'new';

  const handleGoBack = useCallback(() => {
    history.push(`/farm/${farmId}/${TELEMATICS_URLS.logbook}`);
  }, [history, farmId]);

  if (isFetchingDriveDetail || (isNew && driveDetail)) {
    return (
      <div className={classes.spinnerWrapper}>
        <CircularProgress color="primary" />
      </div>
    );
  }

  if (!isNew && !driveDetail) return null;

  return (
    <CfErrorPage error={{}}>
      {driveDetail && (
        <Grid className={classes.wrapper} container>
          <Grid className={classes.bodyWrapper} item xs={12}>
            <div className={classes.body}>
              <TelematicsRideDetailHeader
                driveDetail={driveDetail}
                handleGoBack={handleGoBack}
                isNew={isNew}
              />
              <Grid className={newHandworkClasses.form} container>
                <Grid item xs={6}>
                  <EditBox
                    disabled={false}
                    headingId="TelematicsAggregations.detail.section.operation"
                    isApproved={false}
                    isEditing={true}
                    showEditButton={false}
                    visible={true}
                  >
                    <TelematicsHandworkForm
                      approvalValidationErrors={{}}
                      driveDetail={driveDetail}
                      formPath="logbook"
                      handleReset={handleGoBack}
                      handleSave={undefined}
                    />
                  </EditBox>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      )}
    </CfErrorPage>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  driveDetail: getDriveDetail(state),
  isFetchingDriveDetail: getIsFetchingDriveDetail(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {

    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(TelematicsRideHandworkDetail);

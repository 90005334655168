import React, { FC, useEffect } from 'react';

import { ConnectedProps, connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getPrecisionAsAppliedOrder, getPrecisionAsAppliedOrderBy, getPrecisionAsAppliedPage, getPrecisionAsAppliedRowsPerPage, getPrecisionAsAppliedSelected, getPrecisionAsAppliedTextFilter } from '../selectors/precisionAsApplied.selectors';

import { fetchPrecisionAsApplied } from '../actions/precision.actions';

import { NAMESPACE as namespace } from '../reducer/precisionParcelList.reducer';

import CfErrorPage from '../../../shared/components/common/CfErrorPage/CfErrorPage';
import CfTextFilter from '../../../shared/containers/CfTextFilter/CfTextFilter';
import { useDialog } from '../../../shared/hooks/useDialog';
import { RsaaApiError, Thunk } from '../../../types';

import BulkDeleteButton from './BulkDeleteButton';
import DeleteMachinesDialog from './DeleteMachinesDialog';
import PrecisionAsAppliedTable from './PrecisionAsAppliedTable';
import { usePrecisionListStyles } from './styles';

import { PrecisionState } from '../../../reducers/precision.reducer.types';

export interface PrecisionAsAppliedProps {
  error?: RsaaApiError;
  farmId: string;
  langId: string;
  selected: number[];
}

export const PrecisionAsApplied: FC<PrecisionAsAppliedProps & ReduxProps> = ({
  error,
  farmId,
  fetchPrecisionAsApplied,
  langId,
  order,
  orderBy,
  page,
  rowsPerPage,
  selected,
  textFilter,
}) => {
  const classes = usePrecisionListStyles();
  const {
    handleClose: handleDeleteClose, handleOpen: handleDeleteOpen, isOpen: deleteIsOpen,
  } = useDialog();

  useEffect(() => {
    fetchPrecisionAsApplied();
  }, [fetchPrecisionAsApplied, page, rowsPerPage, order, orderBy, textFilter]);

  return (
    <CfErrorPage error={error}>
      <div className={classes.wrapper}>
        <div
          className={classes.textFilter}
          style={{ paddingLeft: '5px' }}
        >
          <BulkDeleteButton
            onClick={handleDeleteOpen}
            selected={selected}
            />
          <CfTextFilter
            initialValue={textFilter}
            name="precision-list-text-filter"
            namespace={namespace}
            translId="PrecisionFarming.search.filter"
            />
        </div>
        <PrecisionAsAppliedTable farmId={farmId} langId={langId} />
      </div>
      {deleteIsOpen &&
      <DeleteMachinesDialog
        handleClose={handleDeleteClose}
        open={deleteIsOpen}
      />}
    </CfErrorPage>
  );
};

const mapStateToProps = (state: PrecisionState) => ({
  textFilter: getPrecisionAsAppliedTextFilter(state),
  page: getPrecisionAsAppliedPage(state),
  order: getPrecisionAsAppliedOrder(state),
  orderBy: getPrecisionAsAppliedOrderBy(state),
  rowsPerPage: getPrecisionAsAppliedRowsPerPage(state),
  selected: getPrecisionAsAppliedSelected(state),
});

const mapDispatchToProps = (dispatch: Thunk<PrecisionState>) =>
  bindActionCreators(
    {
      fetchPrecisionAsApplied,
    },
    dispatch,
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;
export default connector(PrecisionAsApplied);

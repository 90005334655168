export const API_MODULES = {
  GATEWAY: 'gateway',
  CORE: 'core',
  AGROEVIDENCE: 'agroevidence',
  IOT: 'iot',
  STORES: 'stores',
  WEATHER: 'weather',
  SENTINEL: 'sentinel',
  AUTOMATION: 'automation',
  IRRIGATION: 'irrigation',
  TELEMATICS: 'telematics',
};

export const API_METHODS = {
  GET: 'GET',
  PUT: 'PUT',
  POST: 'POST',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

export const CZ_LPIS = 'lpis:pudni_bloky_cur';
export const SK_LPIS = 'lpis:pudni_bloky_sk';

export const CZ_LPIS_PROD = 'farm:pudni_bloky_cur';
export const SK_LPIS_PROD = 'farm:pudni_bloky_sk';

const API_IDENTIFIERS = [
  'GET_PRECISION_TASK_TIMELINE_DATA',
  'GET_PRECISION_TASK_DATA',
] as const;

type API_IDENTIFIER = typeof API_IDENTIFIERS[number];

export type { API_IDENTIFIER };

import React, { useContext, useState } from 'react';

import { FormattedMessage } from 'react-intl';

import { FileLoadItem } from '../../../telematics/components/FileUploader/FileUploader';
import { AsyncFn } from '../../../types';
import { ImportFilesApiType, ValidateFileApiType } from '../../api/sentinel/asAppliedTaskdata/asAppliedTaskdata.api';
import { SnackbarContext } from '../../containers/SnackbarProvider/SnackbarProvider';

import { TaskDataImportResponseFileTo } from '../../api/satellite/satellite.types';

interface Props {
    importFilesApi: (params: ImportFilesApiType) => void;
    validateFileApi: (params: ValidateFileApiType) => void;
  }

const useFileImport = ({
  importFilesApi, validateFileApi,
}: Props) => {
  const showSnackbar = useContext(SnackbarContext);

  const [allFiles, setAllFiles] = useState<FileLoadItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleFilesValidationApi = (newFiles: FileLoadItem[]) => {
    setLoading(prev => !prev);

    const validationPromises = newFiles.map((fileItem) => {
      const params: ValidateFileApiType = {
        file: fileItem.file,
      };

      return new Promise<FileLoadItem>((resolve) => {
        (validateFileApi as AsyncFn<ValidateFileApiType>)(params).then((res) => {
          if ('file' in res.payload) {
            const data = res.payload as TaskDataImportResponseFileTo;

            if (data.error) {
              fileItem.status = 'error';
              fileItem.errorCode = data.error;
            } else {
              fileItem.status = 'validated';
            }
          }
          resolve(fileItem);
        });
      });
    });

    Promise.all(validationPromises)
      .catch(() => {
        showSnackbar({ message: <FormattedMessage id="FileUploader.modal.validation.error" />, isError: true });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFilesImportApi = () => {
    setLoading(prev => !prev);
    const params: ImportFilesApiType = {
      files: allFiles.map(i => i.file),
    };

    (importFilesApi as AsyncFn<ImportFilesApiType>)(params).then((res) => {
      if ('file' in res.payload) {
        const data = res.payload as {file: TaskDataImportResponseFileTo[]};

        const errorIndexes = data.file
          .map((item, index) => ({ error: item.error, index }))
          .filter(item => !!item.error)
          .map(item => item.index);

        const newFilesCopy = [...allFiles];
        newFilesCopy.forEach((__, i) => {
          if (errorIndexes.includes(i)) {
            newFilesCopy[i].status = 'error';
          }
        });

        const result = newFilesCopy.filter(i => i.status === 'error');
        if (!result.length) {
          setSuccess(true);
        } else {
          setAllFiles(result);
        }
      }
    })
      .catch(() => {
        showSnackbar({ message: <FormattedMessage id="FileUploader.modal.import.error" />, isError: true });
      })
      .finally(() => setLoading(false));
  };

  const containsError = () => !!allFiles.find(f => f.status === 'error');

  return {
    allFiles,
    setAllFiles,
    loading,
    success,
    handleFilesImportApi,
    handleFilesValidationApi,
    containsError,
  };
};

export default useFileImport;

import React, { Component } from 'react';

import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { withStyles } from '@mui/styles';
import classnames from 'classnames';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import PropTypes from 'prop-types';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setTableSorting, setTableSelected } from '../../actions/table.actions';

import * as sortOrder from '../../constants/sortOrder.constants';

const styles = theme => ({
  root: {
    color: theme.palette.grey[500],
  },
  rightPadding: {
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(6),
    },
    [theme.breakpoints.down('lg')]: {
      paddingRight: theme.spacing(2),
    },
  },
  superRightPadding: {
    [theme.breakpoints.up('xl')]: {
      paddingRight: '200px',
    },
  },
  desktopOnly: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
});

export class CfTableHead extends Component {
  getCheckboxEl = () => {
    const {
      checked, classes, customSelectHandler, disabledCheckbox, indeterminate, items, selectedOnPage,
    } = this.props;
    const itemsLength = items ? items.length : null;
    const selectedItemsLength = selectedOnPage?.length ?? 0;
    return (
      <TableCell
        padding={'checkbox'}
        style={{ width: '50px' }}
        classes={{
          root: classes.root,
        }}
      >
        <Checkbox
          color="primary"
          data-test="table-checkbox"
          disabled={disabledCheckbox}
          checked={checked !== undefined ? checked
            : selectedItemsLength > 0 && selectedItemsLength === itemsLength}
          indeterminate={indeterminate !== undefined ? indeterminate
            : (selectedItemsLength > 0 && selectedItemsLength < itemsLength)}
          onChange={(e, checked) => {
            if (customSelectHandler) {
              customSelectHandler(checked);
            } else {
              this.handleSelectAll(e, checked);
            }
          }}
        />
      </TableCell>
    );
  };

  handleSelectAll = (evt, checked) => {
    const { itemId, items, namespace, selected, selectedOnPage } = this.props;
    if (checked) {
      this.props.setTableSelected(uniq([...selected, ...items.map(item => item[itemId])]), namespace);
    } else {
      this.props.setTableSelected(difference(selected, selectedOnPage), namespace);
    }
  };

  createSortHandler = property => event => {
    this.handleRequestSort(event, property);
  };

  handleRequestSort = (evt, colId) => {
    const { namespace, order, orderBy } = this.props;
    const newOrder = colId !== orderBy || order === sortOrder.DESC ? sortOrder.ASC : sortOrder.DESC;
    this.props.setTableSorting(newOrder, colId, namespace);
  };

  render() {
    const {
      bulkMode, children, classes, columns, customSortHandler, order, orderBy, selected,
    } = this.props;
    return (
      <TableHead>
        {children}
        <TableRow>
          {Array.isArray(selected) && bulkMode && this.getCheckboxEl()}
          {Object.entries(columns).map(entry => {
            const colId = entry[0];
            const col = entry[1];
            return (
              <TableCell
                align={col.align}
                key={colId}
                size={'small'}
                style={col.style}
                classes={{
                  root: classnames({
                    [classes.rightPadding]: col.rightPadding,
                    [classes.superRightPadding]: col.superRightPadding,
                    [classes.desktopOnly]: col.hideOnMobile,
                    [classes.root]: true }),
                }}
              >
                {col.sortable ? (
                  <TableSortLabel
                    active={orderBy === colId}
                    data-test={`order-${order}`}
                    direction={orderBy === colId ? order : sortOrder.ASC}
                    onClick={customSortHandler ? customSortHandler(colId) : this.createSortHandler(colId)}
                  >
                    {typeof col.label === 'function' ? col.label() : col.label}
                  </TableSortLabel>
                ) : (
                  <span>{typeof col.label === 'function' ? col.label() : col.label}</span>
                )}
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

CfTableHead.propTypes = {
  children: PropTypes.element,
  classes: PropTypes.object.isRequired,
  columns: PropTypes.object.isRequired,
  selected: PropTypes.array,
  selectedOnPage: PropTypes.array,
  items: PropTypes.array,
  itemId: PropTypes.string,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  setTableSorting: PropTypes.func.isRequired,
  setTableSelected: PropTypes.func.isRequired,
  namespace: PropTypes.string,
  customSortHandler: PropTypes.func,
  customSelectHandler: PropTypes.func,
  bulkMode: PropTypes.bool,
  indeterminate: PropTypes.bool,
  checked: PropTypes.bool,
};

CfTableHead.defaultProps = {
  children: null,
  order: null,
  orderBy: null,
  selected: null,
  selectedOnPage: null,
  items: null,
  itemId: 'id',
  namespace: null,
  bulkMode: true,
  indeterminate: undefined,
  checked: undefined,
  disabledCheckbox: undefined,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setTableSorting,
      setTableSelected,
    },
    dispatch,
  );

export default compose(connect(null, mapDispatchToProps), withStyles(styles))(CfTableHead);

import debounce from 'lodash/debounce';
import { formValueSelector } from 'redux-form';

import { getStage, getError } from '../../selectors/editor.selectors';
import { getInteraction, getMainMap, getLayers } from '../../selectors/map.selectors';

import {
  editorSetStage,
  editorHintReopen,
  editorHintClose,
  editorSetDrawingError,
  handleResponse,
  handleGenericError,
  editorClearDrawingError,
} from '../editor/editor.actions';
import {
  deactivateContextMenuEL,
  activateContextMenuEL,
  setMouseClickEL,
  removeEL,
} from '../eventListener/eventListener.actions';
import {
  setLayer,
  removeLayer,
  setDrawIA,
  removeDrawIA,
  setModifyIA,
  removeModifyIA,
  removeDrawingErrorStyle,
  setDrawingErrorStyle,
} from '../interaction/interaction.actions';
import { fetchBoundingBox, mapSetCursor } from '../map/map.actions';
import { addAreaOverlay, removeOverlays } from '../overlay/overlay.actions';

import * as errors from '../../constants/errors.constants';
import * as stages from '../../constants/stages.constants';
import * as tools from '../../constants/tools.constants';
import * as types from './draw.constants';

import { createParcelApi } from '../../../../shared/api/agroevidence/parcels/parcels.api';
import Geometry, { GEOM_TYPES } from '../../services/geometry/Geometry.service';
import GeometryValidation from '../../services/geometry/GeometryValidation.service';
import { STYLE_TYPES } from '../../services/styles/CommonStyles.service';

const selector = formValueSelector('new_parcel');

/*
 * STAGE_1 - tool opened, nothing drawn
 * STAGE_2 - tool opened, parcel drawn,
 * STAGE_3 - tool opened, parcel drawn, reduction drawing
 */

let IS_TOOL_ACTIVE = false;
const PARCEL_IA = 'draw_parcel';
const REDUCTION_IA = 'draw_reduction';
const INVALID_REDUCTION_EL = 'invalidReductionEL';

export const drawStart = () => dispatch => {
  IS_TOOL_ACTIVE = true;
  dispatch(editorSetStage(stages.STAGE_1));

  dispatch(deactivateContextMenuEL());
  dispatch(setLayer(PARCEL_IA, GEOM_TYPES.POLYGON, STYLE_TYPES.DRAWN));
  dispatch(
    setDrawIA(null, geom => dispatch(onParcelDrawEnd(geom)), PARCEL_IA, GEOM_TYPES.POLYGON, STYLE_TYPES.DRAWING),
  );
  dispatch(mapSetCursor(''));
  dispatch(editorHintReopen());
};

export const drawSubmit = () => (dispatch, getState) => {
  const state = getState();
  if (getError(state)) {
    return;
  }

  const geometry = getInteraction(state).getGeometry(PARCEL_IA);
  return dispatch(createParcel(geometry, false)).then(() => dispatch(updateBoundingBox()));
};

export const drawEnd = () => dispatch => {
  IS_TOOL_ACTIVE = false;
  dispatch(activateContextMenuEL());
  dispatch(editorHintClose());

  [PARCEL_IA, REDUCTION_IA].forEach(iaId => {
    dispatch(removeModifyIA(iaId));
    dispatch(removeDrawIA(iaId));
    dispatch(removeLayer(iaId));
  });

  dispatch(removeOverlays());
};

/** PARCEL DRAW/MODIFY ************************************************************ */

const setModifyParcelIA = () => (dispatch, getState) => {
  dispatch(
    setModifyIA(
      geom => {
        dispatch(onParcelModifyChange(geom));
      },
      () => {
        if (!getError(getState())) {
          dispatch(removeReductionDrawIA());
        }
      },
      () => {
        if (!getError(getState())) {
          dispatch(setReductionDrawIA());
        }
      },
      PARCEL_IA,
      GEOM_TYPES.POLYGON,
      STYLE_TYPES.DRAWN,
    ),
  );
};

const removeModifyParcelIA = () => dispatch => {
  dispatch(removeModifyIA(PARCEL_IA));
};

const onParcelDrawEnd = geometry => dispatch => {
  dispatch(removeDrawIA(PARCEL_IA));
  dispatch(onParcelModifyChange(geometry));

  dispatch(setLayer(REDUCTION_IA, GEOM_TYPES.POLYGON, STYLE_TYPES.REDUCTION));
  dispatch(setReductionDrawIA());
  dispatch(setModifyParcelIA());
};

const onParcelModifyChange = geometry => (dispatch, getState) => {
  const state = getState();
  getInteraction(state).clearLayer(REDUCTION_IA);
  dispatch(handleValidation(validateParcel(geometry), PARCEL_IA))
    .then(() => {
      const isEnteringStage = getStage(state) !== stages.STAGE_2;
      if (isEnteringStage) {
        dispatch(editorSetStage(stages.STAGE_2));
        dispatch(editorHintReopen());
      }

      dispatch(editorClearDrawingError());
      debouncedGetParcelIntermediateResult(geometry, dispatch);
    })
    .catch(({ iaId, reason }) => {
      debouncedGetParcelIntermediateResult.cancel();
      dispatch(setDrawingErrorStyle(iaId));
      dispatch(editorSetDrawingError(reason));
    });
};

const getParcelIntermediateResult = geometry => dispatch => {
  dispatch(createParcel(geometry, true))
    .then(handleResponse)
    .then(payload => {
      if (typeof payload.area === 'number' && IS_TOOL_ACTIVE) {
        dispatch(removeOverlays());
        const elementId = 'parcel-area';
        dispatch(addAreaOverlay({ area: payload.area, geometry }, elementId, elementId));
      }
    })
    .catch(payload => dispatch(handleParcelOverlapError(payload)))
    .catch(payload => dispatch(handleGenericError(payload, tools.DRAW)))
    .catch(reason => {
      dispatch(setDrawingErrorStyle(PARCEL_IA));
      dispatch(editorSetDrawingError(reason));
      dispatch(removeReductionDrawIA());
      dispatch(setModifyParcelIA());
    });
};

const debouncedGetParcelIntermediateResult = debounce(
  (geometry, dispatch) => dispatch(getParcelIntermediateResult(geometry)),
  500,
);

/** REDUCTION DRAW/MODIFY ************************************************************ */

const setReductionDrawIA = () => dispatch => {
  dispatch(
    setDrawIA(
      () => dispatch(onReductionDrawStart()),
      geom => dispatch(onReductionDrawEnd(geom)),
      REDUCTION_IA,
      GEOM_TYPES.POLYGON,
      STYLE_TYPES.REDUCTION,
    ),
  );
};

const removeReductionDrawIA = () => dispatch => {
  dispatch(removeDrawIA(REDUCTION_IA));
};

const onReductionDrawStart = () => (dispatch, getState) => {
  getInteraction(getState()).clearLayer(REDUCTION_IA);
  dispatch(removeModifyParcelIA());
  dispatch(editorSetStage(stages.STAGE_3));
};

const onReductionDrawEnd = reductionGeom => (dispatch, getState) => {
  const ia = getInteraction(getState());
  const parcelGeom = ia.getGeometry(PARCEL_IA);

  dispatch(handleValidation(validateReduction(reductionGeom, parcelGeom), REDUCTION_IA, STYLE_TYPES.TRANSPARENT))
    .then(() => {
      const diff = Geometry.getDifference(parcelGeom, reductionGeom);
      return dispatch(handleValidation(validateDifference(diff.geometry), REDUCTION_IA, STYLE_TYPES.TRANSPARENT)).then(
        () => diff.geometry,
      );
    })
    .then(diff => {
      debouncedGetParcelIntermediateResult(diff, dispatch);
      ia.setGeometry(PARCEL_IA, diff);
      getInteraction(getState()).clearLayer(REDUCTION_IA);
      dispatch(enterStage2());
    })
    .then(() => {
      dispatch(editorClearDrawingError());
      dispatch(setModifyParcelIA());
    })
    .catch(({ iaId, reason }) => {
      dispatch(setDrawingErrorStyle(iaId, GEOM_TYPES.POLYGON, STYLE_TYPES.DRAWN_ERROR, false));
      dispatch(editorSetDrawingError(reason));
      dispatch(removeReductionDrawIA());
      dispatch(removeModifyParcelIA());
      dispatch(
        setMouseClickEL(
          () => {
            getInteraction(getState()).clearLayer(REDUCTION_IA);
            dispatch(setReductionDrawIA());
            dispatch(setModifyParcelIA());
            dispatch(removeEL(INVALID_REDUCTION_EL));
            dispatch(editorClearDrawingError());
          },
          () => {},
          INVALID_REDUCTION_EL,
        ),
      );
      debouncedGetParcelIntermediateResult.cancel();
    })
    .finally(() => {
      dispatch(enterStage2());
    });
};

const handleValidation = (promise, iaId, style) => dispatch =>
  promise
    .then(() => {
      dispatch(removeDrawingErrorStyle(iaId, undefined, style));
    })
    .catch(reason => Promise.reject({ reason, iaId }));

const validateParcel = geometry => GeometryValidation.validateParcelGeometry(geometry);

const validateReduction = (reductionGeom, parcelGeom) =>
  GeometryValidation.validateReductionGeometry(reductionGeom, parcelGeom);

const validateDifference = (reductionGeom, parcelGeom) =>
  GeometryValidation.validateDifferenceGeometry(reductionGeom, parcelGeom);

const enterStage2 = () => (dispatch, getState) => {
  const isEnteringStage = getStage(getState()) !== stages.STAGE_2;
  if (isEnteringStage) {
    dispatch(editorSetStage(stages.STAGE_2));
  }
};

export const updateBoundingBox = () => (dispatch, getState) => {
  const mainMap = getMainMap(getState());
  const layers = getLayers(getState());
  return dispatch(fetchBoundingBox(mainMap.getFarmId())).then(bbox => {
    const farmExtent = mainMap.updateBoundingBox(bbox);
    layers.updateParcelLayerExtent(farmExtent);
    layers.updateParcelLabelsExtent(farmExtent);
  });
};

const handleParcelOverlapError = payload => () => {
  const { response } = payload;
  if (
    response.status &&
    response.status === 400 &&
    response.errors &&
    response.errors.find(err => err.code === 'NonIntersectingParcel') !== undefined
  ) {
    return Promise.reject(errors.DRAW_OVERLAP_ERROR);
  }
  return Promise.reject(payload);
};

const createParcel = (geometry, isDryMode = false) => (dispatch, getState) => {
  const state = getState();

  const localName = isDryMode ? 'foo' : selector(state, 'name');
  const landUse = isDryMode ? { id: 2 } : selector(state, 'culture');
  const landUseId = landUse ? landUse.id : null;

  const params = {
    geometry,
    localName,
    landUseId,
  };

  return dispatch(createParcelApi(params, isDryMode));
};

export const setLandUseFilter = landUseFilter => ({
  type: types.DRAW_SET_LANDUSE_FILTER,
  landUseFilter,
});

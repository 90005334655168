import React from 'react';

import { SelectChangeEvent, MenuItem, Select as MUISelect, styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';

type AttributeCode = Pick<typeof attributes[number], 'code'>['code'];

type Props = {
  initialAttribute: AttributeCode;
  onChange: (code: string) => void;
};

const AttributeControl = ({ initialAttribute, onChange }: Props) => {
  const [attribute, setAttribute] = React.useState(initialAttribute);

  const handleChange = ({ target: { value } }: SelectChangeEvent<AttributeCode>) => {
    setAttribute(value as AttributeCode);
    onChange(value);
  };

  return (
    <Wrapper>
      <Select
        disableUnderline
        onChange={handleChange}
        value={attribute}
      >
        {attributes.map(attribute => (
          <MenuItem key={attribute.code} value={attribute.code}>{attribute.label}</MenuItem>
        ))}
      </Select>
    </Wrapper>
  );
};

const attributes = [
  {
    code: '0007',
    label: <FormattedMessage id="AsAppliedDetail.map.attribute.appliedDose" />,
  },
  {
    code: '0006',
    label: <FormattedMessage id="AsAppliedDetail.map.attribute.prescriptionDose" />,
  },
  {
    code: '018D',
    label: <FormattedMessage id="AsAppliedDetail.map.attribute.speed" />,
  },
  {
    code: '0095',
    label: <FormattedMessage id="AsAppliedDetail.map.attribute.fuelConsumption" />,
  },
  {
    code: 'E107',
    label: <FormattedMessage id="AsAppliedDetail.map.attribute.engineSpeed" />,
  },
] as const;

const initialAttributeCode: Pick<typeof attributes[number], 'code'>['code'] = '0007';

const getAttributeByCode = (code: AttributeCode) => attributes.find(attribute => attribute.code === code);

const Wrapper = styled('div')(() => ({
  background: 'white',
  borderRadius: '28px',
  padding: '0px 4px 0px 12px',
}));

const Select = styled(MUISelect)(() => ({
  fontSize: '14px',
}));

export { AttributeControl, initialAttributeCode, getAttributeByCode };
export type { AttributeCode };

/* eslint-disable @typescript-eslint/no-explicit-any */

import { AnyAction, Action } from 'redux';
import { RSAAAction } from 'redux-api-middleware';
import { call, put, takeLatest, Effect, select, take } from 'redux-saga/effects';

import {
  getTelematicsAggregationsTextFilter,
  getTelematicsAggregationsPage,
  getTelematicsAggregationsRowsPerPage,
  getTelematicsAggregationsOrderBy,
  getTelematicsAggregationsOrder,
  getTelematicsAggregationsUnapprovedFilter,
} from '../telematics/selectors/telematicsAggregations.selectors';
import { selectDateRange } from '../telematics/selectors/telematicsTabs.selectors';

import { setRidesStatusUpdate } from '../telematics/actions/telematicsAggregations.actions';

import * as driverAggregationsApiTypes from '../shared/api/telematics/aggregations/aggregations.constants';
import * as telAggregationsTypes from '../telematics/actions/telematicsAggregations.constants';

import { getDriverAggregationsApi } from '../shared/api/telematics/aggregations/aggregations.api';

import { GetAggregatedDriversParams } from '../shared/api/telematics/aggregations/aggregations.types';
import { State } from '../shared/api/telematics/telematics.types';
import { GRRT } from './sagas.types';

function* getApiParams(): Generator<Effect, GetAggregatedDriversParams, any> {
  const { dateFrom, dateTo } = yield select(selectDateRange);
  const textFilter = yield select(getTelematicsAggregationsTextFilter);
  const page = yield select(getTelematicsAggregationsPage);
  const perPage = yield select(getTelematicsAggregationsRowsPerPage);
  const sortCol = yield select(getTelematicsAggregationsOrderBy);
  const sortDir = yield select(getTelematicsAggregationsOrder);
  const unapprovedOnly = yield select(getTelematicsAggregationsUnapprovedFilter);

  return {
    dateFrom,
    dateTo,
    driver: textFilter !== '' ? textFilter : undefined,
    page: page + 1,
    'per-page': perPage,
    'sort-col': sortCol,
    'sort-dir': sortDir,
    ...(unapprovedOnly && { state: State.NOT_APPROVED }),
  };
}

function* fetchDrivesSaga(action: AnyAction): Generator<Effect, void, any> {
  let refetch = false;
  if (action?.refetch) {
    refetch = action.refetch;
  }
  const params: GRRT<typeof getApiParams> = yield call(getApiParams);
  yield put(getDriverAggregationsApi(params, refetch) as unknown as Action<RSAAAction>);
  yield take(driverAggregationsApiTypes.GET_DRIVER_AGGREGATIONS_SUCCESS);
  yield put(setRidesStatusUpdate([]));
}

export default function* TelematicsSaga(): Generator<Effect, void, any> {
  yield takeLatest(telAggregationsTypes.FETCH_AGGREGATED_DRIVES, fetchDrivesSaga);
}

import { AnyAction } from 'redux';
import { RSAAAction } from 'redux-api-middleware';

import { getPrecisionAsAppliedOrder, getPrecisionAsAppliedPage, getPrecisionAsAppliedRowsPerPage } from '../selectors/precisionAsApplied.selectors';

import { SET_TABLE_SELECTED } from '../../../shared/actions/table.constants';
import * as types from '../../../shared/api/sentinel/precision/precision.constants';

import { getTaskdataApi } from '../../../shared/api/sentinel/asAppliedTaskdata/asAppliedTaskdata.api';
import { getPrecisionParcelsApi, resetPrecisionParcelsApi } from '../../../shared/api/sentinel/precision/precision.api';

import { PrecisionState } from '../../../reducers/precision.reducer.types';

export const fetchPrecisionParcels = () => (
  dispatch: (action: RSAAAction) => void,
) => {
  dispatch(getPrecisionParcelsApi());
};

export const resetPrecision = () => (
  dispatch: (action: AnyAction) => void,
) => {
  dispatch(resetPrecisionParcelsApi());
};

export const fetchPrecisionAsApplied = () => (
  dispatch: (action: RSAAAction) => void,
  getState: () => PrecisionState,
) => {
  const state = getState();

  const params = {
    'sort-dir': getPrecisionAsAppliedOrder(state),
    page: getPrecisionAsAppliedPage(state) + 1,
    'per-page': getPrecisionAsAppliedRowsPerPage(state),
    // search: getTelematics...TextFilter(state),
  };

  dispatch(getTaskdataApi(params));
};

export const setOpenedPrecisionAsAppliedRows = (payload: number[]) => ({
  type: types.SET_OPENED_PRECISION_AS_APPLIED_ROWS,
  payload,
});

export const resetSelectedAsApplied = () => ({
  type: SET_TABLE_SELECTED,
  selected: [],
});

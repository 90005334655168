import React from 'react';

import { FormikProps } from 'formik';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { editBatchDriver } from '../../actions/telematicsAggregations.actions';

import { stringDateToMoment } from '../../../shared/misc/timeHelpers';
import { CfFormikErrors } from '../../../types';
import { DriverField } from '../../components/TelematicsAggregationDetailContent/formComponents';
import { MESSAGES } from '../../components/TelematicsAggregationDetailContent/validators';

import { useCustomStyles } from './styles/useCustomStyles';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';
import { DriverTo } from '../../../shared/api/telematics/telematics.types';

const useDriver = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const customClasses = useCustomStyles();

  const [selectedDriver, setSelectedDriver] = React.useState<DriverTo | null | undefined>(undefined);

  const selectedRides = useSelector((state: TelematicsState) => state.ui.telematicsAggregations.selectedRides);
  const dateFrom = useSelector((state: TelematicsState) => state.ui.telematicsTabs.dateFilter.dateFrom);
  const dateFromMoment = stringDateToMoment(dateFrom);
  let dateForClassifiers: string;
  if (dateFromMoment) {
    dateForClassifiers = dateFromMoment.toISOString() ?? moment().startOf('day').toISOString();
  }

  const initialValues = {
    driverCode: '',
  };

  const validateContentForm = (values: typeof initialValues) => {
    const errors: CfFormikErrors<typeof initialValues> = {};

    if (!values.driverCode) {
      errors.driverCode = MESSAGES.required;
    }
    return errors;
  };

  const onSubmit = () => {
    const payload = { drive: selectedRides, driver: selectedDriver?.id ?? -1 };
    return editBatchDriver(payload)(dispatch);
  };

  const component = (formikProps: FormikProps<typeof initialValues>) => (
    <DriverField
      customClasses={customClasses}
      date={dateForClassifiers}
      disabled={formikProps.isSubmitting}
      placeholder={intl.formatMessage({ id: 'TelematicsAggregations.list.bulk.secondary.placeholder.driver' })}
      setSelectedDriver={setSelectedDriver}
      {...formikProps}
    />
  );

  return {
    initialValues,
    validateContentForm,
    onSubmit,
    component,
  };
};

export default useDriver;

import { PointWithAttribute } from '../selectors/asAppliedDetail.selectors';

type InitialState = Record<string, never>;
type DefinedState = {
  highlight: PointWithAttribute;
  target: 'map' | 'chart';
};

type State = |
  DefinedState
| InitialState;

type Action = |
  {
    payload: State,
    type: 'SET_HIGHLIGHT_POINT',
  } |
  {
    type: 'RESET_HIGHLIGHT_POINT',
  };

const initialState: State = {};

const precisionAsAppliedDetailReducer = () => (state = initialState, action: Action): State => {
  switch (action.type) {
    case 'SET_HIGHLIGHT_POINT': {
      if (state.highlight === action.payload.highlight && state.target === action.payload.target) {
        return state;
      }

      return {
        ...action.payload,
      };
    }
    case 'RESET_HIGHLIGHT_POINT': {
      if (!state.highlight) {
        return state;
      }
      return {};
    }
    default:
      return state;
  }
};

const pointHighlighted = (point: DefinedState): Action => ({
  type: 'SET_HIGHLIGHT_POINT',
  payload: point,
});

const pointReset = (): Action => ({
  type: 'RESET_HIGHLIGHT_POINT',
});

export default precisionAsAppliedDetailReducer;
export { pointHighlighted, pointReset };
export type { Action, State };

import React from 'react';

import Map from 'ol/Map';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import { ConnectedProps, connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { PointWithAttribute } from '../../../selectors/asAppliedDetail.selectors';

import { Action, pointHighlighted, pointReset } from '../../../reducer/precisionAsAppliedDetail.reducer';

import SelectMapPositionService from '../../../../../shared/services/SelectMapPosition.service';

import { isAsAppliedFeature } from './utils';

import { PrecisionState } from '../../../../../reducers/precision.reducer.types';

type Props = React.HTMLAttributes<HTMLDivElement> & { map?: Map } & ConnectedProps<typeof connector>;

const _ClickOverlay = ({
  map,
  resetHighlight,
  setHighlight,
}: Props) => {
  const mapPositionServiceRef = React.useRef<SelectMapPositionService>();

  if (!map) {
    return null;
  }

  if (!mapPositionServiceRef.current) {
    mapPositionServiceRef.current = new SelectMapPositionService(map);
    mapPositionServiceRef.current.setClickMapCallback((e: MapBrowserEvent<UIEvent>) => {
      const features = map.getFeaturesAtPixel(e.pixel, {
        hitTolerance: 5,
      });
      const asAppliedFeature = features.find(isAsAppliedFeature);

      if (!asAppliedFeature) {
        resetHighlight();
      } else {
        setHighlight(asAppliedFeature.getProperties().point);
      }
    });
  }

  return null;
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PrecisionState, unknown, Action>) =>
  bindActionCreators({
    setHighlight: (highlight: PointWithAttribute) => dispatch(pointHighlighted({ highlight, target: 'chart' })),
    resetHighlight: () => dispatch(pointReset()),
  }, dispatch);

const connector = connect(undefined, mapDispatchToProps);
const ClickOverlay = connector(_ClickOverlay);

export { ClickOverlay };

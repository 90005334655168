import { AnyAction } from 'redux';

import * as commonFilterTypes from '../../shared/actions/filter.constants';
import * as commonTableTypes from '../../shared/actions/table.constants';
import * as sortOrder from '../../shared/constants/sortOrder.constants';
import * as types from '../actions/telematicsAggregations.constants';

import { TelematicsAggregations } from '../telematics.types';

export const NAMESPACE = 'telematicsAggregations';

export default (reducerNamespace = NAMESPACE) => (
  state: TelematicsAggregations = {
    page: 0,
    rowsPerPage: 10,
    order: sortOrder.ASC,
    orderBy: 'duration',
    textFilter: '',
    unapprovedRidesOnly: false,
    openedDriverRows: [],
    ridesStatusUpdate: [],
    bulkEditMode: false,
    selected: [],
    selectedRides: [],
  },
  action: AnyAction,
) => {
  const { namespace } = action;
  if (namespace && namespace !== reducerNamespace) return state;

  switch (action.type) {
    case commonTableTypes.SET_TABLE_PAGE:
      return {
        ...state,
        page: action.page,
        openedDriverRows: [],
      };
    case commonTableTypes.SET_TABLE_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
        page: 0,
        openedDriverRows: [],
      };
    case commonTableTypes.SET_TABLE_ORDER:
      return {
        ...state,
        order: action.order,
        page: 0,
        openedDriverRows: [],
      };
    case commonTableTypes.SET_TABLE_ORDER_BY:
      return {
        ...state,
        orderBy: action.orderBy,
        page: 0,
        openedDriverRows: [],
      };
    case commonFilterTypes.SET_TEXT_FILTER:
      return {
        ...state,
        textFilter: action.textFilter,
        page: 0,
        openedDriverRows: [],
      };
    case commonTableTypes.SET_TABLE_SELECTED:
      return {
        ...state,
        selected: action.selected,
      };
    case commonTableTypes.SET_TABLE_SUBROW_SELECTED:
      return {
        ...state,
        selectedRides: action.selected,
      };
    case types.SET_UNAPPROVED_RIDES_FILTER: {
      return {
        ...state,
        unapprovedRidesOnly: action.payload,
        openedDriverRows: [],
      };
    }
    case types.SET_OPENED_DRIVER_ROWS: {
      return {
        ...state,
        openedDriverRows: [...action.payload],
      };
    }
    case types.SET_BULK_EDIT_MODE: {
      return {
        ...state,
        bulkEditMode: action.payload,
      };
    }
    case types.SET_RIDES_STATUS_UPDATE: {
      return {
        ...state,
        ridesStatusUpdate: [...action.payload],
      };
    }
    default:
      return state;
  }
};

import React from 'react';

import { Box } from '@mui/material';
import { ConnectedProps, connect } from 'react-redux';
import { Dispatch } from 'redux';

import { Action, pointReset } from '../../../reducer/precisionAsAppliedDetail.reducer';

import SectionWrapper from '../../../../../shared/components/specific/SectionWrapper/SectionWrapper';
import { useIsMobile } from '../../../../../shared/hooks/useWidth';
import MapService from '../../../../map/services/Map.service';

import { AttributeCode, AttributeControl, initialAttributeCode } from './AttributeControl';
import { Chart } from './Chart';
import { Map } from './Map';

type ReduxProps = ConnectedProps<typeof connector>;

const _MapWrapper = ({ resetHighlight }: ReduxProps) => {
  const [attributeCode, setAttributeCode] = React.useState(initialAttributeCode);
  const isMobile = useIsMobile();

  const handleResizeMap = (map: MapService) => {
    map.updateSize();
  };

  const handleAttributeCodeChange = (code: AttributeCode) => {
    setAttributeCode(code);
    resetHighlight();
  };

  return (
    <Map
      attributeCode={attributeCode}
      onResize={handleResizeMap}
    >
      <SectionWrapper left={56} top={17}>
        <AttributeControl
          initialAttribute={initialAttributeCode}
          onChange={(code: AttributeCode) => handleAttributeCodeChange(code)}
        />
      </SectionWrapper>
      <SectionWrapper bottom={17} left={14} right={14}>
        {isMobile ? null : (
          <Box style={{ background: 'white', borderRadius: '4px', width: '100%', height: '175px' }}>
            <Chart attributeCode={attributeCode} />
          </Box>
        )}
      </SectionWrapper>
    </Map>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  resetHighlight: () => dispatch(pointReset()),
});

const connector = connect(undefined, mapDispatchToProps);
const MapWrapper = connector(_MapWrapper);

export { MapWrapper };

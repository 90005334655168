import React, { FC } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { Checkbox, IconButton, TextField, Tooltip } from '@mui/material';
import classNames from 'classnames';
import { Field } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';

import CfBackButton from '../../../../../shared/components/common/CfBackButton/CfBackButton';
import PageHeader from '../../../../../shared/components/common/PageHeader/PageHeader';
import PageHeading from '../../../../../shared/components/common/PageHeading/PageHeading';
import * as validators from '../../../../../shared/misc/validators';
import { useFertilizerDetailStyles } from '../FertilizerDetail.styles';

interface Props {
  canUpdate?: boolean;
  defaultIsFavorite: boolean;
  defaultName: string;
  error: boolean;
  handleFavoriteClick: () => void;
  handleGoBack: () => void;
  handleStartEdit: () => void;
  isEditing: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const FertilizerDetailHeader: FC<Props> = ({
  canUpdate = false,
  defaultIsFavorite,
  defaultName,
  error = false,
  handleFavoriteClick,
  handleGoBack,
  handleStartEdit,
  isEditing,
  onChange,
}) => {
  const classes = useFertilizerDetailStyles();
  const intl = useIntl();

  return (
    <PageHeader
      actionButtons={
        <Tooltip
          followCursor
          title={canUpdate ? (
            ''
          ) : (
            <span>
              <FormattedMessage
                id="Catalogues.fertilizers.detail.editOff.tooltip"
                values={{
                  br: <br />,
                  b: (chunks: string) => <b>{chunks}</b>,
                }} />
            </span>
          )}
        >
          {/* https://github.com/mui/material-ui/issues/8416 */}
          <div>
            <IconButton
              disabled={!canUpdate}
              onClick={handleStartEdit}
              size="large"
            >
              {canUpdate ? <EditIcon /> : <EditOffIcon />}
            </IconButton>
          </div>
        </Tooltip>
        }
      backButton={
        <CfBackButton
          onClick={handleGoBack}
          translId={'Catalogues.fertilizers.detail.backToFertilizers'}
          />
        }
      heading={
        <PageHeading
          customClass={classes.heading}
          value={
            <>
              <Tooltip
                placement="bottom"
                title={
                  <FormattedMessage
                    id={defaultIsFavorite ?
                      'Catalogues.fertilizers.detail.removeFromFavorites.tooltip' :
                      'Catalogues.fertilizers.detail.addToFavorites.tooltip'
                    }
                  />
                }
              >
                <Checkbox
                  checked={defaultIsFavorite}
                  checkedIcon={<StarRoundedIcon />}
                  color="secondary"
                  icon={<StarBorderRoundedIcon />}
                  onClick={handleFavoriteClick}
                />
              </Tooltip>
              <Field
                component={TextField}
                disabled={!isEditing}
                error={error}
                helperText={error ? <FormattedMessage id="validation.required" /> : ''}
                name="name"
                onChange={onChange}
                placeholder={intl.formatMessage({ id: 'Catalogues.fertilizers.detail.name' })}
                validate={validators.required}
                value={defaultName}
                InputProps={{
                  classes: {
                    root: classNames(
                      classes.textField,
                      { [classes.headingContentConfirming]: !isEditing,
                        [classes.headingValidationError]: error }),
                    input: classes.headingFontSize,
                  },
                }}
              />
            </>
            }
          />
        }
      />
  );
};

export default FertilizerDetailHeader;

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export interface BatchCropSowingPlanCreate {
  parcelId: string;
  cropId?: string;
  seasonId: string;
}

export interface BulkSowingPlanParcelCropBody {
  parcelCrops: BatchCropSowingPlanCreate[];
}

export interface SowingPlanParcelCropBody {
  cropId?: string;
  seasonId: string;
}

export enum EagriUpdateMode {
  DRY = "DRY",
  BLOCKS = "BLOCKS",
  BLOCKS_AND_PARCELS = "BLOCKS_AND_PARCELS",
  ALL = "ALL",
}

export interface EagriFertilizerKindTo {
  /** @format int32 */
  id: number;
  name?: string;
}

export enum EagriFertilizerNitrogenCategory {
  NON_NITROUS = "NON_NITROUS",
  MINERAL = "MINERAL",
  QUICK_RELEASE = "QUICK_RELEASE",
  SLOW_RELEASE = "SLOW_RELEASE",
  SLUDGE = "SLUDGE",
  PLANT_RESIDUES = "PLANT_RESIDUES",
  SEDIMENTS = "SEDIMENTS",
  EXCIPIENTS = "EXCIPIENTS",
}

export interface EagriFertilizerNitrogenCategoryTo {
  code: EagriFertilizerNitrogenCategory;
  name?: string;
}

export interface EagriFertilizerPropertiesCreateTo {
  /** @format int32 */
  eagriId?: number;
  registrationNumber: string;
  evidenceNumber: string;
  applicantTradeName?: string;
  isExcrement: boolean;
  nitrogenCategory: EagriFertilizerNitrogenCategoryTo;
  /** @format double */
  nitrogenCoefficient: number;
  sort?: string;
  registrationType: EagriFertilizerRegistrationTypeTo;
  /** @format double */
  price?: number;
  fertilizerKind: EagriFertilizerKindTo;
  isEco: boolean;
  /** @format date */
  validFrom?: string;
  /** @format date */
  validTo?: string;
  ordinance?: string;
}

export interface EagriFertilizerRegistrationTypeTo {
  code: string;
  name?: string;
}

export interface FertilizerCreateTo {
  name: string;
  description?: string;
  producerTradeName?: string;
  isHidden: boolean;
  isOrganic: boolean;
  /** @format double */
  n: number;
  /** @format double */
  p2o5: number;
  /** @format double */
  k2o: number;
  /** @format double */
  mgo: number;
  /** @format double */
  cao: number;
  /** @format double */
  na2o: number;
  /** @format double */
  s: number;
  /** @format double */
  chlorides: number;
  /** @format double */
  combustibleContent: number;
  /** @format double */
  zn: number;
  /** @format double */
  cu: number;
  /** @format double */
  fe: number;
  /** @format double */
  b: number;
  /** @format double */
  mn: number;
  /** @format double */
  mo: number;
  /** @format double */
  se: number;
  /** @format double */
  phFrom: number;
  /** @format double */
  phTo: number;
  /** @format double */
  electricConductivity: number;
  unitType: UnitType;
  /**
   * Volume to mass conversion coefficient, used only when unit is in volume units (m3, l)
   * @format double
   * @example 0.5
   */
  unitConversionCoefficient: number;
  eagriProperties?: EagriFertilizerPropertiesCreateTo;
}

export enum UnitType {
  WEIGHT = "WEIGHT",
  VOLUME = "VOLUME",
}

export enum CatalogueRole {
  OWNER = "OWNER",
  EDITOR = "EDITOR",
  VIEWER = "VIEWER",
}

export interface CatalogueTo {
  id: string;
  type: CatalogueType;
  role: CatalogueRole;
  canUpdate: boolean;
}

export enum CatalogueType {
  EAGRI = "EAGRI",
  GLOBAL = "GLOBAL",
  PRIVATE = "PRIVATE",
}

export interface EagriFertilizerPropertiesDetailTo {
  /** @format int32 */
  eagriId?: number;
  registrationNumber?: string;
  evidenceNumber?: string;
  applicantTradeName?: string;
  isExcrement: boolean;
  nitrogenCategory: EagriFertilizerNitrogenCategoryTo;
  /** @format double */
  nitrogenCoefficient: number;
  sort?: string;
  isValid: boolean;
  registrationType?: EagriFertilizerRegistrationTypeTo;
  /** @format double */
  price?: number;
  fertilizerType?: string;
  fertilizerKind?: EagriFertilizerKindTo;
  isEco: boolean;
  /** @format date */
  validFrom?: string;
  /** @format date */
  validTo?: string;
  ordinance?: string;
}

export interface FertilizerDetailTo {
  id: string;
  name: string;
  catalogue: CatalogueTo;
  isHidden: boolean;
  producerTradeName?: string;
  description?: string;
  isOrganic: boolean;
  /** @format double */
  n: number;
  /** @format double */
  p2o5: number;
  /** @format double */
  k2o: number;
  /** @format double */
  mgo: number;
  /** @format double */
  cao: number;
  /** @format double */
  na2o: number;
  /** @format double */
  s: number;
  /** @format double */
  chlorides: number;
  /** @format double */
  combustibleContent: number;
  /** @format double */
  zn: number;
  /** @format double */
  cu: number;
  /** @format double */
  fe: number;
  /** @format double */
  b: number;
  /** @format double */
  mn: number;
  /** @format double */
  mo: number;
  /** @format double */
  se: number;
  /** @format double */
  phFrom: number;
  /** @format double */
  phTo: number;
  /** @format double */
  electricConductivity: number;
  unitType: UnitType;
  /** @format double */
  unitConversionCoefficient: number;
  eagriProperties?: EagriFertilizerPropertiesDetailTo;
  isFavorite: boolean;
  producer?: string;
  /** @format double */
  nitrogen: number;
  /** @format double */
  sulfur: number;
}

export interface ActionExpenseTo {
  id?: string;
  /** @format double */
  amount: number;
  unit: string;
  /** @format double */
  dosePerHa: number;
  notes?: string;
}

export interface ActionParcelCreateTo {
  parcelId: string;
  restrictions?: RestrictionTo[];
}

export enum ActionSource {
  TELEMATICS = "TELEMATICS",
  MANUAL = "MANUAL",
  EAGRI = "EAGRI",
  UNKNOWN = "UNKNOWN",
}

export interface ActionSowingCreateTo {
  id?: string;
  note?: string;
  /** @format date */
  date: string;
  /** @uniqueItems true */
  parcels: ActionParcelCreateTo[];
  seedExpense: Seed;
  cropUseType?: EagriCropUseType;
  seedApplicationType: SeedApplicationType;
  isDraft: boolean;
  source: ActionSource;
}

export enum EagriCropUseType {
  Value1 = "1",
  Value2 = "2",
  Value3 = "3",
  Value4 = "4",
  Value6 = "6",
  Value7 = "7",
}

export interface RestrictionTo {
  type: RestrictionType;
  /** @format double */
  value: number;
}

export enum RestrictionType {
  Absolute = "Absolute",
  Boundary = "Boundary",
  Water = "Water",
}

export interface Seed {
  actionMaterial: SeedMat;
  actionExpense?: ActionExpenseTo;
  materialId?: string;
}

export enum SeedApplicationType {
  CATCH_CROP = "CATCH_CROP",
  MAIN_CROP = "MAIN_CROP",
}

export interface SeedMat {
  id?: string;
  materialId: string;
}

export interface ActionIdResponseTo {
  id: string;
}

export interface ActionOtherCreateTo {
  id?: string;
  note?: string;
  /** @format date */
  date: string;
  /** @uniqueItems true */
  parcels: ActionParcelCreateTo[];
  actionType: ActionType;
  isDraft: boolean;
  source: ActionSource;
}

export enum ActionType {
  PLOW = "PLOW",
  TILLAGE = "TILLAGE",
  SOWING = "SOWING",
  SOWING_PERENNIAL = "SOWING_PERENNIAL",
  MOWING = "MOWING",
  HARVEST = "HARVEST",
  SPRAY = "SPRAY",
  SPREADING_PEST = "SPREADING_PEST",
  FERT = "FERT",
  LIMING = "LIMING",
  FERT_ORG = "FERT_ORG",
  FERT_IND = "FERT_IND",
  TANKMIX = "TANKMIX",
  HOE = "HOE",
  TRANSPORT = "TRANSPORT",
  PREP = "PREP",
  PASTURE = "PASTURE",
  DISK = "DISK",
  HARROW = "HARROW",
  ROLL = "ROLL",
  IRRIG = "IRRIG",
  HILL = "HILL",
  CULTIV = "CULTIV",
  FOLIAR = "FOLIAR",
  MULCHING = "MULCHING",
  SUBSOILING = "SUBSOILING",
  RAKING = "RAKING",
  REVERSAL = "REVERSAL",
  CRUSHING = "CRUSHING",
  SKIDDING = "SKIDDING",
  SUB_PLOWING = "SUB_PLOWING",
  PLOUGHING = "PLOUGHING",
  COMPACTING = "COMPACTING",
}

export interface ActionMowingCreateTo {
  id?: string;
  note?: string;
  /** @format date */
  date: string;
  /** @uniqueItems true */
  parcels: ActionParcelCreateTo[];
  revenue: RevenueCreateTo;
  isDraft: boolean;
  source: ActionSource;
}

export interface RevenueCreateTo {
  /** @format double */
  amount: number;
  /** @format int32 */
  productLegislativeCode?: number;
  isMainProduct: boolean;
}

export interface ActionHarvestCreateTo {
  id?: string;
  note?: string;
  /** @format date */
  date: string;
  /** @uniqueItems true */
  parcels: ActionParcelCreateTo[];
  revenues: RevenueCreateTo[];
  isDraft: boolean;
  source: ActionSource;
}

export interface ActionEPHCreateTo {
  id?: string;
  note?: string;
  /** @format date */
  date: string;
  /** @uniqueItems true */
  parcels: ActionParcelCreateTo[];
  targetCropId: string;
  isStrawDecay: boolean;
  fertilizers: EPH[];
  plantProtections: ExpensePlantProtectionCreateTo[];
  isDraft: boolean;
  source: ActionSource;
}

export enum DriftClass {
  DRIFT90 = "DRIFT_90",
  DRIFT75 = "DRIFT_75",
  DRIFT50 = "DRIFT_50",
  DRIFT_NONE = "DRIFT_NONE",
}

export interface EPH {
  actionMaterial: EPHMat;
  actionExpense: ActionExpenseTo;
  materialId?: string;
}

export interface EPHMat {
  id?: string;
  materialId: string;
  /** @format date */
  dateSoilIncorporation?: string;
  driftClass?: DriftClass;
}

export interface ExpensePlantProtectionCreateTo {
  actionMaterial: EPHMat;
  actionExpense: ActionExpenseTo;
  /** @uniqueItems true */
  targetOrganismsIds: number[];
  parcelPlantProtectionEffectiveness: ParcelPlantProtectionEffectivenessTo[];
}

export interface ParcelPlantProtectionEffectivenessTo {
  parcelId: string;
  effectiveness: PlantProtectionEffectiveness;
}

export enum PlantProtectionEffectiveness {
  YES = "YES",
  NO = "NO",
  NOT_SPECIFIED = "NOT_SPECIFIED",
}

export interface GeoJsonMultiPoint {
  type: GeoJsonType;
  coordinates: number[][];
}

export enum GeoJsonType {
  Point = "Point",
  MultiPoint = "MultiPoint",
  LinearRing = "LinearRing",
  LineString = "LineString",
  MultiLineString = "MultiLineString",
  Polygon = "Polygon",
  MultiPolygon = "MultiPolygon",
  GeometryCollection = "GeometryCollection",
}

export interface GeoJson {
  type: GeoJsonType;
  coordinates: object[];
}

export interface GeoJsonPolygon {
  type: GeoJsonType;
  coordinates: number[][][];
  geometries: GeoJson[];
}

export interface InternalParcelTo {
  id: string;
  name: string;
  blockNumber: string;
  square?: string;
  geometry?: GeoJsonPolygon;
  /** @format double */
  area?: number;
  /** @format int32 */
  countyUnitId?: number;
  countyName?: string;
  /** @format int32 */
  cropLegislativeCode?: number;
  cropName?: string;
  cropSeedAppType: SeedApplicationType;
  centerKey?: string;
  /** @format date */
  validFrom?: string;
  /** @format date */
  validTo?: string;
}

export interface InternalTelematicsActionCreate {
  /** @format date */
  date: string;
  /** @uniqueItems true */
  parcels: ActionParcelCreateTo[];
  actionType: ActionType;
  source: ActionSource;
  isDraft: boolean;
}

export interface SowingPlanSeasonTo {
  id: string;
  /** @format int32 */
  startYear: number;
  /** @format int32 */
  endYear: number;
}

export interface ActionTypeTo {
  code: ActionType;
  name: string;
}

export interface ColorTo {
  /** @format int32 */
  red: number;
  /** @format int32 */
  green: number;
  /** @format int32 */
  blue: number;
  /** @format int32 */
  asDecimal: number;
}

export interface CropGroupTo {
  /** Crop group legislative id / external id */
  id: string;
  name?: string;
  /** @format int32 */
  legislativeCode: number;
}

export interface CropTo {
  id: string;
  name: string;
  /** @format int32 */
  externalId: number;
  catalogueType: CatalogueType;
  group?: CropGroupTo;
  /** @format date */
  validFrom?: string;
  /** @format date */
  validTo?: string;
  color: ColorTo;
  textColor: ColorTo;
  pfCode?: string;
  /** @format int32 */
  legislativeCode: number;
}

export interface ParcelNameResponse {
  id: string;
  name: string;
}

export interface PorUseReportMissingDataBody {
  actionIdsMissingTargetCrop: PorValidationErrorActionResponse[];
  actionsWithInvalidUnit: PorValidationErrorActionResponse[];
  actionsWithInvalidOrganisms: PorValidationErrorActionResponse[];
  actionsWithInvalidEffectiveness: PorValidationErrorActionResponse[];
}

export interface PorValidationErrorActionResponse {
  actionId: string;
  /** @format date */
  date: string;
  actionType: ActionTypeTo;
  targetCrop?: CropTo;
  materials: PorValidationErrorMaterialResponse[];
}

export interface PorValidationErrorMaterialResponse {
  materialId: string;
  name: string;
  unit: string;
  /** @format double */
  totalDose: number;
  /** @format double */
  dosePerHa: number;
  /** @uniqueItems true */
  parcels?: ParcelNameResponse[];
}

export interface ValidationError {
  detailedMessage: PorUseReportMissingDataBody;
  /** @format int32 */
  status: number;
  error: string;
  detail: string;
  trace?: string;
}

export interface ReportIsAlreadyRunning {
  farmId?: string;
  type?: ReportType;
  /** @format int32 */
  status: number;
  error: string;
  detail: string;
  trace?: string;
  detailedMessage?: object;
}

export enum ReportType {
  POR_USE = "POR_USE",
  FERTILIZER_USE = "FERTILIZER_USE",
}

export interface EagriReportError {
  /** @format int32 */
  status: number;
  error: string;
  detail: string;
  trace?: string;
  detailedMessage?: object;
}

export interface EagriReportResponse {
  guid: string;
}

export enum EagriFertilizerUseReportType {
  STATISTICS = "STATISTICS",
  CHECK = "CHECK",
}

export interface EagriReportBody {
  /** @format date-time */
  from: string;
  /** @format date-time */
  to: string;
  type: EagriFertilizerUseReportType;
}

export interface FertilizerUseReportMissingDataBody {
  /** @uniqueItems true */
  actionIdsMissingUsage: number[];
  /** @uniqueItems true */
  actionIdsMissingProduct: number[];
}

export interface MissingData {
  detailedMessage: FertilizerUseReportMissingDataBody;
  /** @format int32 */
  status: number;
  error: string;
  detail: string;
  trace?: string;
}

export interface InvalidDateRange {
  /** @format int32 */
  status: number;
  error: string;
  detail: string;
  trace?: string;
  detailedMessage?: object;
}

export enum EagriImportMode {
  BLOCKS = "BLOCKS",
  BLOCKS_AND_PARCELS = "BLOCKS_AND_PARCELS",
  ALL = "ALL",
}

export interface SubtractionTo {
  type: RestrictionType;
  /** @format double */
  value: number;
  /** @format double */
  area: number;
}

export interface ZoneCreateTo {
  name: string;
}

export interface ZoneDetailTo {
  id: string;
  name: string;
  /** @format int32 */
  parcelCount: number;
  /** @format double */
  totalArea: number;
}

export interface MaterialApplicationRestrictionTo {
  plantProtectionId: string;
  /** @uniqueItems true */
  targetOrganismIds: number[];
  targetCropId: string;
  driftClass: DriftClass;
}

/** All parcels for action have to be sent. Even if there is no restriction for them. */
export interface ParcelRecountSubtractionTo {
  parcelId: string;
  restrictions: RecountSubtractionTo[];
}

export interface RecountSubtractionRequest {
  /** All parcels for action have to be sent. Even if there is no restriction for them. */
  restrictions: ParcelRecountSubtractionTo[];
  materials: MaterialApplicationRestrictionTo[];
}

export interface RecountSubtractionTo {
  /** @format double */
  value: number;
  type: RestrictionType;
  isMaterial: boolean;
  isUsed: boolean;
}

export interface ParcelSubtractionResponse {
  parcelId: string;
  subtractions: SubtractionResponse[];
}

export interface SubtractionResponse {
  type: RestrictionType;
  /** @format double */
  value: number;
  /** @format double */
  area: number;
  isMaterial: boolean;
  isUsed: boolean;
}

export interface ParcelRestrictionRequest {
  parcelId: string;
  restrictions: RestrictionTo[];
}

export interface ParcelGeometryResponse {
  parcelId: string;
  geometry: GeoJson;
}

export interface CenterCreateTo {
  key: string;
  name: string;
}

export interface CenterTo {
  id: string;
  key: string;
  name: string;
}

export interface ManualParcelCreateRequest {
  /** @format int32 */
  landUseId: number;
  localName: string;
  geometry: GeoJsonPolygon;
  /** @format date */
  validFrom: string;
}

export interface ManualParcelCreateResult {
  /** @format int32 */
  landUseId: number;
  /** @format double */
  area: number;
  localName: string;
  geometry: GeoJsonPolygon;
  parcelId?: string;
}

export interface EagriAuthDataValidationTo {
  login: string;
  secret: string;
  szrid: string;
}

export interface EagriAuthValidationSuccessTo {
  farmName?: string;
}

export interface CropSeedCreateTo {
  cropId: string;
  varietyName?: string;
  varietyId?: string;
  note?: string;
}

export interface CropSeedTo {
  id: string;
  catalogue: CatalogueTo;
  isHidden: boolean;
  varietyName?: string;
  crop: CropTo;
  name: string;
  /** @format int32 */
  legislativeCode: number;
}

export enum CatalogueSource {
  EAGRI = "EAGRI",
  EXT_GLOBAL = "EXT_GLOBAL",
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
}

export interface MaterialBufferTo {
  type: MaterialBufferType;
  driftClass: DriftClass;
  /** @format double */
  value: number;
}

export enum MaterialBufferType {
  Boundary = "Boundary",
  Water = "Water",
}

export interface PlantProtectionApplicationCropTo {
  /** @format int32 */
  legislativeCode: number;
  name: string;
}

export interface PlantProtectionApplicationDosageTo {
  buffers: MaterialBufferTo[];
  /** @format double */
  dosageMin?: number;
  /** @format double */
  dosageMax?: number;
  dosageUnit?: string;
  dosageDescription?: string;
  /** @format double */
  waterDosageMin?: number;
  /** @format double */
  waterDosageMax?: number;
  waterDosageUnit?: string;
  /** @format int32 */
  withdrawalPeriodDays?: number;
  withdrawalPeriodText?: string;
  /** @format int32 */
  minCropBBCH?: number;
  /** @format int32 */
  maxCropBBCH?: number;
  /** @format int32 */
  minOrganismBBCH?: number;
  /** @format int32 */
  maxOrganismBBCH?: number;
  nextCropRestriction?: string;
  /** @format int32 */
  applicationCount?: number;
  /** @format double */
  slope?: number;
  /** @format double */
  waterBuffer?: number;
  /** @format double */
  parcelBuffer?: number;
  /** @format double */
  waterProtectionDistance?: number;
  /** @format int32 */
  repeatedApplicationMinDays?: number;
  /** @format int32 */
  repeatedApplicationMaxDays?: number;
  /** @format int32 */
  protectionPeriod?: number;
  protectionZone?: boolean;
  undersowing?: boolean;
  description?: string;
  comment?: string;
  isDeleted?: boolean;
  mixtureId?: string;
  drift?: string;
  mandatoryDosage?: string;
  seedTreatment?: boolean;
}

export interface PlantProtectionApplicationOrganismTo {
  /** @format int32 */
  legislativeCode: number;
  name: string;
}

export interface PlantProtectionApplicationTo {
  id?: string;
  validForAllCrops: boolean;
  crops: PlantProtectionApplicationCropTo[];
  organisms: PlantProtectionApplicationOrganismTo[];
  dosage: PlantProtectionApplicationDosageTo[];
}

export interface PlantProtectionDetailTo {
  id?: string;
  name: string;
  catalogue?: CatalogueTo;
  /** @format int32 */
  legislativeCode: number;
  source: CatalogueSource;
  isDeleted: boolean;
  registrationNumber?: string;
  authorizationHolder?: string;
  allowed?: boolean;
  bioFunction?: string;
  substance?: PlantProtectionSubstanceTo;
  eko?: boolean;
  protectionZoneIIExcluded?: boolean;
  /** @format date */
  legalStart?: string;
  /** @format date */
  legalEnd?: string;
  /** @format date */
  marketEnd?: string;
  /** @format date */
  useEnd?: string;
  airApplication?: boolean;
  comment?: string;
  applications: PlantProtectionApplicationTo[];
  edited: boolean;
  unit?: string;
  description?: string;
  /** @format double */
  priceUnit?: number;
  labelId?: string;
  providerId?: string;
  isHidden: boolean;
}

export interface PlantProtectionSubstanceTo {
  name?: string;
  integratedVineyard?: boolean;
  integratedOrchards?: boolean;
  integratedVegetables?: boolean;
  unit?: string;
  /** @format double */
  amount?: number;
}

export interface AdminFarmManagementTo {
  farmId: string;
  farmName?: string;
  response: object;
}

export interface AdminNitrateVulnerableZoneTo {
  lpisBlockId: string;
  parcelId: string;
  parcelName?: string;
  isNitrateVulnerable: boolean;
}

export interface AdminActionMoveResult {
  okFarms: number[];
  nokFarms: number[];
}

export interface EmptyFarmCatalogueFillResult {
  ok: number[];
  nok: number[];
}

export interface SplitActionRequest {
  actionId: string;
  parcelsToSplit: number[];
}

export interface SplitActionResponse {
  originalActionId: string;
  splitActionId: string;
}

export enum NitrateDirectiveVersion {
  NITRATEDIRECTIVE2015 = "NITRATE_DIRECTIVE_2015",
  NITRATEDIRECTIVE2016 = "NITRATE_DIRECTIVE_2016",
  NITRATEDIRECTIVE2020 = "NITRATE_DIRECTIVE_2020",
  NITRATE_DIRECTIVE_ALL = "NITRATE_DIRECTIVE_ALL",
}

export enum RestrictionCode {
  EagriCodeRestrictions = "EagriCodeRestrictions",
  GrasslandsFertilizationRestriction = "GrasslandsFertilizationRestriction",
  SlopeWaterFertilizationRestriction = "SlopeWaterFertilizationRestriction",
  SlopeWaterSowingRestriction = "SlopeWaterSowingRestriction",
}

export interface ValidationResponseTo {
  valid: boolean;
  restrictions: ValidationResultTo[];
}

export interface ValidationResultTo {
  description: string;
  code: RestrictionCode;
  version: NitrateDirectiveVersion;
  violated: boolean;
  violations: ViolationTo[];
}

export enum ViolationSeverity {
  ERROR = "ERROR",
  WARNING = "WARNING",
}

export interface ViolationTo {
  code: string;
  message: string;
  text: string;
  parcelId?: string;
  severity: ViolationSeverity;
}

export interface CenterAssignTo {
  centerId?: string;
}

export interface ParcelUpdateTo {
  name?: string;
  userYieldGrade?: YieldGrade;
  zoneIds?: number[];
  center?: CenterAssignTo;
  notes?: string;
}

export enum YieldGrade {
  I = "I",
  II = "II",
  III = "III",
}

export interface CropSeedPatchTo {
  varietyName?: string;
  isHidden?: boolean;
}

export interface PlantProtectionUpdateTo {
  name?: string;
  registrationNumber?: string;
  authorizationHolder?: string;
  allowed?: boolean;
  bioFunction?: string;
  substance?: PlantProtectionSubstanceTo;
  eko?: boolean;
  protectionZoneIIExcluded?: boolean;
  /** @format date */
  legalStart?: string;
  /** @format date */
  legalEnd?: string;
  /** @format date */
  marketEnd?: string;
  /** @format date */
  useEnd?: string;
  airApplication?: boolean;
  comment?: string;
  applications?: PlantProtectionApplicationTo[];
  unit?: string;
  description?: string;
  /** @format double */
  priceUnit?: number;
  labelId?: string;
  providerId?: string;
}

export interface FertilizerPatchTo {
  isFavorite?: boolean;
  isHidden?: boolean;
}

export interface InternalSeasonTo {
  /** @format int32 */
  startYear: number;
  cropId: string;
  /** @format int32 */
  cropLegislativeCode: number;
  cropName: string;
}

export interface PagingFilter {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  "per-page"?: number;
  "sort-col"?: string;
  "sort-dir"?: string;
  /** @format int32 */
  pageOffset: number;
  /** @format int32 */
  pageSize?: number;
  sortProperty?: string;
  isAscending: boolean;
  language?: string;
  /** @format int32 */
  pageNumber: number;
}

export interface InternalSeedApplicationTo {
  /** @format date */
  dateStart: string;
  /** @format date */
  dateEnd?: string;
  seedTo: CropSeedTo;
}

export enum MaterialType {
  FERTILIZER = "FERTILIZER",
  PLANT_PROTECTION = "PLANT_PROTECTION",
  SEED = "SEED",
}

export interface InternalMaterialTo {
  id: string;
  type: MaterialType;
  name?: string;
  /** @format int32 */
  externalId?: number;
}

export interface InternalFarmResponse {
  id: string;
  name: string;
  countryCode?: string;
}

export interface InternalActionConsumptionNote {
  storeTransactionId: string;
  note: string;
}

export interface SowingPlanCropStatisticsOverview {
  /** @format int32 */
  parcelsCount: number;
  crop?: CropTo;
  /** @format double */
  area: number;
}

export interface SowingPlanOverviewParcelTo {
  seasonId: string;
  parcelId: string;
  /** @format double */
  area: number;
  crop?: CropTo;
}

export interface SowingPlanSeasonStatisticsTo {
  parcels: SowingPlanOverviewParcelTo[];
  statistics: SowingPlanCropStatisticsOverview[];
}

export enum LocalLanguage {
  DEFAULT = "DEFAULT",
  EN = "EN",
  CZ = "CZ",
  SRB = "SRB",
  ES = "ES",
  RU = "RU",
  GR = "GR",
  RO = "RO",
}

export enum ParcelStatus {
  OPEN = "OPEN",
  RETIRED = "RETIRED",
}

export enum LandUse {
  ARABLE = "ARABLE",
  HOPFIELD = "HOPFIELD",
  VINEYARD = "VINEYARD",
  OTHER_PERMANENT = "OTHER_PERMANENT",
  ORCHARD = "ORCHARD",
  GRASS = "GRASS",
  OTHER = "OTHER",
  SET_ASIDE = "SET_ASIDE",
  FODDER = "FODDER",
  UNPRODUCTIVE = "UNPRODUCTIVE",
  NURSERY = "NURSERY",
  VEGETABLES = "VEGETABLES",
  POND = "POND",
  FAST_GROW = "FAST_GROW",
  WOODED = "WOODED",
}

export interface ParcelWithSowingPlansTo {
  id: string;
  blockNumber: string;
  localName: string;
  /** @format double */
  area: number;
  /** @format double */
  lpisBlockArea: number;
  seedApplication?: SeedApplicationTo;
  /** @uniqueItems true */
  zones?: ZoneTo[];
  seasons: SowingPlanSeasonCropTo[];
}

export interface SeedApplicationTo {
  id: string;
  seedingActionId: string;
  seed: CropSeedTo;
  type: SeedApplicationType;
  /** @format date */
  startDate: string;
  /** @format date */
  endDate?: string;
  cropUseType?: EagriCropUseType;
  terminalActionId?: string;
}

export interface SowingPlanSeasonCropTo {
  season: SowingPlanSeasonTo;
  crop?: CropTo;
}

export interface ZoneTo {
  id: string;
  name: string;
}

export enum FileType {
  PDF = "PDF",
  XLSX = "XLSX",
}

export type ReportErrorBody = PorUseReportMissingDataBody | FertilizerUseReportMissingDataBody;

export interface ReportHistoryResponse {
  id: string;
  type: ReportType;
  /** @format date-time */
  started: string;
  /** @format date-time */
  finished?: string;
  success?: boolean;
  /** @format date */
  periodFrom: string;
  /** @format date */
  periodTo: string;
  note?: string;
  jsonNote?: ReportErrorBody;
}

export interface Aplikace {
  typ: string;
  zkod: string;
  ctverec: string;
  /** @format date-time */
  datAplikaceZahajeni: string;
  /** @format date-time */
  datZapraveniUkonceni?: string;
  idPozemek?: string;
  /** @format int32 */
  plodina?: number;
  vymeraPlodiny: number;
  vymeraAplikace: number;
  mnozstviCelkem?: number;
  mnozstviHa?: number;
  mernaJednotka?: string;
  /** @format int32 */
  hnojivoId?: number;
  hnojivoNazev?: string;
  metodaZivin?: string;
  privodN?: number;
  privodP?: number;
  privodK?: number;
  privodMg?: number;
  privodCa?: number;
  privodS?: number;
  rozkladSlamy?: boolean;
  chyby?: TChyby;
}

export interface Applications {
  aplikace: Aplikace[];
}

export interface Chyba {
  /** @format int32 */
  kod?: number;
  zavaznost: string;
  popis: string;
}

export interface Osev {
  zkod: string;
  ctverec: string;
  idPozemek?: string;
  /** @format int32 */
  plodina?: number;
  /** @format int32 */
  uzitkovySmerPestovani?: number;
  meziplodina?: boolean;
  vymera: number;
  /** @format date-time */
  datVysev: string;
  /** @format date-time */
  datZapraveni?: string;
  chyby?: TChyby;
  sklizne?: Sklizne;
  predplodiny?: Predplodiny;
}

export interface Osevy {
  osev: Osev[];
}

export interface Predplodina {
  idPozemek?: string;
  /** @format int32 */
  plodina?: number;
  /** @format int32 */
  uzitkovySmerPestovani?: number;
  vymera: number;
  chyby?: TChyby;
}

export interface Predplodiny {
  predplodina: Predplodina[];
}

export interface ProduktHlavni {
  /** @format date-time */
  datSklizen: string;
  /** @format int32 */
  produkt?: number;
  vynos: number;
  /** @format int32 */
  obsahSusiny?: number;
}

export interface ProduktVedlejsi {
  /** @format date-time */
  datSklizen: string;
  /** @format int32 */
  produkt?: number;
  vynos?: number;
  /** @format int32 */
  obsahSusiny?: number;
}

export interface Response {
  timeStamp?: TimeStamp;
  responseHeader?: ResponseHeader;
  responseContent: ResponseContent;
  sid?: string;
  vokoid?: string;
  srvid?: SRVid;
}

export interface ResponseContent {
  response?: TResponse;
}

export interface ResponseHeader {
  requestID: string;
}

export interface SRVid {
  serverID?: string;
}

export interface Sklizen {
  produktHlavni?: ProduktHlavni;
  produktVedlejsi?: ProduktVedlejsi;
  chyby?: TChyby;
}

export interface Sklizne {
  sklizen: Sklizen[];
}

export interface TChyby {
  chyba: Chyba[];
}

export interface TResponse {
  /** @format date-time */
  datPrijeti: string;
  idPodani: string;
  stavPodani: string;
  typ: string;
  /** @format date-time */
  obdobiOd: string;
  /** @format date-time */
  obdobiDo: string;
  stredisko?: string;
  rozsah?: string;
  chyby?: TChyby;
  osevy?: Osevy;
  aplikace?: Applications;
}

export interface TimeStamp {
  /** @format date-time */
  value?: string;
  type?: string;
}

/** Parcel list item */
export interface ParcelTo {
  id: string;
  blockNumber: string;
  localName: string;
  /** @format double */
  area: number;
  /** @format double */
  lpisBlockArea: number;
  nitrateVulnerable: boolean;
  /** @format date */
  validFrom: string;
  /** @format date */
  validTo?: string;
  status: ParcelStatus;
  zone?: ZoneTo;
  center?: CenterTo;
  landUseName: string;
  seedApplication?: SeedApplicationTo;
  /** @format double */
  accountableNitrogen: number;
}

export interface ParcelCropDetailTo {
  id: string;
  blockNumber: string;
  localName: string;
  /** @format double */
  area: number;
  /** @format double */
  lpisBlockArea: number;
  seedApplication?: SeedApplicationTo;
  zone?: ZoneTo;
  seasons?: SowingPlanSeasonCropTo[]
}

export interface SeedApplicationTo {
  id: string;
  seedingActionId: string;
  seed: CropSeedTo;
  type: SeedApplicationType;
  /** @format date */
  startDate: string;
  /** @format date */
  endDate?: string;
  cropUseType?: EagriCropUseType;
  terminalActionId?: string;
}

export interface ZoneTo {
  id: string;
  name: string;
}

/**
 * Parcel application zone from LPIS
 * @example "IIIa"
 */
export enum ApplicationZone {
  I = "I",
  II = "II",
  IIIa = "IIIa",
  IIIb = "IIIb",
}

export interface FertilizationTo {
  /**
   * Kilos of nitrogen per ha that will be absorbed by soil. Used for nitrate restrictions.
   * @format double
   * @example 20
   */
  accountableNitrogen: number;
  /**
   * Kilos of nitrogen per ha
   * @format double
   * @example 20
   */
  nitrogen: number;
  /**
   * Kilos of p2o5 per ha
   * @format double
   * @example 20
   */
  p2o5: number;
  /**
   * Kilos of k2o per ha
   * @format double
   * @example 20
   */
  k2o: number;
  /** @format double */
  mgo: number;
  /** @format double */
  cao: number;
  /** @format double */
  sulfur: number;
}

export interface LpisBlockTo {
  /** @format double */
  area: number;
  landUse: LandUse;
  /** Designated parcel land use localized name */
  landUseName: string;
  yieldGrade?: YieldGrade;
  userYieldGrade?: YieldGrade;
  /** Parcel application zone from LPIS */
  applicationZone?: ApplicationZone;
  /**
   * Bonitiated soil ecological unit = BPEJ
   * @example "4.19.11"
   */
  soilEcologicalUnit?: string;
  /**
   * Average parcel altitude
   * @min 0
   */
  altitude?: number;
  /**
   * Average parcel slope
   * @min 0
   */
  slope?: number;
  /**
   * Minimal parcel water distance
   * @min 0
   */
  waterDistance?: number;
}

/** Parcel detail */
export interface ParcelDetailTo {
  id: string;
  blockNumber: string;
  localName: string;
  /** @format double */
  area: number;
  geometry: GeoJsonPolygon;
  status: ParcelStatus;
  nitrateVulnerable: boolean;
  /** @format date */
  validFrom: string;
  /** @format date */
  validTo?: string;
  notes?: string;
  zone?: ZoneTo;
  center?: CenterTo;
  lpisBlock: LpisBlockTo;
  seedApplication?: SeedApplicationTo;
  fertilization: FertilizationTo;
  /** @uniqueItems true */
  eagriRestrictions: string[];
}

export interface ParcelSeedApplicationTo {
  id: string;
  seedingActionId: string;
  seed: CropSeedTo;
  type: SeedApplicationType;
  /** @format date */
  startDate: string;
  /** @format date */
  endDate?: string;
  cropUseType?: EagriCropUseType;
  terminalActionId?: string;
  fertilization: FertilizationTo;
  /** @format double */
  primaryRevenueHa?: number;
  /** @format double */
  secondaryRevenueHa?: number;
}

export interface ParcelPredecessorTo {
  id: string;
  localName: string;
  blockNumber?: string;
  lastCrop?: CropTo;
}

export interface EagriNitrateRestrictionTo {
  code: string;
  description: string;
}

export interface EagriSubsidyTo {
  square: string;
  blockNr: string;
  /** @format double */
  declaredArea: number;
  /** @format int32 */
  subsidyTypeId: number;
  /** @format date */
  from: string;
  /** @format date */
  to?: string;
  /** @format int32 */
  subsidyTitleId: number;
  /** @format int32 */
  cropId?: number;
  code: string;
  description: string;
}

export interface ParcelEagriInfoTo {
  /** @format int32 */
  aekoYear?: number;
  aeko: EagriSubsidyTo[];
  nitrate: EagriNitrateRestrictionTo[];
}

/** Parcel suggestions list item */
export interface ParcelSuggestionTo {
  id: string;
  blockNumber: string;
  localName: string;
  status: ParcelStatus;
  /** @format date */
  validTo?: string;
}

export interface CropStatisticTo {
  /** @format int32 */
  legislativeCode: number;
  name: string;
  /** @format int32 */
  count: number;
  /** @format double */
  totalArea: number;
  color: ColorTo;
  textColor: ColorTo;
  cropId: string;
}

export interface FarmStatisticsTo {
  crops: CropStatisticTo[];
  parcels: ParcelStatisticsTo;
}

export interface ParcelStatisticsTo {
  /** @format int32 */
  count: number;
  /** @format double */
  totalArea: number;
}

export interface LandUseResponse {
  key: LandUse;
  name: string;
}

export interface FarmTo {
  id: string;
  code: string;
  name?: string;
  countryCode: string;
  timeZone: string;
  /** @format date-time */
  createdOn?: string;
  createdBy?: string;
  boundingBox?: GeoJsonPolygon;
  /** @format date-time */
  latestUpdate?: string;
}

export interface FarmUpdateErrorFlatResponse {
  block?: string;
  parcelId?: string;
  geometryToRepair?: GeoJson;
  conflictingGeometry?: GeoJson;
}

export interface FarmUpdateFlatResponse {
  farmUpdateId: string;
  source: UpdateSource;
  state: FarmUpdateState;
  /** @format date-time */
  startedAt: string;
  /** @format date-time */
  updatedAt?: string;
  /** @format date-time */
  lastSuccessUpdateDate?: string;
  errorMessage?: string;
  code?: string;
  shortCode?: string;
  conflictingBlocks?: string[];
  ambiguousParcels?: number[];
  newParcelsFromUpdate?: number[];
  farmUpdateErrorFlatResponse: FarmUpdateErrorFlatResponse[];
}

export enum FarmUpdateState {
  IN_PROGRESS = "IN_PROGRESS",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}

export enum UpdateSource {
  EMPTY_FARM = "EMPTY_FARM",
  EAGRI = "EAGRI",
  SHP = "SHP",
}

export interface InternalFarm {
  id: string;
  code: string;
  name?: string;
  countryCode: string;
}

export interface DeprecatedFarmTo {
  id: string;
  code: string;
  name?: string;
  timeZone: string;
  /** @format date-time */
  createdOn?: string;
  createdBy?: string;
  customer: FarmCustomerTo;
  boundingBox?: GeoJsonPolygon;
}

export interface FarmCustomerTo {
  id: string;
  schemaName: string;
  countryCode: string;
}

export interface PlantProtectionTo {
  id?: string;
  name: string;
  catalogue?: CatalogueTo;
  /** @format int32 */
  legislativeCode: number;
  source: CatalogueSource;
  isDeleted: boolean;
  registrationNumber?: string;
  authorizationHolder?: string;
  allowed?: boolean;
  labelId?: string;
  isHidden: boolean;
}

export interface EagriFertilizerPropertiesTo {
  nitrogenCategory: EagriFertilizerNitrogenCategoryTo;
  isValid: boolean;
  /** @format date */
  validFrom?: string;
  /** @format date */
  validTo?: string;
}

export interface FertilizerTo {
  id: string;
  name: string;
  catalogue?: CatalogueTo;
  isHidden: boolean;
  producerTradeName?: string;
  isOrganic: boolean;
  /** @format double */
  n: number;
  /** @format double */
  p2o5: number;
  /** @format double */
  k2o: number;
  /** @format double */
  mgo: number;
  /** @format double */
  cao: number;
  /** @format double */
  s: number;
  isFavorite: boolean;
  eagriProperties?: EagriFertilizerPropertiesTo;
}

export interface EnumEntriesEagriFertilizerNitrogenCategory {
  empty?: boolean;
}

export interface FertilizerMineralIntakeResponse {
  /** @format double */
  nitrogenIntake: number;
  /** @format double */
  p2o5Intake: number;
  /** @format double */
  k2oIntake: number;
}

export interface FertilizerSuggestionTo {
  id: string;
  name: string;
  catalogue?: CatalogueTo;
  isHidden: boolean;
  producerTradeName?: string;
  isFavorite: boolean;
}

export interface HarvestProductTo {
  /** @format int32 */
  legislativeCode: number;
  description: string;
}

export interface CropUseTypeTo {
  /** @format int32 */
  legislativeCode: number;
  description: string;
  cropUseType: EagriCropUseType;
}

export interface AdminFarmTo {
  id: string;
  name?: string;
  countryCode?: string;
}

export interface FarmUserTo {
  userId: string;
}

export interface AekoUdajeType {
  aekokod: string;
  hodnotanum?: number;
  /** @format int32 */
  hodnotaint?: number;
  hodnotastr?: string;
  /** @format date-time */
  platnostod: string;
  /** @format date-time */
  platnostdo?: string;
}

export interface AplPasmoType {
  /** @format int32 */
  aplpaskod?: number;
  vymprekryv: number;
  /** @format date-time */
  platnostod: string;
  /** @format date-time */
  platnostdo?: string;
}

export interface AzzpType {
  /** @format int32 */
  kod?: number;
  souradnicex: string;
  souradnicey: string;
}

export interface BpejType {
  bpejkod: string;
  vymprekryv: number;
  /** @format date-time */
  platnostod: string;
  /** @format date-time */
  platnostdo?: string;
}

export interface ChybaType {
  kodchyby: string;
  popis: string;
}

export interface ChybyType {
  chyba: ChybaType[];
}

export interface DpbType {
  chyba?: string;
  chyby?: ChybyType;
  /** @format int32 */
  iddpb?: number;
  ctverec: string;
  zkod: string;
  stav: string;
  /** @format int32 */
  stavid?: number;
  /** @format date-time */
  ucinnostdlezakona?: string;
  /** @format date-time */
  platnostod: string;
  /** @format date-time */
  platnostdo?: string;
  vymera: number;
  vymeraopv: number;
  kultura: string;
  /** @format int32 */
  kulturaid?: number;
  kulturanazev: string;
  /** @format date-time */
  kulturaod?: string;
  geometriedpb: string;
  uzivatel: UzivatelType;
  zemparcely?: ZemParcelyType[];
  zakladni?: ZakladniType[];
  prekryvkatuze?: PrekryvKatuzeType[];
  seznamevp?: SeznamEvpType[];
  udajens?: UdajeNsType[];
  opatrenins?: OpatreniNsType[];
  aplpasmo?: AplPasmoType[];
  vynoshladina?: VynosHladinaType[];
  udajeeroze?: UdajeErozeType[];
  opatrenieroze?: OpatreniErozeType[];
  opatrenimeo?: OpatreniMeoType[];
  lfa?: LfaType[];
  opv?: OpvType[];
  opvz?: OpvzType[];
  bpej?: BpejType[];
  efas?: EfasType[];
  mzp?: MzpType[];
  enviro?: EnviroType[];
  aekoudaje?: AekoUdajeType[];
  gmo?: GmoType[];
  azzp?: AzzpType[];
  eroze2G?: Eroze2GType[];
  eroznipozemek?: EroznipozemekType[];
}

export interface EagriDpbPair {
  subsidyDpb: DpbType;
  detail?: DpbType;
}

export interface EfasType {
  efaskod: string;
  vymefas: number;
  vymefasprep: number;
  /** @format date-time */
  platnostod: string;
  /** @format date-time */
  platnostdo?: string;
}

export interface EnviroType {
  kodlouky: string;
  terminsec?: string;
  zpusobpastvy?: string;
  vymprekryv: number;
  kvalita: string;
  /** @format date-time */
  platnostod: string;
  /** @format date-time */
  platnostdo?: string;
}

export interface Eroze2GType {
  vyslednaeroze: VyslednaErozeType;
  seocelkem: number;
  seomeocelkem: number;
  maxsouvseo: number;
  maxsouvseomeo: number;
  /** @format date-time */
  osevod: string;
  /** @format date-time */
  osevdo: string;
  ptopatreni?: PtopatreniType[];
}

export interface EroznipozemekType {
  /** @format int32 */
  id?: number;
  typ: TypEroznihoPozemkuType;
  /** @format date-time */
  datumvytvoreni: string;
  vytvorilkdo: string;
  poznamka?: string;
  geoinfo?: GeoinfoType[];
  prislusdpb?: PrisluzdpbType;
  vyslednaeroze?: VyslednaErozeType;
  seocelkem?: number;
  seomeocelkem?: number;
  maxsouvseo?: number;
  maxsouvseomeo?: number;
  /** @format date-time */
  osevod?: string;
  /** @format date-time */
  osevdo?: string;
  ptopatreni?: PtopatreniType[];
}

export interface GeoinfoType {
  kod: string;
  hodnota: number;
  /** @format date-time */
  platnostod: string;
  /** @format date-time */
  platnostdo?: string;
}

export interface GmoType {
  /** @format int32 */
  plodinaid?: number;
  plodinanazev: string;
  vymera: number;
}

export enum KodOpatreniType {
  P1 = "P_1",
  P2 = "P_2",
  P3 = "P_3",
  Z0 = "Z_0",
  Z1 = "Z_1",
  Z2 = "Z_2",
  Z3 = "Z_3",
  S0 = "S_0",
  S1 = "S_1",
  S2 = "S_2",
  S3 = "S_3",
  V0 = "V_0",
  V1 = "V_1",
  V2 = "V_2",
  V3 = "V_3",
  V4 = "V_4",
  K = "K",
  R = "R",
  LOS = "LOS",
  ST = "ST",
  PK = "PK",
}

export interface LfaType {
  lfakod: string;
  vymprekryv: number;
  /** @format date-time */
  platnostod: string;
  /** @format date-time */
  platnostdo?: string;
}

export interface MzpType {
  mzpkod: string;
  vymprekryv: number;
  /** @format date-time */
  platnostod: string;
  /** @format date-time */
  platnostdo?: string;
}

export interface OdpocPlochyType {
  /** @format int32 */
  kodop?: number;
  /** @format int32 */
  sirka?: number;
  vymera: number;
  /** @format date-time */
  platnostod: string;
  /** @format date-time */
  platnostdo?: string;
}

export interface OpatreniErozeType {
  /** @format int32 */
  erozekateg?: number;
  vymprekryv: number;
  /** @format date-time */
  osevod: string;
  /** @format date-time */
  osevdo: string;
  /** @format date-time */
  cfaktorod?: string;
  /** @format date-time */
  cfaktordo?: string;
}

export interface OpatreniMeoType {
  kodopatreni: KodOpatreniType;
  /** @format date-time */
  osevod: string;
  /** @format date-time */
  osevdo: string;
}

export interface OpatreniNsType {
  kodtyp: string;
  hodnota: string;
  /** @format date-time */
  platnostod: string;
  /** @format date-time */
  platnostdo?: string;
}

export interface OpvType {
  kodzakres: string;
  geometrie: string;
}

export interface OpvzType {
  /** @format int32 */
  kategorieopvz?: number;
  vymprekryv: number;
  /** @format date-time */
  platnostod: string;
  /** @format date-time */
  platnostdo?: string;
}

export interface ParcelaVerzeType {
  /** @format int32 */
  idparcelaverze?: number;
  geometrie?: string;
  multipolygon?: boolean;
  vymera: number;
  /** @format date-time */
  parcelaverzeod: string;
  /** @format date-time */
  parcelaverzedo?: string;
  zakladni?: ZakladniType[];
  opatrenins?: OpatreniNsType[];
  aplpasmo?: AplPasmoType[];
  eroze2G?: Eroze2GType[];
}

export interface PlodinaType {
  /** @format int32 */
  pestovaniid?: number;
  /** @format int32 */
  kodplodiny?: number;
  meziplodina?: boolean;
  gps?: boolean;
  /** @format date-time */
  platnostod: string;
  /** @format date-time */
  platnostdo?: string;
}

export interface PrekryvKatuzeType {
  kunazev: string;
  /** @format int32 */
  kukod?: number;
  vymprekryv: number;
  /** @format int32 */
  dlecentroid?: number;
  /** @format date-time */
  platnostod: string;
  /** @format date-time */
  platnostdo?: string;
}

export interface PrisluzdpbType {
  /** @format int32 */
  fbid?: number;
  ctverec: string;
  zkod: string;
  vymeradpb: number;
  /** @format date-time */
  platnostod: string;
  /** @format date-time */
  platnostdo?: string;
}

export interface PtopatreniType {
  kod: string;
}

export interface SeznamEvpType {
  /** @format int32 */
  evpid?: number;
  ctverec: string;
  zkod: string;
  vymera: number;
  vymeraprekryv: number;
  geometrieevp: string;
  druhnazev: string;
  /** @format int32 */
  druhid?: number;
  /** @format date-time */
  platnostod: string;
  /** @format date-time */
  platnostdo?: string;
}

export enum TypEroznihoPozemkuType {
  EEP = "EEP",
  VEP = "VEP",
}

export interface UdajeErozeType {
  kod: string;
  hodnotanum?: number;
  /** @format int32 */
  hodnotaint?: number;
  hodnotastr?: string;
  /** @format date-time */
  platnostod: string;
  /** @format date-time */
  platnostdo?: string;
}

export interface UdajeNsType {
  kod: string;
  hodnotanum?: number;
  /** @format int32 */
  hodnotaint?: number;
  hodnotastr?: string;
  /** @format date-time */
  platnostod: string;
  /** @format date-time */
  platnostdo?: string;
}

export interface UzivatelType {
  /** @format int32 */
  iduzivatele?: number;
  jmeno?: string;
  prijmeni?: string;
  obchodnijmeno?: string;
  ic?: string;
  /** @format int32 */
  pravniforma?: number;
}

export interface VynosHladinaType {
  /** @format int32 */
  vynoshladinakod?: number;
  vymprekryv: number;
  /** @format date-time */
  platnostod: string;
  /** @format date-time */
  platnostdo?: string;
}

export enum VyslednaErozeType {
  SEO = "SEO",
  MEO = "MEO",
  NEO = "NEO",
}

export interface ZakladniType {
  kod: string;
  hodnotanum?: number;
  /** @format int32 */
  hodnotaint?: number;
  hodnotastr?: string;
  /** @format date-time */
  platnostod: string;
  /** @format date-time */
  platnostdo?: string;
}

export interface ZemParcelyType {
  /** @format int32 */
  idparcela?: number;
  nazev: string;
  /** @format int32 */
  poradi?: number;
  /** @format date-time */
  parcelaod: string;
  /** @format date-time */
  parcelado?: string;
  /** @format int32 */
  idparcelapredek?: number;
  plodina: PlodinaType[];
  odpocplochy?: OdpocPlochyType[];
  parcelaverze?: ParcelaVerzeType[];
}

export interface EagriServiceSyncStatusTo {
  service: string;
  /** @format date */
  lastRun: string;
  /** @format date */
  lastData: string;
  /** @format date */
  lastSuccess: string;
}

export interface ActionExpenseOverviewTo {
  materialId: string;
  materialType: MaterialType;
  materialName: string;
  /** @format int32 */
  materialLegislativeCode?: number;
  /** @format double */
  amount?: number;
  unit?: string;
  /** @format double */
  dosePerHa?: number;
  /** @format double */
  accountableNitrogen?: number;
  /** @format double */
  p2o5?: number;
  /** @format double */
  k2o?: number;
  /** @format double */
  mgo?: number;
  /** @format double */
  cao?: number;
  /** @format double */
  s?: number;
}

export interface ActionOverviewTo {
  id: string;
  /** @format date */
  date: string;
  isDraft: boolean;
  actionType: ActionTypeTo;
  crop?: CropTo;
  parcels: ActionParcelMinimalTo[];
  expenses: ActionExpenseOverviewTo[];
  source: ActionSource;
  seedApplicationType: SeedApplicationType;
}

export interface ActionParcelMinimalTo {
  id: string;
  actionParcelId: string;
  name?: string;
  blockNumber?: string;
  landUse: LandUse;
  landUseLocalised?: string;
  /** @format double */
  parcelArea: number;
  /** @format double */
  actionArea: number;
  /** @format double */
  restrictedArea: number;
  seedApplicationId: string;
  isNitrateVulnerable: boolean;
}

export type ActionDetailTo = (
  | UtilRequiredKeys<ActionSowingDetailTo, "parcels" | "id" | "date" | "source" | "actionType" | "isDraft">
  | UtilRequiredKeys<ActionRevenueDetailTo, "parcels" | "id" | "date" | "source" | "actionType" | "isDraft">
  | UtilRequiredKeys<ActionEPHDetailTo, "parcels" | "id" | "date" | "source" | "actionType" | "isDraft">
  | UtilRequiredKeys<ActionOtherDetailTo, "parcels" | "id" | "date" | "source" | "actionType" | "isDraft">
) & {
  id: string;
  /** @format date */
  date: string;
  source: ActionSource;
  /** @uniqueItems true */
  parcels: ActionParcelDetailTo[];
  actionType: ActionType;
  note?: string;
  isDraft: boolean;
};

export interface ActionEPHDetailTo {
  /** @uniqueItems true */
  parcels: ActionParcelDetailTo[];
  targetCrop?: CropTo;
  isStrawDecay?: boolean;
  fertilizationTarget?: FertilizerTarget;
  fertilizers: ActionExpenseFertilizerDetailTo[];
  plantProtections: ActionExpensePlantProtectionDetailTo[];
  id?: string;
  /** @format date */
  date?: string;
  source?: ActionSource;
  actionType?: ActionType;
  note?: string;
  isDraft?: boolean;
}

export interface ActionExpenseFertilizerDetailTo {
  fertilization: FertilizationTo;
  materialId: string;
  material: FertilizerTo;
  actionExpense: ActionExpenseTo;
  actionMaterial: EPHMat;
}

export interface ActionExpensePlantProtectionDetailTo {
  /** @uniqueItems true */
  targetOrganisms: OrganismTo[];
  parcelPlantProtectionEffectiveness: ParcelPlantProtectionEffectivenessTo[];
  material: PlantProtectionTo;
  materialId: string;
  actionExpense: ActionExpenseTo;
  actionMaterial: EPHMat;
}

export interface ActionExpenseSeedDetailTo {
  material: CropSeedTo;
  materialId: string;
  actionExpense?: ActionExpenseTo;
  actionMaterial: SeedMat;
}

export interface ActionOtherDetailTo {
  /** @uniqueItems true */
  parcels: ActionParcelDetailTo[];
  id?: string;
  /** @format date */
  date?: string;
  source?: ActionSource;
  actionType?: ActionType;
  note?: string;
  isDraft?: boolean;
}

export interface ActionParcelDetailTo {
  id: string;
  /** Parcel list item */
  parcel: ParcelTo;
  /** @uniqueItems true */
  restrictions: SubtractionResponse[];
  /** @format double */
  actionArea: number;
  /** @format double */
  restrictedArea: number;
}

export interface ActionRevenueDetailTo {
  /** @uniqueItems true */
  parcels: ActionParcelDetailTo[];
  revenues: RevenueTo[];
  id?: string;
  /** @format date */
  date?: string;
  source?: ActionSource;
  actionType?: ActionType;
  note?: string;
  isDraft?: boolean;
}

export interface ActionSowingDetailTo {
  /** @uniqueItems true */
  parcels: ActionParcelDetailTo[];
  seedExpense?: ActionExpenseSeedDetailTo;
  cropUseType?: CropUseTypeTo;
  seedApplicationType: SeedApplicationType;
  id?: string;
  /** @format date */
  date?: string;
  source?: ActionSource;
  actionType?: ActionType;
  note?: string;
  isDraft?: boolean;
}

export enum FertilizerTarget {
  CATCH_CROP = "CATCH_CROP",
  MAIN_CROP = "MAIN_CROP",
  STRAW = "STRAW",
}

export enum HarvestProduct {
  Value1 = "1",
  Value2 = "2",
  Value3 = "3",
  Value4 = "4",
  Value5 = "5",
  Value6 = "6",
  Value7 = "7",
  Value8 = "8",
  Value9 = "9",
  Value10 = "10",
  Value11 = "11",
  Value12 = "12",
  Value13 = "13",
  Value14 = "14",
  Value15 = "15",
  Value16 = "16",
  Value17 = "17",
  Value18 = "18",
  Value19 = "19",
  Value20 = "20",
  Value21 = "21",
  Value22 = "22",
  Value23 = "23",
  Value24 = "24",
  Value25 = "25",
  Value26 = "26",
  Value27 = "27",
  Value28 = "28",
  Value29 = "29",
  Value30 = "30",
  Value31 = "31",
  Value32 = "32",
  Value33 = "33",
  Value34 = "34",
}

export enum OrganismGroup {
  DISEASE = "DISEASE",
  WEED = "WEED",
  PEST = "PEST",
  OTHER_USE = "OTHER_USE",
  ORGANISM_PPP = "ORGANISM_PPP",
}

export interface OrganismTo {
  id: string;
  name: string;
  description?: string;
  legislativeCode: string;
  group: OrganismGroup;
}

export interface RevenueTo {
  actionId: string;
  /** @format double */
  amount: number;
  /** @format double */
  amountPerHa: number;
  product?: HarvestProduct;
  isMainProduct: boolean;
}

export enum ActionTypeGroupTo {
  OTHER = "OTHER",
}

export type Unit = object;

const PREFIX = 'PRECISION';

export const GET_PRECISION_PARCELS_REQUEST = `${PREFIX}/GET_PRECISION_PARCELS_REQUEST`;
export const GET_PRECISION_PARCELS_SUCCESS = `${PREFIX}/GET_PRECISION_PARCELS_SUCCESS`;
export const GET_PRECISION_PARCELS_ERROR = `${PREFIX}/GET_PRECISION_PARCELS_ERROR`;
export const RESET_PRECISION_PARCELS = `${PREFIX}/RESET_PRECISION_PARCELS`;

export const GET_PRECISION_PARCEL_REQUEST = `${PREFIX}/GET_PRECISION_PARCEL_REQUEST`;
export const GET_PRECISION_PARCEL_SUCCESS = `${PREFIX}/GET_PRECISION_PARCEL_SUCCESS`;
export const GET_PRECISION_PARCEL_ERROR = `${PREFIX}/GET_PRECISION_PARCEL_ERROR`;
export const RESET_PRECISION_PARCEL = `${PREFIX}/RESET_PRECISION_PARCEL`;

export const SET_OPENED_PRECISION_AS_APPLIED_ROWS = `${PREFIX}/SET_OPENED_PRECISION_AS_APPLIED_ROWS`;

const API_IDENTIFIERS = [
  'GET_PRECISION_TASK_TIMELINE_DATA',
  'GET_PRECISION_TASK_DATA',
] as const;

type API_IDENTIFIER = typeof API_IDENTIFIERS[number];

const getStates = (identifier: API_IDENTIFIER) => ({
  ERROR: `${PREFIX}/${identifier}_ERROR`,
  REQUEST: `${PREFIX}/${identifier}_REQUEST`,
  SUCCESS: `${PREFIX}/${identifier}_SUCCESS`,
  RESET: `${PREFIX}/${identifier}_RESET`,
});

const getStatesForApiCall = (identifier: API_IDENTIFIER) => [
  `${PREFIX}/${identifier}_REQUEST`,
  `${PREFIX}/${identifier}_SUCCESS`,
  `${PREFIX}/${identifier}_ERROR`,
] as const;

export { getStates, getStatesForApiCall };
export type { API_IDENTIFIER };
